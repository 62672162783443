import { hideLocatorEditPolygonSystemLayer } from 'src/app/shared/atlas-mapping/helpers/system-layers-helper';
import {
  locatorDataShapesLayerIdentifier,
  locatorDataShapeSpatialFunctionLayerIdentifier
} from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { environment } from 'src/environments/environment';
import { LocatorShape } from '../models/locator-shape';
import { LocatorShapeTypes } from '../types/locator-shape.types';
import {
  CircleShapeDefaults,
  DriveTimeShapeDefaults
} from 'src/app/core/models/locator-shape-defaults';
import { MapLayerTemplate } from 'src/app/core/models/map-layer-template';
import {
  getUpdatablesFromRecord,
  showFeatureActionMapLayers
} from 'src/app/shared/atlas-mapping/helpers/dynamic-layers.helper';
import { locatorFeatureActions } from '../types/locator-feature-action.types';

export function showLocatorShapeMapLayer(
  libraryId: number,
  locationDataId: number,
  shapeId: number,
  mapService: MapService
) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      locatorDataShapesLayerIdentifier
    )
  ) {
    if (mapService.getLayer(locatorDataShapesLayerIdentifier)) {
      mapService.updateLayer(locatorDataShapesLayerIdentifier, {
        visible: true,
        data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/library-data/${locationDataId}/shapes/${shapeId}/boundaries`
      });
    }
  }
}

export function showLocatorSelectedShapeMapLayer(
  shapeUpdatables: Record<string, number>,
  locatorTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  const updatables = getUpdatablesFromRecord(
    shapeUpdatables,
    locatorFeatureActions.selectedShape.updatables
  );
  showFeatureActionMapLayers(
    locatorTemplates.filter(
      (template) =>
        template.featureAction ===
        locatorFeatureActions.selectedShape.actionType
    ),
    updatables,
    mapService
  );
}

export function showShapesFromLibraryDataOnMap(
  libraryId: number,
  libraryDataId: number,
  mapService: MapService
) {
  if (mapService.getLayer(locatorDataShapesLayerIdentifier)) {
    mapService.updateLayer(locatorDataShapesLayerIdentifier, {
      visible: true,
      data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/library-data/${libraryDataId}/shapes/boundaries`
    });
  }
}

export function showLocatorSelectedLocationMapLayer(
  locationDataUpdatables: Record<string, number>,
  locatorTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  const updatables = getUpdatablesFromRecord(
    locationDataUpdatables,
    locatorFeatureActions.selectedLocation.updatables
  );
  showFeatureActionMapLayers(
    locatorTemplates.filter(
      (template) =>
        template.featureAction ===
        locatorFeatureActions.selectedLocation.actionType
    ),
    updatables,
    mapService
  );
}

export function showLocatorShapeBuildingBlockSelectionSystemLayer(
  libraryId: number,
  shapeId: number,
  mapService: MapService
) {
  var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
    locatorDataShapeSpatialFunctionLayerIdentifier
  );

  var layer = mapService.getLayer(
    locatorDataShapeSpatialFunctionLayerIdentifier
  );

  if (layer) {
    mapService.updateLayer(locatorDataShapeSpatialFunctionLayerIdentifier, {
      visible: usersVisiblePreference,
      data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/shapes/${shapeId}/building-block/within/locations`
    });
  }
}

export function showLocatorLayersEffectedByShapeSelectionLayers(
  libraryId: number,
  locationDataId: number,
  shapeUpdatables: Record<string, number>,
  locatorTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  showLocatorShapeMapLayer(
    libraryId,
    locationDataId,
    shapeUpdatables['shapeId'],
    mapService
  );
  showLocatorShapeBuildingBlockSelectionSystemLayer(
    libraryId,
    shapeUpdatables['shapeId'],
    mapService
  );
  showLocatorSelectedShapeMapLayer(
    shapeUpdatables,
    locatorTemplates,
    mapService
  );
}

// Cuurently this has be come obselete due to the show shapes being driven by the select all in the UI being triggered when the location selected
// If this is reenstated showLocatorSelectedLocationMapLayer might not be needed this is going to an effect that listens for selected location change
// this used to be the only place that triggered the feature layers for selected location
/*export function showLocatorLayersEffectedByLocationSelectionLayers(
  libraryId: number,
  locationDataUpdatables: Record<string, number>,
  locatorTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  showShapesFromLibraryDataOnMap(
    libraryId,
    locationDataUpdatables['locationId'],
    mapService
  );
  showLocatorSelectedLocationMapLayer(
    locationDataUpdatables,
    locatorTemplates,
    mapService
  );
}*/

export function showLocatorLayersEffectedByLibrarySelectionLayers(
  libraryUpdatables: Record<string, number>,
  locatorTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  const updatables = getUpdatablesFromRecord(
    libraryUpdatables,
    locatorFeatureActions.selectedLibrary.updatables
  );
  showFeatureActionMapLayers(
    locatorTemplates.filter(
      (template) =>
        template.featureAction ===
        locatorFeatureActions.selectedLibrary.actionType
    ),
    updatables,
    mapService,
    false
  );
}

export function showSelectedLocatorDataShapeAfterAddEdit(
  mapService: MapService,
  libraryId: number,
  shape: LocatorShape
) {
  showLocatorShapeMapLayer(
    libraryId,
    shape.libraryDataId,
    shape.id,
    mapService
  );
  showLocatorShapeBuildingBlockSelectionSystemLayer(
    libraryId,
    shape.id,
    mapService
  );
  hideLocatorEditPolygonSystemLayer(mapService);
}

export function isShapeDuplicate(
  sizeValues: number[],
  shapes: LocatorShape[] | null,
  shapeType: LocatorShapeTypes,
  driveCatchmentSpeed: string
): boolean {
  let isDuplicate = false;

  if (shapes === undefined) return isDuplicate;

  const sameShapeType = shapes!.filter((s) => s.type === shapeType);
  sameShapeType.forEach((shape) => {
    const circleConfig = shape.circleConfiguration
      ? JSON.parse(shape.circleConfiguration)
      : null;
    const circleRadius = circleConfig?.Radius;

    const driveTimeConfig = shape.drivetimeConfiguration
      ? JSON.parse(shape.drivetimeConfiguration)
      : null;
    const driveTimeInSeconds = driveTimeConfig?.TimeInSeconds;
    const driveTimeInMinutes = driveTimeInSeconds / 60;
    const shapeDriveCatchmentSpeed = driveTimeConfig?.DriveCatchmentSpeed;
    if (
      sizeValues.includes(circleRadius) ||
      (sizeValues.includes(driveTimeInMinutes) &&
        (shapeType === LocatorShapeTypes.Walk ||
          shapeDriveCatchmentSpeed === driveCatchmentSpeed))
    ) {
      isDuplicate = true;
    }
  });
  return isDuplicate;
}

export function isShapeSizeExceeded(
  shapeCircleConfig: CircleShapeDefaults,
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults,
  shapeWalkDriviTimeConfig: DriveTimeShapeDefaults,
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults,
  shapeType: LocatorShapeTypes,
  sizeValues: number[]
): boolean {
  if (shapeType === LocatorShapeTypes.Car) {
    return sizeValues.some((s) => s > shapeCarDriveTimeConfig.rangeLimit);
  } else if (shapeType === LocatorShapeTypes.Walk) {
    return sizeValues.some((s) => s > shapeWalkDriviTimeConfig.rangeLimit);
  } else if (shapeType === LocatorShapeTypes.Circle) {
    return sizeValues.some((s) => s > shapeCircleConfig.rangeLimit);
  } else if (shapeType === LocatorShapeTypes.PublicTransport) {
    return sizeValues.some(
      (s) => s > shapePublicTransportDriveTimeConfig.rangeLimit
    );
  }
  return false;
}

export function getShapeTypeName(type: LocatorShapeTypes): string {
  const shapeTypes = {
    1: $localize`:@@driveCatchmentName:Drive Catchment`,
    2: $localize`:@@walkCatchmentName:Walk Catchment`,
    3: $localize`:@@circleCatchmentName:Circle Catchment`,
    4: $localize`:@@polygonCatchmentName:Polygon Catchment`,
    5: $localize`:@@publicTransportCatchmentName:Public Transport Catchment`,
    6: $localize`:@@polygonCatchmentLockedName:Polygon Catchment Locked`
  };
  return shapeTypes[type];
}

export function getShapeRangeLimit(
  shapeCircleConfig: CircleShapeDefaults,
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults,
  shapeWalkDriviTimeConfig: DriveTimeShapeDefaults,
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults,
  shapeType: LocatorShapeTypes
): number {
  const shapeTypes = {
    1: shapeCarDriveTimeConfig?.rangeLimit,
    2: shapeWalkDriviTimeConfig?.rangeLimit,
    3: shapeCircleConfig?.rangeLimit,
    4: 0,
    5: shapePublicTransportDriveTimeConfig?.rangeLimit,
    6: 0
  };
  return shapeTypes[shapeType];
}

export function isShapeDisabled(shapeType: any) {
  return (
    shapeType === null ||
    shapeType?.isDisabled === true ||
    shapeType?.isDisabled === undefined
  );
}
