import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';

import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';

import * as fromLocatorStore from 'src/app/locator-store';
import * as fromUIStore from 'src/app/core/store';
import { getLocatorLibraryId } from 'src/app/locator-store';
import { SwapLibraryDialogComponent } from '../swap-library-dialog/swap-library-dialog.component';

@Component({
  selector: 'atlas-swap-library',
  templateUrl: './swap-library.component.html',
  styleUrls: ['../../less/library-management-button.component.less']
})
export class SwapLibraryComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private libraryId$ = this.store$.select(getLocatorLibraryId);
  private currentLibraryId: number;

  @Output() swapLibraryClicked = new EventEmitter();

  isLocationChanging$ = this.UIStore$.select(fromUIStore.isLocationChanging);
  isCatchmentReportingReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getCatchmentReportingReadonlySystem
  );

  constructor(
    public dialogService: DialogService,
    private store$: Store<fromLocatorStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.subscription.add(
      this.libraryId$.subscribe((id) => {
        this.currentLibraryId = id;
      })
    );
  }

  onSwapLibrary() {
    this.dialogService.show(SwapLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        headerText: $localize`:@@switchLibraryHeader:Switch library`,
        currentLibraryId: this.currentLibraryId
      },
      disableClose: true
    });
  }
}
