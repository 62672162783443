import { Component, OnDestroy, OnInit, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromProfilerStore from 'src/app/profiler-store';

import { Subscription } from 'rxjs/internal/Subscription';

import { DOCUMENT } from '@angular/common';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';
import { MapLayerStyleService } from 'src/app/shared/atlas-mapping/services/map-layer-style-service';

@Component({
  selector: 'atlas-profiler-reports-panel',
  templateUrl: './profiler-reports-panel.component.html',
  styleUrls: ['./profiler-reports-panel.component.less']
})
export class ProfilerReportsPanelComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() pieGridView: [number, number] = [250, 620];
  @Input() numberCardView: [number, number] = [300, 620];
  @Input() graphHeight: number;

  identifier = this.getGUID();

  selectedQueryItem: ProfilerLofiQueryResult | null =
    new ProfilerLofiQueryResult();

  queryTotal: number = -1;
  queryItems: ProfilerLofiQueryResult[] = [];

  graphConfig: any = {};
  title: string = '';

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private profilerStore$: Store<fromProfilerStore.State>,
    private layerStyleService: MapLayerStyleService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.profilerStore$
        .select(fromProfilerStore.getLofiReport)
        .subscribe((lofFiReport) => {
          if (lofFiReport) {
            this.queryItems = lofFiReport as ProfilerLofiQueryResult[];
            this.queryTotal = this.queryItems.length;
            let selectedQuery = this.queryItems[0];
            this.selectedQueryItem = selectedQuery;
            this.setGraphConfig(selectedQuery);
            this.updateTitle();
          } else {
            this.queryTotal = 0;
            this.queryItems = [];
            this.selectedQueryItem = null;
            this.title = '';
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectQuery(position: number) {
    this.activateDot(position);
    // Select the first query item as a fallback
    this.selectedQueryItem = this.queryItems[position];
    this.updateTitle();
    this.setGraphConfig(this.queryItems[position]);
  }

  onGraphClicked(evt: any) {
    if (evt.mapLayerId) {
      this.layerStyleService.toggleSpotlight(evt.mapLayerId, evt.name);
    }
  }

  private activateDot(postion: number) {
    const container = this._document.getElementById(this.identifier);

    var dots = container?.querySelectorAll('.dot');

    if (dots) {
      dots.forEach((dot) => {
        dot.className = dot.className.replace(' active', '');
      });

      dots[postion].className += ' active';
    }
  }

  private setGraphConfig(selectedQuery: ProfilerLofiQueryResult) {
    this.graphConfig =
      selectedQuery === undefined ||
      selectedQuery.graphConfiguration == null ||
      selectedQuery.graphConfiguration == undefined
        ? {}
        : JSON.parse(selectedQuery.graphConfiguration);
  }

  private getGUID(): string {
    let d = new Date().getTime();
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return guid;
  }

  private updateTitle(): void {
    if (this.selectedQueryItem) {
      this.title = this.selectedQueryItem.pageTitle;
    }
  }
}
