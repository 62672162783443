import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SmImportStatusTypes } from 'src/app/spatial-modeller/helpers/spatial-modeller-status-helper';

@Component({
  selector: 'atlas-sm-import-scenario-data-form-2',
  templateUrl: './sm-import-scenario-data-form-2.component.html',
  styleUrl: './sm-import-scenario-data-form-2.component.less'
})
export class SmImportScenarioDataForm2Component {
  mode: ProgressSpinnerMode = 'determinate';
  description: string = $localize`:@@validatingFileContent:Validating file content`;
  private _importStatus: SmImportStatusTypes;
  @Input()
  set importStatus(newValue: SmImportStatusTypes) {
    this._importStatus = newValue;
    this.percentage = this.calculatePercentage(
      this._processedRecords,
      this._totalRecords
    );
  }
  get importStatus(): SmImportStatusTypes {
    return this._importStatus;
  }

  private _totalRecords: number = 0;
  @Input()
  set totalRecords(newValue: number) {
    this._totalRecords = newValue;
    this.percentage = this.calculatePercentage(
      this._processedRecords,
      this._totalRecords
    );
  }
  get totalRecords(): number {
    return this._totalRecords;
  }

  private _processedRecords: number = 0;
  @Input()
  set processedRecords(newValue: number) {
    this._processedRecords = newValue;
    this.percentage = this.calculatePercentage(
      this._processedRecords,
      this._totalRecords
    );
  }
  get processedRecords(): number {
    return this._processedRecords;
  }

  @Input() errorsProcessingData = false;
  percentage: number = 0;
  validationCompletedPercentage: number = 0; // First step: time validating file, from 0% to 10%
  fileRowsProcessedPercentage: number = 0; // Second step: time processing data using spatial api, from 0% to 80%
  dataImportedPercentage: number = 0; // Third step: time inserting data in atlas Db, from 0% to 10%

  constructor() {}

  calculatePercentage(processedRecords: number, totalRecords: number) {
    const processedRecordsPercentage =
      totalRecords === 0
        ? 0
        : Math.round((processedRecords * 100) / totalRecords);

    if (
      this.importStatus != null &&
      this.importStatus !== 'FileUploadCompleted'
    ) {
      this.validationCompletedPercentage = 10;
      this.description = $localize`:@@importing:Importing`;
    }

    if (this.importStatus !== 'ProcessingFileDataCompleted') {
      this.fileRowsProcessedPercentage = Math.round(
        (processedRecordsPercentage * 80) / 100
      );
    }
    if (this.importStatus === 'ProcessingFileDataCompleted') {
      this.dataImportedPercentage = 10;
      this.fileRowsProcessedPercentage = 80;
    }
    return (
      this.validationCompletedPercentage +
      this.fileRowsProcessedPercentage +
      this.dataImportedPercentage
    );
  }
}
