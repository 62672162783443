<div class="list-container">
  <mat-list *ngIf="progressSteps && progressSteps.length > 0">
    <mat-list-item *ngFor="let step of progressSteps">
      <atlas-profiler-progress-step
        [progressStep]="step"
      ></atlas-profiler-progress-step>
    </mat-list-item>
  </mat-list>
  <div
    *ngIf="importStatusErrors && importStatusErrors.errors.length > 0"
    class="errors-list"
  >
    <div class="header" i18n="@@listOfErrorsDetected">
      List of errors detected:
    </div>
    <div style="max-height: 100px; overflow-y: auto">
      <div
        class="error-padding"
        *ngFor="let errorMessage of importStatusErrors.errors"
      >
        <li class="text">
          {{ errorMessage.error }}
        </li>
      </div>
    </div>
  </div>
</div>
