<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    (click)="onOpenNewLibraryDialog()"
    aria-label="add library"
    i18n-aria-label="@@addLibraryLabel"
    i18n-matTooltip="@@createNewLibraryTooltip"
    matTooltip="Create a new library"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    [disabled]="isCatchmentReportingReadonlySystem$ | async"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
