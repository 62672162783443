<div class="feature-item-container">
  <div *ngIf="isLocatorFeatureSelected$ | async" class="locations-padding">
    <a
      style="cursor: pointer"
      target="_blank"
      [ngClass]="
        selectedOption == 'LocatorLocations'
          ? 'feature-selected-link '
          : 'feature-link'
      "
      (click)="onLocatorLocations()"
      i18n="@@locatorLocations"
    >
      Locations
    </a>
  </div>

  <div class="layers-padding">
    <a
      [ngClass]="
        selectedOption == 'Layers' ? 'feature-selected-link ' : 'feature-link'
      "
      style="cursor: pointer"
      target="_blank"
      (click)="onLayersClicked()"
      i18n="@@layers"
    >
      Layers
    </a>
  </div>
</div>
