import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

@Component({
  selector: 'save-pending-supply-point-confirmation-dialog.component',
  templateUrl: './save-pending-supply-point-confirmation-dialog.component.html',
  styleUrls: ['./save-pending-supply-point-confirmation-dialog.component.less']
})
export class SavePendingSupplyPointConfirmationDialogComponent {
  get headerText() {
    return $localize`:@@saveEditedLocationHeader:Save edited location`;
  }

  constructor(
    private store$: Store<fromSmStore.State>,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SavePendingSupplyPointConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {}
  ) {}

  selectedSupplypoint$ = this.store$.select(
    fromSmStore.getSpatialModellerSupplyPoint
  );

  onSave() {
    this.store$.dispatch(
      fromSmStore.SupplyPointActions.savePendingSupplyPointClicked()
    );
    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }
  onDiscard() {
    this.store$.dispatch(
      fromSmStore.SupplyPointActions.discardPendingSupplyPointClicked()
    );
    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }
}
