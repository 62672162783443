<form [formGroup]="defineNewLocationForm" autocomplete="off">
  <div class="description-text">
    <span i18n="@@latitudeLongitudeDescription">
      Please enter your latitude and longitude using the WGS84 projection e.g.
      41.40338, 2.17403
    </span>
  </div>

  <mat-form-field
    appearance="outline"
    hideRequiredMarker="true"
    class="input-field"
  >
    <mat-label i18n="@@latitudeLabel">Latitude</mat-label>
    <input matInput type="text" formControlName="latitude" />
  </mat-form-field>

  <div *ngIf="isLatitudeValid()">
    <div class="error-message">
      <span i18n="@@latitudeError">
        {{
          getErrorMessage(
            defineNewLocationForm.controls.latitude.errors,
            'Latitude'
          )
        }}
      </span>
    </div>
  </div>

  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label i18n="@@longitudeLabel">Longitude</mat-label>
    <input matInput type="text" formControlName="longitude" />
  </mat-form-field>

  <div *ngIf="isLongitudeValid()">
    <div class="error-message">
      <span i18n="@@longitudeError">
        {{
          getErrorMessage(
            defineNewLocationForm.controls.longitude.errors,
            'Longitude'
          )
        }}
      </span>
    </div>
  </div>
</form>
