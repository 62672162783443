import { KeyValue } from '@angular/common';

export enum DriveCatchmentSpeeds {
  MidDay,
  RushHour,
  Night
}

export const DriveCatchmentSpeedNames: KeyValue<
  DriveCatchmentSpeeds,
  string
>[] = [
  {
    key: DriveCatchmentSpeeds.MidDay,
    value: $localize`:@@middayDescription:Midday (average traffic, average vehicle speed)`
  },
  {
    key: DriveCatchmentSpeeds.RushHour,
    value: $localize`:@@rushHourDescription:Rush-hour (most traffic, slowest vehicle speed)`
  },
  {
    key: DriveCatchmentSpeeds.Night,
    value: $localize`:@@nightDescription:Night (least traffic, fastest vehicle speed)`
  }
];
