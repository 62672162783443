<div class="main-container">
  <div class="side-column">
    <button
      mat-flat-button
      type="button"
      class="secondary-button back-button"
      (click)="onBack()"
    >
      <mat-icon class="chevron-back">chevron_left</mat-icon
      ><span i18n="@@backButton">Back</span>
    </button>
  </div>
  <div class="workspace-container">
    <div><h1 i18n="@@usageTitle">Usage</h1></div>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab
        label="Logins"
        *ngIf="layoutService.isDesktopView$ | async"
        i18n-label="@@loginsTab"
      >
        <atlas-users-login-usage-tab-content></atlas-users-login-usage-tab-content>
      </mat-tab>
      <mat-tab
        label="Network planning"
        *ngIf="isSMFeatureAllowed$ | async"
        i18n-label="@@networkPlanningTab"
      >
        <atlas-model-usage-tab-content></atlas-model-usage-tab-content>
      </mat-tab>
      <mat-tab
        label="Catchment reporting"
        *ngIf="
          (isLocatorFeatureAllowed$ | async) &&
          !(isCatchmentReportingReadonlySystem$ | async)
        "
        i18n-label="@@catchmentReportingTab"
      >
        <atlas-catchment-usage-tab-content></atlas-catchment-usage-tab-content>
      </mat-tab>
      <mat-tab
        label="Profiling"
        *ngIf="isProfilerFeatureAllowed$ | async"
        i18n-label="@@profilingTab"
      >
        <atlas-profiled-records-usage-tab-content></atlas-profiled-records-usage-tab-content>
      </mat-tab>
      <mat-tab
        label="Geocoder"
        *ngIf="
          ((isLocatorFeatureAllowed$ | async) ||
            (isSMFeatureAllowed$ | async)) &&
          (isGeocoderLicensed$ | async)
        "
        i18n-label="@@geocoderTab"
      >
        <atlas-geocoded-records-usage-tab-content></atlas-geocoded-records-usage-tab-content>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="side-column"></div>
</div>
