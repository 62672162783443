<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div i18n="@@switchSystemConfirmation">
      Are you sure you want to switch from {{ selectedSystemName$ | async }} to
      {{ this.data.selectedSystemName }}?
    </div>
    <p></p>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button
        (clicked)="onCancelClicked()"
      ></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Switch"
        i18n-label="@@switchButtonLabel"
        (clicked)="onSwitchClicked()"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
