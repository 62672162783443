<div class="settings-container">
  <div class="settings-content">
    <div class="scroll-bar">
      <form
        [formGroup]="locatorSettingsFormGroup"
        autocomplete="off"
        style="height: 100%"
      >
        <div>
          <div i18n="@@catchmentModeTitle" class="title">Catchment mode</div>
          <mat-radio-group
            formControlName="multiCatchmentMode"
            (change)="onRadioChange($event)"
          >
            <span i18n="@@defaultCatchmentsLabel">Default catchments</span>
            <mat-radio-button
              [value]="false"
              class="radio-button-margin radio-button-padding"
            >
            </mat-radio-button>
            <div i18n="@@defaultCatchmentsDescription" class="description">
              Enables users to set their own default catchment size when new
              locations are defined.
            </div>
            <div class="inputFields">
              <div class="catchments">
                <mat-form-field appearance="outline" hideRequiredMarker="true">
                  <mat-label i18n="@@driveCatchmentLabel"
                    >Drive ({{ driveUnit }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="carCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max: {{ minRangeLimit }}:{{ maxCarRangeLimit }}
                  </div>
                  @if(emptyCarCatchmentError() || minCarCatchmentSizeError() ||
                  maxCarCatchmentSizeError() ||
                  positiveIntegersCarCatchmentSizeError()){
                  <div>
                    <mat-error i18n="@@carCatchmentError" class="error-message">
                      <span
                        >Please enter an integer from {{ minRangeLimit }} to
                        {{ maxCarRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
                <mat-form-field
                  appearance="outline"
                  hideRequiredMarker="true"
                  style="margin-top: -5px"
                >
                  <mat-label i18n="@@walkCatchmentLabel"
                    >Walk ({{ walkUnit }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="walkCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max: {{ minRangeLimit }}:{{ maxWalkRangeLimit }}
                  </div>
                  @if(emptyWalkCatchmentError() || minWalkCatchmentSizeError()
                  || maxWalkCatchmentSizeError() ||
                  positiveIntegersWalkCatchmentSizeError()){
                  <div>
                    <mat-error
                      i18n="@@walkCatchmentError"
                      class="error-message"
                    >
                      <span
                        >Please enter an integer from {{ minRangeLimit }} to
                        {{ maxWalkRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
                <mat-form-field appearance="outline" hideRequiredMarker="true">
                  <mat-label i18n="@@publicTransportCatchmentLabel"
                    >PublicTransport ({{ publicTransportUnit }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="publicTransportCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max: {{ minRangeLimit }}:{{
                      maxPublicTransportRangeLimit
                    }}
                  </div>
                  @if(emptyPublicTransportCatchmentError() ||
                  minPublicTransportCatchmentSizeError() ||
                  maxPublicTransportCatchmentSizeError() ||
                  positiveIntegersPublicTransportCatchmentSizeError()){
                  <div>
                    <mat-error
                      i18n="@@publicTransportCatchmentError"
                      class="error-message"
                    >
                      <span
                        >Please enter an integer from {{ minRangeLimit }} to
                        {{ maxPublicTransportRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
              </div>

              <div class="catchments">
                <mat-form-field appearance="outline" hideRequiredMarker="true">
                  <mat-label i18n="@@circleCatchmentLabel"
                    >Circle (radius, {{ circleUnit }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="circleCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max {{ minCircleRangeLimit }}:{{ maxCircleRangeLimit }}
                  </div>
                  @if(emptyCircleCatchmentError() ||
                  minCircleCatchmentSizeError() ||
                  maxCircleCatchmentSizeError()){
                  <div>
                    <mat-error
                      i18n="@@circleCatchmentError"
                      class="error-message"
                    >
                      <span
                        >Please enter a number up to
                        {{ maxCircleRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
                <mat-form-field
                  appearance="outline"
                  hideRequiredMarker="true"
                  style="margin-top: -5px"
                >
                  <mat-label i18n="@@polygonCatchmentLabel"
                    >Polygon (radius, {{ polygonUnitToShow }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="polyCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">Min: 0.1</div>
                  @if(emptyPolyCatchmentError() || minPolyCatchmentSizeError()){
                  <div>
                    <mat-error
                      i18n="@@polygonCatchmentError"
                      class="error-message"
                    >
                      <span>Please enter a number greater than 0.1</span>
                    </mat-error>
                  </div>
                  }
                </div>
              </div>
            </div>
            <span i18n="@@multipleCatchmentsLabel">Multiple catchments</span>
            <mat-radio-button
              [value]="true"
              class="radio-button-margin radio-button-padding"
            >
            </mat-radio-button>
            <div>
              <div i18n="@@multipleCatchmentsDescription" class="description">
                Enables users to create multiple catchments of pre-determined
                types and sizes.
              </div>
            </div>
          </mat-radio-group>
          <mat-radio-group></mat-radio-group>
          <div>
            <mat-radio-group
              formControlName="applyMultiCatchmentsToExistingLocations"
              (change)="onApplyMultiCatchmentsRadioChange($event)"
            >
              <mat-radio-button [value]="true" class="radio-button-padding">
                <mat-label i18n="@@newAndExistingLocationsLabel"
                  >New and existing locations</mat-label
                >
              </mat-radio-button>

              <mat-radio-button
                [value]="false"
                class="radio-button-margin radio-button-padding"
              >
                <mat-label i18n="@@newLocationsLabel">New locations</mat-label>
              </mat-radio-button>
            </mat-radio-group>

            <atlas-multi-catchment-settings
              [defaultDistanceUnit]="defaultDistanceUnit$ | async"
              [multipleCatchmentsFormArray]="multipleCatchmentsFormArray"
              [isMultiCatchmentsMode]="multiCatchmentsMode"
            ></atlas-multi-catchment-settings>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="save-button-container">
    <button
      mat-flat-button
      color="primary"
      class="save-button"
      (click)="saveSettings()"
      [disabled]="invalidForm()"
    >
      <span i18n="@@saveButtonLabel">Save</span>
    </button>
  </div>
</div>
