import {
  GeocodeLevel,
  geocodeLevelType
} from 'src/app/locator/types/geocode-level';

export interface SortOptions {
  value: ListSortOptions | LocatorListSortOptions;
  display: string;
}

export interface SearchAndSortValues {
  filter: string;
  sort: string;
}

export type ListSortOptions =
  | 'NameAsc'
  | 'NameDesc'
  | 'EditedAsc'
  | 'EditedDesc';

export type LocatorListSortOptions =
  | 'MatchLevelHighLow'
  | 'MatchLevelLowHigh'
  | 'CatchmentsHighLow'
  | 'CatchmentsLowHigh';

export function applyFilter(filterValue: string, data: any[]) {
  return data.filter((s) =>
    s.name.toLowerCase().includes(filterValue.trim().toLowerCase())
  );
}

export const SortValuesArray = [
  { value: 'NameAsc', display: $localize`:@@nameAsc:Name: A-Z` },
  { value: 'NameDesc', display: $localize`:@@nameDesc:Name: Z-A` },
  {
    value: 'EditedDesc',
    display: $localize`:@@editedDesc:Date last opened: Recent First`
  },
  {
    value: 'EditedAsc',
    display: $localize`:@@editedAsc:Date last opened: Oldest First`
  }
];

export function applySort(option: ListSortOptions, data: any[]) {
  const sortOptions = {
    NameAsc: (data: any[]) =>
      data.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0
      ),
    NameDesc: (data: any[]) =>
      data.sort((a, b) =>
        b.name.toLowerCase() > a.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? -1
          : 0
      ),
    EditedAsc: (data: any[]) =>
      data.sort((a, b) =>
        a.editedDt > b.editedDt ? 1 : b.editedDt > a.editedDt ? -1 : 0
      ),
    EditedDesc: (locations: any[]) =>
      locations.sort((a, b) =>
        b.editedDt > a.editedDt ? 1 : a.editedDt > b.editedDt ? -1 : 0
      )
  };

  return sortOptions[option](data);
}

export function applyMatchLevelSort(data: any[], isStrongToWeak: boolean) {
  if (isStrongToWeak) {
    data.sort((a, b) => {
      const value = sortNullValues(a.matchLevel, b.matchLevel);
      if (value !== null) return value;

      return sortGeocodeMatchLevel(a.matchLevel, b.matchLevel);
    });
  } else {
    data.sort((a, b) => {
      const value = sortNullValues(b.matchLevel, a.matchLevel);
      if (value !== null) return value;

      return sortGeocodeMatchLevel(b.matchLevel, a.matchLevel);
    });
  }

  return data;
}

function sortGeocodeMatchLevel(
  matchLevelA: GeocodeLevel,
  matchLevelB: GeocodeLevel
) {
  const geocodeLevelTypeA = geocodeLevelType[matchLevelA](matchLevelA);
  const geocodeLevelTypeB = geocodeLevelType[matchLevelB](matchLevelB);
  return geocodeLevelTypeA > geocodeLevelTypeB
    ? 1
    : geocodeLevelTypeB > geocodeLevelTypeA
    ? -1
    : 0;
}

function sortNullValues(a: any, b: any) {
  return a === null && b === null ? 0 : a === null ? 1 : b === null ? -1 : null;
}
