@if(!errorsProcessingData){
<div class="title">{{ titleTxt }}</div>
<div class="spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="0" class="background-spinner">
  </mat-progress-spinner>
  <mat-progress-spinner
    [mode]="mode"
    [value]="percentage"
    class="percentage-spinner"
  >
  </mat-progress-spinner>
</div>

<div class="message-container">
  <span class="summary-text" i18n="@@batchReportsMessage">
    Batch reports for many catchments may take a long time.
  </span>
</div>
} @else{
<div class="title title-error" i18n="@@errorTitle">Error</div>
<div class="error-spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="100" class="error-spinner">
  </mat-progress-spinner>
  <div class="error-text">
    <span i18n="@@errorText">N/A</span>
  </div>
</div>
}
