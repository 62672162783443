<div class="chart-title" i18n-gridTitle="@@allUsersGridTitle">All Users</div>
<div class="chart-container">
  <ngx-charts-bar-vertical
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showGridLines]="showGridLines"
  >
  </ngx-charts-bar-vertical>
</div>
