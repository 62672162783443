<div class="button-container">
  <div
    matTooltip="Run model"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    i18n-matTooltip="@@runModelTooltip"
  >
    <ng-container *ngIf="!spatialModellerLiveModeDisabled; else disabledButton">
      <button
        class="atlas-icon-button"
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="isSubmitButtonDisabled()"
      >
        <mat-icon
          class="run-model-icon"
          [style.display]="isTestInProgress ? 'none' : 'inline-block'"
          >play_circle_outline</mat-icon
        >
        <mat-spinner
          class="update-button-spinner"
          *ngIf="isTestInProgress"
          diameter="24"
          strokeWidth="2"
        >
        </mat-spinner>
      </button>
    </ng-container>

    <ng-template #disabledButton>
      <button
        class="atlas-icon-button"
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="isSubmitButtonLiveModeOffDisabled()"
      >
        <mat-icon
          class="run-model-icon"
          [style.display]="
            isTestInProgressNotFromSaveCloseReopenAndLiveModeOff()
              ? 'none'
              : 'inline-block'
          "
          >play_circle_outline</mat-icon
        >
        <mat-spinner
          class="update-button-spinner"
          *ngIf="isTestInProgressNotFromSaveCloseReopenAndLiveModeOff()"
          diameter="24"
          strokeWidth="2"
        >
        </mat-spinner>
      </button>
    </ng-template>
  </div>
  <div
    matTooltip="Restore defaults"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    i18n-matTooltip="@@restoreDefaultsTooltip"
  >
    <button
      class="atlas-icon-button atlas-icon icon-reset"
      mat-flat-button
      color="primary"
      aria-label="Restore defaults"
      (click)="onReset()"
      [disabled]="isRestoreDefaultsButtonDisabled()"
      i18n-aria-label="@@restoreDefaultsAriaLabel"
    ></button>
  </div>
  <div
    matTooltip="Save location"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    i18n-matTooltip="@@saveLocationTooltip"
  >
    <button
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      aria-label="Save location"
      (click)="onSaveClicked()"
      [disabled]="isSaveButtonDisabled()"
      i18n-aria-label="@@saveLocationAriaLabel"
    >
      <mat-icon
        [style.display]="
          isGenerateLocationInProgressFromSaveLiveModeOff()
            ? 'none'
            : 'inline-block'
        "
        >save</mat-icon
      >
      <mat-spinner
        class="update-button-spinner"
        *ngIf="isGenerateLocationInProgressFromSaveLiveModeOff()"
        diameter="24"
        strokeWidth="2"
      >
      </mat-spinner>
    </button>
  </div>
  <div
    [matTooltip]="closeOrReopenLocationTooltip()"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    i18n-matTooltip="@@closeOrReopenLocationTooltip"
  >
    <button
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      aria-label="Close location"
      (click)="onCloseClicked()"
      [disabled]="isCloseButtonDisabled()"
      i18n-aria-label="@@closeLocationAriaLabel"
    >
      <ng-container
        *ngIf="
          !isGenerateLocationInProgressFromCloseOrReopenLiveModeOff();
          else inProgress
        "
      >
        <ng-container *ngIf="!isSupplyPointClosed; else locationOn">
          <mat-icon>location_off</mat-icon>
        </ng-container>

        <ng-template #locationOn>
          <mat-icon>location_on</mat-icon>
        </ng-template>
      </ng-container>

      <ng-template #inProgress>
        <mat-spinner
          class="update-button-spinner"
          diameter="24"
          strokeWidth="2"
        >
        </mat-spinner>
      </ng-template>
    </button>
  </div>
  <div
    matTooltip="Delete location"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    i18n-matTooltip="@@deleteLocationTooltip"
  >
    <button
      class="atlas-icon-button"
      mat-flat-button
      color="primary"
      aria-label="Delete location"
      (click)="onDeleteClicked()"
      [disabled]="isDeleteButtonDisabled()"
      i18n-aria-label="@@deleteLocationAriaLabel"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <button
    class="atlas-icon-button atlas-icon icon-street-view atlas-secondary-icon-button"
    mat-flat-button
    color="primary"
    aria-label="Street view"
    (click)="onStreetViewClicked()"
    matTooltip="Show street view"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    [disabled]="isStreetViewDisabled()"
    *ngIf="!(layoutService.isDesktopView | async)"
    i18n-aria-label="@@streetViewButtonAriaLabel"
    i18n-matTooltip="@@streetViewButtonTooltip"
  ></button>
</div>
