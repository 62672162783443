<div>
  <div class="summary-container">
    <div *ngIf="!isProfileStatusSucceded()">
      <span i18n="@@noProfileAvailable">No profile available</span>
    </div>
    <div *ngIf="isProfileStatusSucceded()">
      <div class="summary-row">
        <span i18n="@@createdOn">Created on</span>
        {{ createDate | date : 'dd/MM/y' }}
      </div>
      <div class="summary-row">
        <span class="profiled-records-label">{{
          profiledRecords | number
        }}</span>
        <span i18n="@@recordsMatched"> records matched</span> ({{
          profiledRecordsPercentage | number : '1.1-1'
        }}%)
      </div>
      <div class="summary-row">
        <span class="imported-records-label">{{
          importedRecords | number
        }}</span>
        <span i18n="@@recordsImported"> records imported</span>
      </div>
    </div>
  </div>
</div>
