import { forEach } from 'lodash';
import { LegendItem } from '../models/legend-item';
import { MapLayerTypes } from '../types/map-layer.types';
import { Color, Solver } from './color-filter-helper';
import {
  getIconUrl,
  getThematicColor,
  hexToRgb,
  rgbToHex
} from './thematic-style-helper';

export function getBoundaryLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  return [
    {
      mapLayerType: mapLayerType,
      fillColor: rgbToHex(
        layerProperties.getFillColor[0],
        layerProperties.getFillColor[1],
        layerProperties.getFillColor[2]
      ),
      lineColor: rgbToHex(
        layerProperties.getLineColor[0],
        layerProperties.getLineColor[1],
        layerProperties.getLineColor[2]
      ),
      lineWidth: layerProperties.lineWidthMinPixels,
      iconColor: [],
      text: '',
      iconUrl: '',
      iconHeight: 0,
      iconWidth: 0,
      iconFilter: '',
      labelBackground: []
    }
  ];
}

export function getPointLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  return [
    {
      mapLayerType: mapLayerType,
      fillColor: rgbToHex(
        layerProperties.getFillColor[0],
        layerProperties.getFillColor[1],
        layerProperties.getFillColor[2]
      ),
      lineColor: rgbToHex(
        layerProperties.getLineColor[0],
        layerProperties.getLineColor[1],
        layerProperties.getLineColor[2]
      ),
      lineWidth: 1,
      iconColor: [],
      text: '',
      iconUrl: '',
      iconHeight: 0,
      iconWidth: 0,
      iconFilter: '',
      labelBackground: []
    }
  ];
}

export function getIconLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  var filter = getFilterColor(layerProperties.iconColor);

  return [
    {
      mapLayerType: mapLayerType,
      fillColor: [],
      lineColor: [],
      lineWidth: 1,
      iconColor: rgbToHex(
        layerProperties.iconColor[0],
        layerProperties.iconColor[1],
        layerProperties.iconColor[2]
      ),
      text: '',
      iconUrl: layerProperties.iconUrl,
      iconHeight: 24,
      iconWidth: 24,
      iconFilter: filter,
      labelBackground: []
    }
  ];
}

export function getLabelLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  return [
    {
      mapLayerType: mapLayerType,
      fillColor: rgbToHex(
        layerProperties.getColor[0],
        layerProperties.getColor[1],
        layerProperties.getColor[2]
      ),
      lineColor: rgbToHex(0, 0, 0),
      lineWidth: 1,
      iconColor: [],
      text: '',
      iconUrl: '',
      iconHeight: 0,
      iconWidth: 0,
      iconFilter: '',
      labelBackground: rgbToHex(
        layerProperties.getBackgroundColor[0],
        layerProperties.getBackgroundColor[1],
        layerProperties.getBackgroundColor[2]
      )
    }
  ];
}

export function getLineLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  return [
    {
      mapLayerType: mapLayerType,
      fillColor: rgbToHex(
        layerProperties.getLineColor[0],
        layerProperties.getLineColor[1],
        layerProperties.getLineColor[2]
      ),
      lineColor: rgbToHex(
        layerProperties.getLineColor[0],
        layerProperties.getLineColor[1],
        layerProperties.getLineColor[2]
      ),
      lineWidth: layerProperties.lineWidthMinPixels,
      iconColor: [],
      text: '',
      iconUrl: '',
      iconHeight: 0,
      iconWidth: 0,
      iconFilter: '',
      labelBackground: []
    }
  ];
}

export function getThematicPointLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  var items: LegendItem[] = [];

  if ('fillThematicScale' in layerProperties) {
    var legendConfig = { ...layerProperties.fillThematicScaleConfig };
    legendConfig.attr = 'legend';
    var scale: any = getThematicColor(
      layerProperties.fillThematicScale,
      legendConfig
    );

    layerProperties.fillThematicScaleConfig.domain.forEach((d: any) => {
      var data = {
        properties: { legend: d },
        type: 'Feature'
      };
      var result = scale(data);

      items.push({
        mapLayerType: mapLayerType,
        fillColor: rgbToHex(result[0], result[1], result[2]),
        lineColor: rgbToHex(
          layerProperties.getLineColor[0],
          layerProperties.getLineColor[1],
          layerProperties.getLineColor[2]
        ),
        lineWidth: 1,
        iconColor: [],
        text: d,
        iconUrl: '',
        iconHeight: 0,
        iconWidth: 0,
        iconFilter: '',
        labelBackground: []
      });
    });

    if ('othersColor' in layerProperties.fillThematicScaleConfig) {
      items.push(
        generateOthersColorPointLegendItem(
          mapLayerType,
          layerProperties.fillThematicScaleConfig
        )
      );
    }
  }

  return items;
}

export function getThematicBoundaryLegendInfo(
  layerProperties: any
): LegendItem[] {
  var items: LegendItem[] = [];

  if ('fillThematicScale' in layerProperties) {
    var legendConfig = { ...layerProperties.fillThematicScaleConfig };
    legendConfig.attr = 'legend';
    var scale: any = getThematicColor(
      layerProperties.fillThematicScale,
      legendConfig
    );

    layerProperties.fillThematicScaleConfig.domain.forEach((d: any) => {
      var data = {
        properties: { legend: d },
        type: 'Feature'
      };
      var result = scale(data);

      items.push({
        mapLayerType: 'Boundary',
        fillColor: rgbToHex(result[0], result[1], result[2]),
        lineColor: rgbToHex(
          layerProperties.getLineColor[0],
          layerProperties.getLineColor[1],
          layerProperties.getLineColor[2]
        ),
        lineWidth: layerProperties.lineWidthMinPixels,
        iconColor: [],
        text: d,
        iconUrl: '',
        iconHeight: 0,
        iconWidth: 0,
        iconFilter: '',
        labelBackground: []
      });
    });
  }

  if ('othersColor' in layerProperties.fillThematicScaleConfig) {
    items.push(
      generateOthersColorBoundaryLegendItem(
        'Boundary',
        layerProperties.fillThematicScaleConfig,
        layerProperties.lineWidthMinPixels
      )
    );
  }

  return items;
}

export function getThematicLineLegendInfo(layerProperties: any): LegendItem[] {
  var items: LegendItem[] = [];

  if ('lineThematicScale' in layerProperties) {
    var legendConfig = { ...layerProperties.lineThematicScaleConfig };
    legendConfig.attr = 'legend';
    var scale: any = getThematicColor(
      layerProperties.lineThematicScale,
      legendConfig
    );

    layerProperties.lineThematicScaleConfig.domain.forEach((d: any) => {
      var data = {
        properties: { legend: d },
        type: 'Feature'
      };
      var result = scale(data);

      items.push({
        mapLayerType: 'Line',
        fillColor: rgbToHex(result[0], result[1], result[2]),
        lineColor: rgbToHex(
          layerProperties.getLineColor[0],
          layerProperties.getLineColor[1],
          layerProperties.getLineColor[2]
        ),
        lineWidth: layerProperties.lineWidthMinPixels,
        iconColor: [],
        text: d,
        iconUrl: '',
        iconHeight: 0,
        iconWidth: 0,
        iconFilter: '',
        labelBackground: []
      });
    });
  }

  if ('othersColor' in layerProperties.lineThematicScaleConfig) {
    items.push(
      generateOthersColorBoundaryLegendItem(
        'Line',
        layerProperties.lineThematicScaleConfig,
        layerProperties.lineWidthMinPixels
      )
    );
  }

  return items;
}

export function getThematicIconLegendInfo(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  var itemsColours: LegendItem[] = [];
  var itemsIcons: LegendItem[] = [];

  itemsIcons = getIconAttributeLegendItems(mapLayerType, layerProperties);

  itemsColours = getThematicScaleIconLegendItems(mapLayerType, layerProperties);

  if (!layerProperties.iconMask) {
    return itemsIcons;
  }

  if (itemsColours.length > 0 && itemsIcons.length > 0) {
    return mergeIconAndColorLegendItems(itemsColours, itemsIcons);
  }

  return itemsColours;
}

export function mergeIconAndColorLegendItems(
  itemsColours: LegendItem[],
  itemsIcons: LegendItem[]
) {
  var itemsIconsColours: LegendItem[] = [];

  itemsColours.forEach((item, index) => {
    const newItem = { ...item };
    if (itemsIcons[index]) {
      newItem.iconUrl = itemsIcons[index].iconUrl;
    }
    itemsIconsColours.push(newItem);
  });

  return itemsIconsColours;
}

export function getIconAttributeLegendItems(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  const itemsIcons: LegendItem[] = [];

  if ('iconAttribute' in layerProperties) {
    layerProperties.iconDomain.forEach((d: any) => {
      const iconUrl = getIconUrl(
        layerProperties.iconAttribute,
        layerProperties.iconDomain,
        layerProperties.icons,
        layerProperties.iconSourceUrl,
        layerProperties.iconOther,
        d
      );

      itemsIcons.push({
        mapLayerType,
        fillColor: [],
        lineColor: [],
        lineWidth: 1,
        iconColor: layerProperties.iconColor,
        text: d,
        iconUrl,
        iconHeight: 24,
        iconWidth: 24,
        iconFilter: '',
        labelBackground: []
      });
    });
  }

  return itemsIcons;
}

export function getThematicScaleIconLegendItems(
  mapLayerType: MapLayerTypes,
  layerProperties: any
): LegendItem[] {
  const itemsColours: LegendItem[] = [];

  if ('iconThematicScale' in layerProperties) {
    const legendConfig = {
      ...layerProperties.iconThematicScaleConfig,
      attr: 'legend'
    };
    var scale: any = getThematicColor(
      layerProperties.iconThematicScale,
      legendConfig
    );

    layerProperties.iconThematicScaleConfig.domain.forEach((d: any) => {
      const data = { properties: { legend: d }, type: 'Feature' };
      const result = scale(data);
      const filter = getFilterColor(result);
      const iconColor = rgbToHex(result[0], result[1], result[2]);

      itemsColours.push({
        mapLayerType,
        fillColor: [],
        lineColor: [],
        lineWidth: 1,
        iconColor,
        text: d,
        iconUrl: layerProperties.iconUrl,
        iconHeight: 24,
        iconWidth: 24,
        iconFilter: filter,
        labelBackground: []
      });
    });

    if ('othersColor' in layerProperties.iconThematicScaleConfig) {
      itemsColours.push(
        generateOthersColorIconLegendItem(
          mapLayerType,
          layerProperties.iconThematicScaleConfig,
          layerProperties.iconOther
        )
      );
    }
  }
  return itemsColours;
}

export function getFilterColor(rgb: any) {
  if (rgb != undefined) {
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.solve();

    return result.filterNoPropertyName;
  }

  return '';
}

export function generateOthersColorBoundaryLegendItem(
  mapLayerType: MapLayerTypes,
  thematicScaleConfig: any,
  lineWidth: any
): LegendItem {
  return {
    mapLayerType: mapLayerType,
    fillColor: rgbToHex(
      thematicScaleConfig.othersColor[0],
      thematicScaleConfig.othersColor[1],
      thematicScaleConfig.othersColor[2]
    ),
    lineColor: [],
    lineWidth: lineWidth,
    iconColor: [],
    text: 'Other',
    iconUrl: '',
    iconHeight: 0,
    iconWidth: 0,
    iconFilter: '',
    labelBackground: []
  };
}

export function generateOthersColorPointLegendItem(
  mapLayerType: MapLayerTypes,
  thematicScaleConfig: any
): LegendItem {
  return {
    mapLayerType: mapLayerType,
    fillColor: rgbToHex(
      thematicScaleConfig.othersColor[0],
      thematicScaleConfig.othersColor[1],
      thematicScaleConfig.othersColor[2]
    ),
    lineColor: [],
    lineWidth: 1,
    iconColor: [],
    text: 'Other',
    iconUrl: '',
    iconHeight: 0,
    iconWidth: 0,
    iconFilter: '',
    labelBackground: []
  };
}

export function generateOthersColorIconLegendItem(
  mapLayerType: MapLayerTypes,
  thematicScaleConfig: any,
  iconUrl: string
): LegendItem {
  var filter = getFilterColor(thematicScaleConfig.othersColor);

  return {
    mapLayerType: mapLayerType,
    fillColor: [],
    lineColor: [],
    lineWidth: 1,
    iconColor: rgbToHex(
      thematicScaleConfig.othersColor[0],
      thematicScaleConfig.othersColor[1],
      thematicScaleConfig.othersColor[2]
    ),
    text: 'Others',
    iconUrl: iconUrl,
    iconHeight: 24,
    iconWidth: 24,
    iconFilter: filter,
    labelBackground: []
  };
}

export function buildMapLayerLegend(
  mapLayerType: MapLayerTypes,
  layerProperties: any
) {
  const mapLayerTypes = {
    Boundary: (layerProperties: any) =>
      getBoundaryLegendInfo('Boundary', layerProperties),
    Point: (layerProperties: any) =>
      getPointLegendInfo('Point', layerProperties),
    Icon: (layerProperties: any) => getIconLegendInfo('Icon', layerProperties),
    CustomIcon: (layerProperties: any) =>
      getIconLegendInfo('Icon', layerProperties),
    InSiteEverywhereMapPin: (layerProperties: any) =>
      getIconLegendInfo('InSiteEverywhereMapPin', layerProperties),
    Label: (layerProperties: any) =>
      getLabelLegendInfo('Label', layerProperties),
    H3Hexagon: (layerProperties: any) =>
      getBoundaryLegendInfo('Boundary', layerProperties),
    LabelTileSet: (layerProperties: any) =>
      getLabelLegendInfo('Label', layerProperties),
    BoundaryTileSet: (layerProperties: any) =>
      getBoundaryLegendInfo('Boundary', layerProperties),
    PointTileSet: (layerProperties: any) =>
      getPointLegendInfo('Point', layerProperties),
    IconTileSet: (layerProperties: any) =>
      getIconLegendInfo('Icon', layerProperties),
    ThematicBoundary: (layerProperties: any) =>
      getThematicBoundaryLegendInfo(layerProperties),
    ThematicPoint: (layerProperties: any) =>
      getThematicPointLegendInfo('Point', layerProperties),
    ThematicIcon: (layerProperties: any) =>
      getThematicIconLegendInfo('Icon', layerProperties),
    ThematicInSiteEverywhereMapPin: (layerProperties: any) =>
      getThematicIconLegendInfo('InSiteEverywhereMapPin', layerProperties),
    ThematicBoundaryTileSet: (layerProperties: any) =>
      getThematicBoundaryLegendInfo(layerProperties),
    ThematicPointTileSet: (layerProperties: any) =>
      getThematicPointLegendInfo('Point', layerProperties),
    ThematicIconTileSet: (layerProperties: any) =>
      getThematicIconLegendInfo('Icon', layerProperties),
    ThematicH3HexagonTileSet: (layerProperties: any) =>
      getThematicBoundaryLegendInfo(layerProperties),
    ThematicH3Hexagon: (layerProperties: any) =>
      getThematicBoundaryLegendInfo(layerProperties),
    Line: (layerProperties: any) => getLineLegendInfo('Line', layerProperties),
    LineTileSet: (layerProperties: any) =>
      getLineLegendInfo('Line', layerProperties),
    ThematicLine: (layerProperties: any) =>
      getThematicLineLegendInfo(layerProperties),
    ThematicLineTileSet: (layerProperties: any) =>
      getThematicLineLegendInfo(layerProperties)
  };

  return mapLayerTypes[mapLayerType](layerProperties);
}

export function isLayerSpotlightSupported(layer: any) {
  // Validate props and type directly using optional chaining and nullish coalescing operator
  const layerType = layer?.props?.type as MapLayerTypes | undefined;

  //  colorContinuous not supported
  if (isThematicScaleColorContinuous(layer)) {
    return false;
  }

  // Check if the type matches the desired values
  return (
    layerType == 'ThematicBoundary' ||
    layerType == 'ThematicBoundaryTileSet' ||
    layerType == 'ThematicIcon' ||
    layerType == 'ThematicIconTileSet' ||
    layerType == 'ThematicPoint' ||
    layerType == 'ThematicPointTileSet' ||
    layerType == 'ThematicLine' ||
    layerType == 'ThematicLineTileSet'
  );
}

export function isThematicScaleColorContinuous(layer: any) {
  return layer &&
    layer?.props &&
    layer?.props?.fillThematicScale == 'colorContinuous'
    ? true
    : false;
}

export function isLayerMultiThemeSupported(layer: any) {
  // Validate props and type directly using optional chaining and nullish coalescing operator
  const layerType = layer?.props?.type as MapLayerTypes | undefined;

  // Check if the type matches the desired values
  return (
    layerType == 'ThematicBoundary' ||
    layerType == 'ThematicBoundaryTileSet' ||
    layerType == 'ThematicPoint' ||
    layerType == 'ThematicPointTileSet' ||
    layerType == 'Label' ||
    layerType == 'LabelTileSet'
  );
}

export function isLabelLayer(layer: any) {
  // Validate props and type directly using optional chaining and nullish coalescing operator
  const layerType = layer?.props?.type as MapLayerTypes | undefined;

  // Check if the type matches the desired values
  return layerType === 'Label' || layerType === 'LabelTileSet';
}

export function isIconLayer(layer: any) {
  // Validate props and type directly using optional chaining and nullish coalescing operator
  const layerType = layer?.props?.type as MapLayerTypes | undefined;

  // Check if the type matches the desired values
  return layerType === 'ThematicIcon' || layerType === 'ThematicIconTileSet';
}

export function getLayerThematicColours(layer: any) {
  const type = layer.props.type;
  let colours;

  let legendInfo;

  switch (type) {
    case 'ThematicLine':
    case 'ThematicLineTileSet':
      colours = layer.props.lineThematicScaleConfig.colors;
      legendInfo = layer.props.legendInfo;

      return convertCartoColoursToRgb(legendInfo, colours, 'fillColor');

    case 'ThematicIcon':
    case 'ThematicIconTileSet':
      if (layer.props?.iconThematicScaleConfig === undefined) {
        return [];
      }

      colours = layer.props.iconThematicScaleConfig.colors;
      legendInfo = layer.props.legendInfo;

      return convertCartoColoursToRgb(legendInfo, colours, 'iconFilter');

    default:
      colours = layer.props.fillThematicScaleConfig.colors;
      legendInfo = layer.props.legendInfo;

      return convertCartoColoursToRgb(legendInfo, colours, 'fillColor');
  }
}

export function isTextRGB(text: string) {
  return text.toString().includes(',');
}

export function convertCartoColoursToRgb(
  legendInfo: any,
  colours: any,
  colourKey: string
) {
  if (!isTextRGB(colours.toString())) {
    return legendInfo.map((item: any) => hexToRgb(item[colourKey]));
  }

  return colours;
}
