/// <reference types="@types/googlemaps" />
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Point } from '../models/point.model';
import { environment } from 'src/environments/environment';
import { SearchResult } from '../models/search-result';
import { DataDogService } from 'src/app/core/services/datadog.service';

@Injectable({ providedIn: 'root' })
export class GazetteerService {
  constructor(
    private http: HttpClient,
    private dataDogService: DataDogService
  ) {}

  // country code two letter ISO 3166-1 country code. Uk is 'gb'
  getLocationPredictions(
    address: string,
    countryCode: string | null
  ): Observable<SearchResult[]> {
    const startTime = performance.now();

    return this.getGoogleLocationPredictions(address, countryCode).pipe(
      map((data: google.maps.places.AutocompletePrediction[]) => {
        const duration = performance.now() - startTime;
        this.dataDogService.trackApiTiming(
          'google_location_predictions_api',
          duration
        );
        this.dataDogService.trackUserAction(
          'google_location_predictions_api_success',
          {
            endpoint: address,
            duration
          }
        );

        return data.map((prediction) => {
          let result: SearchResult = {
            latitude: 0,
            longitude: 0,
            identifier: prediction.place_id,
            description: prediction.description, //prediction.description,
            googleSearchText: this.getGoogleMainText(prediction),
            datasource: 'Google',
            action: 'DropPin'
          };
          return result;
        });
      })
    );
  }

  getGoogleMainText(prediction: google.maps.places.AutocompletePrediction) {
    return prediction.types.includes('postal_code')
      ? prediction.structured_formatting.main_text
      : prediction.description;
  }

  // https://stackoverflow.com/questions/43459411/how-to-build-a-service-which-returns-an-observable
  // All examples found use Promises, but for consistensy with the rest of the project we transform them
  // into observables.
  private getGoogleLocationPredictions(
    address: string,
    countryCode: string | null
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const autocompleteSessionToken =
      new google.maps.places.AutocompleteSessionToken();

    return new Observable((obs) => {
      let getGooglePredictions = (
        predictions: google.maps.places.AutocompletePrediction[],
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            obs.next([]);
            obs.complete();
          }

          obs.error(status);
        } else {
          obs.next(predictions);
          obs.complete();
        }
      };
      const countryRestriction = countryCode == null ? '' : countryCode;
      // Autocomplete types supported:
      // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
      // Suggestion in case we want to add more than one type:
      // https://stackoverflow.com/questions/21139700/return-multiple-types-from-google-places-autocompleteservice-getplacepredictions
      // No types added to the request at the moment
      autocompleteService.getPlacePredictions(
        {
          input: address,
          sessionToken: autocompleteSessionToken,
          componentRestrictions: { country: countryRestriction }
        },
        getGooglePredictions
      );
    });
  }

  // Country code must be two letter ISO 3166-1. Uk is 'gb'
  getLatLongLocation(
    address: string,
    countryCode: string | null
  ): Observable<Point> {
    return this.geocodeGoogleLocation(address, countryCode).pipe(
      map((latlon: google.maps.GeocoderResult) => {
        return {
          latitude: latlon.geometry.location.lat(),
          longitude: latlon.geometry.location.lng()
        };
      })
    );
  }

  // Promise transformed into an observable in the same way as getGoogleLocationPredictions(...) method
  // if using component restriction you need to pass the address to the restriction property such as postalCode: address,locality: address
  private geocodeGoogleLocation(
    address: string,
    countryCode: string | null
  ): Observable<google.maps.GeocoderResult> {
    const geocoder = new google.maps.Geocoder();
    // google.maps.GeocoderRequest.componentRestrictions doesn't admit an empty string value
    const componentRestrictions =
      countryCode == null || countryCode === ''
        ? {}
        : {
            country: countryCode!
          };

    const request: google.maps.GeocoderRequest = {
      address,
      componentRestrictions
    };
    return this.getGeocodingObservable(geocoder, request);
  }

  getAddressFromLatLong(location: Point): Observable<string> {
    return this.reverseGeocodeLocation(location).pipe(
      map((result: google.maps.GeocoderResult) => {
        return result.formatted_address;
      })
    );
  }

  private reverseGeocodeLocation(
    location: Point
  ): Observable<google.maps.GeocoderResult> {
    const geocoder = new google.maps.Geocoder();

    const googlePointRequest = {
      lat: location.latitude,
      lng: location.longitude
    };
    const request: google.maps.GeocoderRequest = {
      location: googlePointRequest
    };
    return this.getGeocodingObservable(geocoder, request);
  }

  private getGeocodingObservable(
    geocoder: google.maps.Geocoder,
    request: google.maps.GeocoderRequest
  ): Observable<google.maps.GeocoderResult> {
    return new Observable((obs) => {
      let geocodeCallback = (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (
          status != google.maps.GeocoderStatus.OK &&
          status != google.maps.GeocoderStatus.ZERO_RESULTS
        ) {
          obs.error(status);
          return;
        }

        if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          const unknownLocation = this.createUnknownResult(request);

          obs.next(unknownLocation);
          obs.complete();
        } else {
          const result = this.getPreferredResult(results);
          obs.next(result!);
          obs.complete();
        }
      };

      geocoder.geocode(request, geocodeCallback);
    });
  }

  private getPreferredResult(
    results: google.maps.GeocoderResult[]
  ): google.maps.GeocoderResult | null {
    const preferredTypes = ['postal_code', 'locality', 'route'];

    for (const type of preferredTypes) {
      const result = results.find((r) => r.types.includes(type));
      if (result) {
        return result;
      }
    }

    if (results && results.length > 0) {
      return results[0];
    }

    return null;
  }

  private createUnknownResult(
    request: google.maps.GeocoderRequest
  ): google.maps.GeocoderResult {
    const unknownLocation: google.maps.LatLng =
      request.location instanceof google.maps.LatLng
        ? request.location
        : new google.maps.LatLng(request.location!.lat, request.location!.lng);

    const formattedAddress = `Unknown (${unknownLocation.lat()}, ${unknownLocation.lng()})`;

    return {
      formatted_address: formattedAddress,
      geometry: {
        location: unknownLocation,
        location_type: google.maps.GeocoderLocationType.APPROXIMATE,
        viewport: new google.maps.LatLngBounds(
          unknownLocation,
          unknownLocation
        ),
        bounds: new google.maps.LatLngBounds(unknownLocation, unknownLocation)
      },
      place_id: '',
      address_components: [],
      partial_match: false,
      postcode_localities: [],
      types: []
    };
  }

  public getCustomSearchResults(
    featureGroupId: number,
    feature: string,
    searchTerm: string
  ): Observable<SearchResult[]> {
    let customSearchApiUrl = `${environment.baseUrl}api/custom-search/feature/${feature}/feature-grouping/${featureGroupId}/search-term/${searchTerm}`;
    return this.http.get<SearchResult[]>(customSearchApiUrl);
  }
}
