<div class="container">
  <div class="pill" *ngIf="measureMode">
    <span class="pill-text">{{ measurement }}</span>
    <button
      color="primary"
      class="pill-close-btn"
      style="color: white"
      (click)="onCloseClicked()"
    >
      &#10005;
    </button>
  </div>

  <button
    *ngIf="!measureMode"
    color="primary"
    class="icon button"
    (click)="onMeasuredMapClicked('Area')"
  >
    <span class="material-symbols-outlined">square_foot</span>
  </button>

  <button
    *ngIf="!measureMode"
    color="primary"
    class="icon button"
    (click)="onMeasuredMapClicked('Distance')"
  >
    <span class="material-symbols-outlined">Straighten</span>
  </button>
</div>
