import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { loadAppFeaturesSucceeded } from '../actions/app-feature-ui.actions';
import {
  getUserTenantsInfoAttempt,
  getUserTenantsInfoFailed,
  getUserTenantsInfoSucceeded,
  logOutUserAfterSwappingTenant,
  updateUserTenantAttempt,
  updateUserTenantFailed,
  updateUserTenantSucceeded
} from '../actions/tenant-management.actions';
import { TenantManagementService } from '../../services/tenant-management.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { of } from 'rxjs';
import { checkFeatureUpdateAccepted, initialiseAppAttempt } from '../actions';
import { checkDataUpdateAccepted } from '../actions/data-update.actions';

@Injectable()
export class TenantManangementEffects {
  constructor(
    private actions$: Actions,
    private tenantManagementService: TenantManagementService,
    private authService: AuthService
  ) {}

  loadAppFeaturesSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initialiseAppAttempt),
      map(() => {
        return checkDataUpdateAccepted();
      })
    )
  );

  checkFeatureUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initialiseAppAttempt),
      map(() => {
        return checkFeatureUpdateAccepted();
      })
    )
  );

  getAvailableTenantsForCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserTenantsInfoAttempt),
      switchMap(() =>
        this.tenantManagementService.getAvailableTenants().pipe(
          map((userTenantsInfo) => {
            return getUserTenantsInfoSucceeded({ userTenantsInfo });
          }),
          catchError((error) => {
            this.authService.logout();
            return of(
              getUserTenantsInfoFailed({
                errorOn: '',
                error: $localize`:@@tenantInfoFailed:The available tenants have not been configured correctly. Please contact your administrator.`
              })
            ).pipe(delay(1000));
          })
        )
      )
    )
  );

  updateCurrentTenantAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserTenantAttempt),
      switchMap(({ tenantId, tenantName }) =>
        this.tenantManagementService
          .setCurrentUserTenant(tenantId, tenantName)
          .pipe(
            map((userTenantsInfo) => {
              return updateUserTenantSucceeded({ userTenantsInfo });
            }),
            catchError((error) =>
              of(
                updateUserTenantFailed({
                  errorOn: $localize`:@@switchTenant:Switch Tenant`,
                  error: error
                })
              )
            )
          )
      )
    )
  );

  updateCurrentTenantSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserTenantSucceeded),
      map(() => {
        return logOutUserAfterSwappingTenant();
      })
    )
  );

  logoutAfterSwappingTenants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logOutUserAfterSwappingTenant),
        map(() => {
          this.authService.logout();
        })
      ),
    { dispatch: false }
  );
}
