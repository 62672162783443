import { supplyPointDictionary } from 'src/app/spatial-modeller/helpers/supply-point.helpers';

export function formatPropertyKeysMap(
  value: Array<{ [k: string]: any }> | null
): Array<{ [k: string]: any }> | null {
  if (!value) return null;

  return value.map((item) => {
    const formattedObject: { [k: string]: any } = {};

    Object.entries(item).forEach(([key, val]) => {
      const formattedKey = supplyPointDictionary[key] || key;
      formattedObject[formattedKey] = val;
    });

    return formattedObject;
  });
}
