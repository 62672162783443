<div *ngIf="deltaSummary$ | async as summary">
  <div>
    <ng-container i18n="@@scenarioChangesSummary">
      {{ summary.closed + summary.added + summary.edited }} change(s) in
      scenario.
    </ng-container>

    <span
      [class]="disabled ? 'link-disabled' : 'link'"
      *ngIf="layoutService.isDesktopView$ | async"
      (click)="!disabled ? onViewDeltaList() : $event.preventDefault()"
    >
      <u i18n="@@viewList">View list</u>
    </span>
  </div>
  <div
    [class]="
      disabled ? 'summary-item-container-disabled' : 'summary-item-container'
    "
    class="summary-item-container"
  >
    <div i18n="@@newChanges">{{ summary.added }} New</div>
    <div i18n="@@editedChanges">{{ summary.edited }} Edited</div>
    <div i18n="@@closedChanges">{{ summary.closed }} Closed</div>
  </div>
</div>
