<atlas-panel-header>
  <div header i18n="@@managementPanel">Management panel</div>
  <div description i18n="@@swipeLeftPropertiesDescription">
    Swipe left to access the Properties panel
  </div>
</atlas-panel-header>
<mat-divider></mat-divider>

<div class="atlas-panel-body">
  <atlas-scenario-management
    class="scenario-management-container"
  ></atlas-scenario-management>
  <atlas-library-management></atlas-library-management>
  <atlas-profiler-library-management></atlas-profiler-library-management>
  <atlas-feature-management-panel></atlas-feature-management-panel>
</div>
