<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="edit scenario"
    (click)="onOpenEditScenarioDialog()"
    i18n-matTooltip="@@editScenarioTooltip"
    matTooltip="Edit scenario"
    [matTooltipShowDelay]="1000"
    [disabled]="disabled"
    i18n-aria-label="@@editScenarioAriaLabel"
  >
    <mat-icon class="edit-icon">edit</mat-icon>
  </button>
</div>
