import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { SpatialModellerImportStatusService } from 'src/app/spatial-modeller/services/spatial-modeller-import-status.service';
import {
  openSmImportDialogAfterLoadingApp,
  pollSmImportScenarioDataStatusCompleted,
  pollSmImportScenarioDataStatusFailed,
  pollSmImportScenarioDataStatusInProgress,
  pollSmImportScenarioDataStatusStarted
} from '../actions/spatial-modeller-import-status.actions';
import { smFileImportHeaderValidationSuccess } from '../actions/sm-import-validation.actions';
import { getScenarioSucceeded } from '../actions/scenario.actions';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SmImportScenarioDataDialogComponent } from 'src/app/core/components/scenario-management/sm-import-scenario-data-dialog/sm-import-scenario-data-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { hasGeocodingCompanyLimitBeenExceededAttempt } from 'src/app/reporting/store/actions/usage.actions';
import { hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt } from '../actions/sm-import.actions';

@Injectable()
export class SpatialModellerImportStatusEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private smImportStatusService: SpatialModellerImportStatusService,
    private dialogService: DialogService
  ) {}

  getLocatorImportLibraryDataSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getScenarioSucceeded),
      switchMap(({ scenario }) =>
        this.smImportStatusService.getImportSmScenarioStatus(scenario.id).pipe(
          map((smImportStatus) => {
            return openSmImportDialogAfterLoadingApp({
              smImportStatus
            });
          })
        )
      )
    )
  );

  openSmImportDialogAfterLoadingApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openSmImportDialogAfterLoadingApp),
      filter(
        ({ smImportStatus }) =>
          smImportStatus &&
          smImportStatus?.status !== 'ProcessingFileDataCompleted' &&
          smImportStatus?.status !== 'FileUploadFailed'
      ),
      map(({ smImportStatus }) => {
        this.onOpenNpImportDataDialog(smImportStatus.scenarioId);
        return pollSmImportScenarioDataStatusStarted({
          scenarioId: smImportStatus.scenarioId
        });
      })
    )
  );

  restartPollingAfterReuploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smFileImportHeaderValidationSuccess),
      withLatestFrom(this.store$.select(fromSmStore.getScenarioId)),
      map(([_, scenarioId]) => {
        return pollSmImportScenarioDataStatusStarted({ scenarioId });
      })
    )
  );

  pollSmImportStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollSmImportScenarioDataStatusStarted),
      switchMap(({ scenarioId }) =>
        this.smImportStatusService
          .pollForSmImportScenarioStatus(scenarioId)
          .pipe(
            withLatestFrom(this.store$.select(fromSmStore.getScenarioId)),
            filter(
              ([smImportStatus, currentScenarioId]) =>
                smImportStatus?.scenarioId === currentScenarioId
            ),
            map(([smImportStatus, _]) => {
              if (smImportStatus.status === 'ProcessingFileDataCompleted') {
                return pollSmImportScenarioDataStatusCompleted({
                  smImportStatus
                });
              } else {
                return smImportStatus.errorMessage != null
                  ? pollSmImportScenarioDataStatusFailed({
                      smImportStatus
                    })
                  : pollSmImportScenarioDataStatusInProgress({
                      smImportStatus
                    });
              }
            })
          )
      )
    )
  );

  refreshHasGeocodingCompanyLimitBeenExceededAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pollSmImportScenarioDataStatusCompleted),
        map(() =>
        // The scenario might have reached the Geocoding limit.
        // The store should be refreshed to update the state.
          hasGeocodingCompanyLimitBeenExceededAttempt()
        )
      )
  );

  refresSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pollSmImportScenarioDataStatusCompleted),
        map(({smImportStatus}) =>
        // The scenario might have reached the number of supply point limit.
        // The store should be refreshed to update the state.
        hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt({scenarioId: smImportStatus.scenarioId})
        )
      )
  );

  private onOpenNpImportDataDialog(scenarioId: number) {
    this.dialogService.show(SmImportScenarioDataDialogComponent, {
      width: DialogWidth.Medium,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: '',
        mode: DialogMode.Add,
        headerPrefix: $localize`:@@importLocations:Import Locations`,
        affirmativeButtonText: $localize`:@@continue:Continue`,
        scenarioId,
        tenantImportUsingGeocoding: false,
        openAfterRefreshingApp: true
      },
      disableClose: true
    });
  }
}
