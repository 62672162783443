<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div class="message-content" i18n="@@saveOrDiscardMessage">
      Do you want to save or discard your edits?
    </div>
    <p></p>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button
        label="Discard"
        (clicked)="onDiscard()"
        i18n-label="@@discardButtonLabel"
      ></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Save"
        (clicked)="onSave()"
        i18n-label="@@saveButtonLabel"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
