import { OnInit, OnDestroy, Input, Component } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';
import { createState } from 'src/app/shared/utils/create-state';

// allows handling of a row selection cause by a user click
// built in onSelectionChanged event does not differentiate between a user and programtic selection
@Component({
  selector: 'atlas-dx-grid-header',
  templateUrl: './atlas-dx-data-header.component.html',
  styleUrls: ['./atlas-dx-data-header.component.less']
})
export class DxDataGridHeaderComponent implements OnInit, OnDestroy {
  constructor(private dataGridHost: DxDataGridComponent) {}

  private subscription = new Subscription();

  showFilterReset = false;
  rowCountText = '';
  filterValues = '';
  rowCount = createState<string>(this.rowCountText);

  @Input()
  gridTitle: string;

  ngOnInit() {
    this.subscription.add(
      this.dataGridHost.onToolbarPreparing.subscribe(this.toolbarPreparingEvent)
    );

    this.subscription.add(
      this.dataGridHost.onContentReady.subscribe(this.contentReadyEvent)
    );

    this.subscription.add(
      this.dataGridHost.onOptionChanged.subscribe(this.optionChangedEvent)
    );

    this.subscription.add(
      this.dataGridHost.onEditorPreparing.subscribe(this.editorPreparingEvent)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toolbarPreparingEvent = (event: any) => {
    event.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'gridTitleTemplate'
      },
      {
        location: 'before',
        template: 'rowCountTemplate'
      }
      /*// remove golbal search filter text
      {
        location: 'after',
        template: 'filterTextTemplate',
      }*/
      // removed the filter builder from the header
      /*{
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'filter',
          onClick: this.showFilterBuilder.bind(this),
        },
      }*/
    );

    this.moveColumnChooserToToolbarLastItem(event.toolbarOptions.items);
  };

  editorPreparingEvent = (event: any) => {
    this.preventChromeAutocompleteWithRandomText(event);
  };

  contentReadyEvent = (event: any) => {
    this.buildRowCountText(event);
    this.setPostionOfColumnChooserPopup(event);
  };

  optionChangedEvent = (event: any) => {
    // https://www.devexpress.com/Support/Center/Question/Details/T741347/datagrid-how-to-move-filter-panel-to-header
    if (
      event.name === 'searchPanel' ||
      event.name === 'columns' ||
      event.name === 'filterValue'
    ) {
      const gridInstance = event.component;
      const filterPanelView = gridInstance.getView('filterPanelView');
      const filterValue = filterPanelView.option('filterValue');

      let globalSearch = '';
      if (event.name === 'searchPanel') {
        globalSearch = '[Global search] = ' + event.value + ' ';
      }

      const customOperations = gridInstance
        .getController('filterSync')
        .getCustomFilterOperations();

      this.filterValues = globalSearch;
      if (filterValue) {
        filterPanelView
          .getFilterText(filterValue, customOperations)
          .then((filterPanelText: any) => {
            this.filterValues = globalSearch + filterPanelText;
          });
      }
    }
  };

  showFilterBuilder() {
    this.dataGridHost.instance.option('filterBuilderPopup.visible', true);
  }

  resetFilters() {
    this.dataGridHost.instance.clearFilter();
    this.dataGridHost.instance.clearFilter('header');
    this.dataGridHost.instance.clearFilter('row');
    this.dataGridHost.instance.clearFilter('search');
    this.dataGridHost.instance.clearFilter('dataSource');

    this.filterValues = '';
  }

  private setPostionOfColumnChooserPopup(e: any) {
    // https://www.devexpress.com/Support/Center/Question/Details/T400242/dxdatagrid-how-to-change-position-of-column-chooser
    const columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.option('position', {
        of: e.element,
        my: 'right top',
        at: 'right top',
        offset: '0 55'
      });

      columnChooserView._popupContainer.option('height', '90%');

      columnChooserView._popupContainer.option(
        'onResizeEnd',
        this.columnChooserResizeEnd
      );
    }
  }

  private columnChooserResizeEnd(e: any) {
    // prevent resize keep the height 90%
    const columnChooserView = e.component;
    columnChooserView.option('height', '90%');
  }

  private buildRowCountText(e: any) {
    const visibleRowsCount = e.component.totalCount();
    const totalCount = e.component.option('dataSource')?.length;

    if (visibleRowsCount === totalCount) {
      this.showFilterReset = false;
      this.rowCountText = $localize`:@@showingTotal:(Showing ${totalCount} total)`;
    } else {
      this.showFilterReset = true;
      this.rowCountText = $localize`:@@showingVisibleOfTotal:(Showing ${visibleRowsCount} of ${totalCount})`;
    }

    this.rowCount.set(this.rowCountText);
  }

  private moveColumnChooserToToolbarLastItem(toolbarItems: any) {
    toolbarItems.forEach((item: any, index: any) => {
      if (item.name === 'columnChooserButton') {
        this.repostionItemInArray(toolbarItems, index, toolbarItems.length - 1);
      }
    });
  }

  private repostionItemInArray(arr: any, fromIndex: any, toIndex: any) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  private preventChromeAutocompleteWithRandomText(event: any) {
    // Information about chrome and auto complete
    // https://www.devexpress.com/Support/Center/Question/Details/T578760/how-to-disable-browser-autocomplete-for-dxdatagrid-editors
    // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
    const randomString = '804CFCE3-84E0-4198-9BC7-918CEAA9B885';

    if (event.parentType === 'dataRow' || event.parentType === 'filterRow') {
      this.findAllInputsSetAutocompleteRandomString(
        event.element,
        randomString
      );

      // you would think you wouldnt need this but you do
      event.editorOptions.inputAttr = {
        autocomplete: randomString
      };
    }
  }

  private findAllInputsSetAutocompleteRandomString(
    element: any,
    randomString: any
  ) {
    const allInputs = element.querySelectorAll('input');
    allInputs.forEach((i: any) => {
      i.autocomplete = randomString;
    });
  }
}
