<div *ngIf="layer$ | async as layer" class="status-content">
  <div *ngIf="layer?.props?.mapLayerType == 'Boundary'">
    <span
      class="boundary-legend-icon"
      [style.background-color]="getSelectionColour()"
      [style.border-color]="layer.props.getLineColor"
    >
    </span>
  </div>

  <div *ngIf="layer?.props?.mapLayerType == 'Point'">
    <span
      class="point-legend-icon"
      [style.background-color]="getSelectionColour()"
      [style.border-color]="layer.props.getLineColor"
    >
    </span>
  </div>

  <div *ngIf="layer?.props?.mapLayerType == 'Icon'">
    <span class="icon-legend-icon">
      <img
        class="icon-size"
        [style.filter]="
          getIconSelectionColour(layer.props.id, layer.props.iconMask)
        "
        [attr.src]="getIconUrl(layer.props)"
      />
    </span>
  </div>

  <div *ngIf="layer?.props?.mapLayerType == 'Line'">
    <span
      class="line-legend-icon"
      [style.background-color]="getSelectionColour()"
    >
    </span>
  </div>

  <div *ngIf="layer?.props?.mapLayerType == 'InSiteEverywhereMapPin'">
    <span class="icon-legend-icon">
      <img
        [style.filter]="
          getIconSelectionColour(layer.props.id, layer.props.iconMask)
        "
        [attr.src]="layer.props.legendInfo[0].iconUrl"
      />
    </span>
  </div>

  <div *ngIf="isSMFeatureSelected$ | async">
    <div *ngIf="currentSupplyPoint$ | async as currentSupplyPoints">
      <div
        *ngIf="currentSupplyPoints?.userAction === userAction.Untouched"
        i18n="@@existingStatus"
      >
        Existing
      </div>
      <div
        *ngIf="
          currentSupplyPoints?.userAction === userAction.Added &&
          !currentSupplyPoints?.isClosed
        "
        i18n="@@newStatus"
      >
        New
      </div>
      <div
        *ngIf="
          currentSupplyPoints?.userAction === userAction.Edited &&
          !currentSupplyPoints?.isClosed
        "
        i18n="@@editedStatus"
      >
        Edited
      </div>
      <div *ngIf="currentSupplyPoints?.isClosed" i18n="@@closedStatus">
        Closed
      </div>
      <div
        *ngIf="currentSupplyPoints?.userAction === userAction.Temporary"
        i18n="@@temporaryStatus"
      >
        Temporary
      </div>
    </div>
  </div>
  <div class="location-description">
    <atlas-edit-location-description
      *ngIf="isLocatorFeatureSelected$ | async"
      class="edit-locator-location"
      [currentLocation]="selectedLocation$ | async"
      [areMultipleLocationsSelected]="areMultipleLocationsSelected$ | async"
    ></atlas-edit-location-description>
  </div>
</div>
