<div
  class="gazetteer-button"
  [class.button-enabled]="!(isFindMyLocationButtonDisabled$ | async)"
  i18n-matTooltip="@@useMyLocationTooltip"
  matTooltip="Use my location"
  [matTooltipShowDelay]="1000"
  [matTooltipHideDelay]="1000"
>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="Main menu"
    (click)="onFindMyLocation()"
    [disabled]="isFindMyLocationButtonDisabled$ | async"
  >
    <mat-icon>my_location</mat-icon>
  </button>
  <div class="button-underline"></div>
</div>
