<dx-data-grid
  class="standard-grid"
  keyExpr="supplyId"
  atlasDxDataGridDefaultOptions
  [dataSource]="dataSource"
  [selection]="{ mode: 'multiple' }"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
>
  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customLoad]="loadState"
    [customSave]="saveState"
  >
  </dxo-state-storing>

  <dxo-load-panel [enabled]="false"> </dxo-load-panel>

  <atlas-dx-grid-header gridTitle="Changes" i18n-gridTitle="@@changesTitle">
  </atlas-dx-grid-header>
  <div *dxTemplate="let data of 'parentTemplate'">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</dx-data-grid>
