<div class="zoom-container">
  <button (click)="onZoomOutClicked()" class="zoom-buttons zoom-out">
    <mat-icon>remove</mat-icon>
  </button>
  <button class="zoom-buttons zoom-indicator">
    {{ this.mapService.zoom | number : '.0-0' }}
  </button>
  <button (click)="onZoomInClicked()" class="zoom-buttons zoom-in">
    <mat-icon>add</mat-icon>
  </button>
</div>

@if (layoutService.isDesktopView$ | async) {
<div>
  <div class="map-icons-container">
    @if (showButtons) {
    <atlas-map-measure-control
      [measurement]="measurement"
      [measureMode]="this.mapService.measureMode$ | async"
      (measureOptionClicked)="onMeasuredMapClicked($event)"
      (close)="onMeasuredMapCloseClicked()"
    ></atlas-map-measure-control>

    <button
      matSuffix
      mat-icon-button
      color="primary"
      class="icon button"
      (click)="onToggleMap3d()"
    >
      <span class="material-symbols-outlined">3d_rotation </span>
    </button>

    <button
      matSuffix
      mat-icon-button
      color="primary"
      class="icon button"
      (click)="onCopydMapClicked()"
    >
      <span class="material-symbols-outlined">content_copy</span>
    </button>

    <button
      matSuffix
      mat-icon-button
      color="primary"
      class="icon button"
      (click)="onDownloadMapClicked()"
    >
      <span class="material-symbols-outlined">download_2</span>
    </button>
    }
  </div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="icon main-icon button"
    (click)="onScreenshotClicked()"
  >
    <span class="material-symbols-outlined">map</span>
  </button>

  @if (showButtons) {
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="close-icon main-icon button"
    (click)="onCloseClicked()"
  >
    <span class="material-symbols-outlined">Close</span>
  </button>
  }
</div>
}
