<div class="container">
  <div class="header-padding text" i18n="@@importOverview">Import overview</div>
  <div class="summary-container">
    <div class="summary-item">
      <span class="summary-item-title" i18n="@@recordsUploadedTitle"
        >Records Uploaded</span
      >
      <div class="count-text">{{ processedRecords | number : '1.0-0' }}</div>
    </div>
    <div class="summary-item">
      <span class="summary-item-title" i18n="@@importedSuccessfully"
        >Imported successfully</span
      >
      <div class="count-text">{{ importedRecords | number : '1.0-0' }}</div>
    </div>
  </div>
</div>
