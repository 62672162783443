<div style="padding-bottom: 100px">
  <div
    *ngIf="multipleCatchmentsFormArray != null"
    class="table-container"
    [class.disabled]="!isMultiCatchmentsMode"
  >
    <table mat-table [dataSource]="dataSource!" class="scroll-bar">
      <ng-container matColumnDef="type">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="first-column-width"
          i18n="@@typeColumn"
        >
          Type
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            *ngIf="element.type === locatorShapeTypes.Circle"
            class="atlas-icon icon-circle"
          ></div>
          <mat-icon *ngIf="element.type === locatorShapeTypes.Car"
            >directions_car</mat-icon
          >
          <mat-icon *ngIf="element.type === locatorShapeTypes.Walk"
            >directions_walk</mat-icon
          >
          <mat-icon *ngIf="element.type === locatorShapeTypes.PublicTransport"
            >train</mat-icon
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="column-width description-align"
          i18n="@@descriptionColumn"
        >
          Description
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="description-align truncate-description"
        >
          {{ element.description | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="last-column-width"></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [disabled]="!isMultiCatchmentsMode"
            class="delete-icon"
            (click)="onDeleteCatchment(element)"
            aria-label="delete catchment"
            i18n-aria-label="@@deleteCatchmentButton"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['type', 'description', 'delete']"
      ></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: ['type', 'description', 'delete']"
      ></tr>
    </table>
  </div>
  <div>
    <div
      class="empty-list"
      *ngIf="dataSource?.length === 0"
      i18n="@@noCatchmentsDefined"
    >
      No catchments defined.
    </div>
    <div>
      <button
        matSuffix
        mat-flat-button
        color="primary"
        aria-modal="true"
        aria-label="add catchment"
        [matMenuTriggerFor]="addCatchmentMenu"
        [disabled]="this.dataSource?.length === 20 || !isMultiCatchmentsMode"
        i18n-aria-label="@@addCatchmentButton"
      >
        <ng-container>
          <div class="add-catchment-button">
            <mat-icon>add</mat-icon>
            <div class="text">
              <span i18n="@@addCatchmentButtonText">Add Catchment</span>
            </div>
          </div>
        </ng-container>
      </button>
    </div>
  </div>
</div>

<mat-menu #addCatchmentMenu="matMenu">
  <button
    mat-menu-item
    (click)="onAddCatchment(locatorShapeTypes.Circle)"
    [disabled]="isDisabled(this.locatorShapeDefaults?.circle)"
  >
    <mat-icon class="atlas-icon icon-circle"></mat-icon>
    <span i18n="@@circleOption">Circle</span>
  </button>
  <button
    mat-menu-item
    (click)="onAddCatchment(locatorShapeTypes.Car)"
    [disabled]="isDisabled(this.locatorShapeDefaults?.car)"
  >
    <mat-icon>directions_car</mat-icon>
    <span i18n="@@driveOption">Drive</span>
  </button>
  <button
    mat-menu-item
    (click)="onAddCatchment(locatorShapeTypes.PublicTransport)"
    [disabled]="isDisabled(this.locatorShapeDefaults?.publicTransport)"
  >
    <mat-icon>train</mat-icon>
    <span i18n="@@publicTransportOption">Public Transport</span>
  </button>
  <button
    mat-menu-item
    (click)="onAddCatchment(locatorShapeTypes.Walk)"
    [disabled]="isDisabled(this.locatorShapeDefaults?.walk)"
  >
    <mat-icon>directions_walk</mat-icon>
    <span i18n="@@walkOption">Walk</span>
  </button>
</mat-menu>
