<div class="combo-box">
  <!--<mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label i18n="@@baseLabel">Base</mat-label>
    <mat-select
      [value]="selectedBaseId$ | async"
      (selectionChange)="onBaseSelectionChange($event)"
    >
      <mat-option *ngFor="let base of bases$ | async" [value]="base.id">{{
        base.name
      }}</mat-option>
    </mat-select>
  </mat-form-field> -->
  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label i18n="@@segmentationTypeLabel">Segmentation type</mat-label>
    <mat-select
      [hideSingleSelectionIndicator]="true"
      [value]="selectedClassificationId$ | async"
      (selectionChange)="onClassificationSelectionChange($event)"
    >
      <mat-option
        *ngFor="let classification of classifications$ | async"
        [value]="classification.id"
        >{{ classification.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
