import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromStore from 'src/app/reporting/store';
import * as fromUIStore from 'src/app/core/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { UsersGeocoderRecordsUsage } from '../../models/usage-summary';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-geocoded-records-usage-tab-content',
  templateUrl: './geocoded-records-usage-tab-content.component.html',
  styleUrl: './geocoded-records-usage-tab-content.component.less'
})
export class GeocodedRecordsUsageTabContentComponent {
  private subscription = new Subscription();

  showGrid = true;
  allUsersUsage: UsersGeocoderRecordsUsage[];
  allowInternalUsersUsage: boolean;

  userUsageSummary$ = this.Store$.select(
    fromStore.getGeocodedRecordsUserUsageSummary
  );
  companyUsageSummary$ = this.Store$.select(
    fromStore.getGeocodedRecordsCompanyUsageSummary
  );

  constructor(
    private Store$: Store<fromStore.State>,
    public layoutService: LayoutService,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );

    this.subscription.add(
      this.Store$.select(
        fromStore.getGeocodedRecordsAllUsersUsageSummary
      ).subscribe((usersUsage) => {
        this.allUsersUsage = hideCACIUsers(
          usersUsage,
          this.allowInternalUsersUsage
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggleGridChart() {
    this.showGrid = !this.showGrid;
  }

  getLinkText() {
    return this.showGrid
      ? $localize`:@@viewChartLink:View Chart`
      : $localize`:@@viewGridLink:View Grid`;
  }
}
