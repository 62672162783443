import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { LocatorShapeDefaults } from 'src/app/core/models/locator-shape-defaults';
import * as fromUserSettings from 'src/app/core/store';
import {
  getApplyMultiCatchmentsToExistingLocations,
  getLocatorShapesDefaults,
  getMultiCatchmentMode,
  getMultipleCatchmentsSelection,
  updateCatchmentReportingUserSettingsOnSaveClicked,
  updateLocatorUserSettingsOnSaveClicked
} from 'src/app/core/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { getDefaultDistanceUnit } from 'src/app/core/store/selectors/app-feature-ui.selectors';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { getShapeUnit } from 'src/app/shared/utils/shape-utils';
import {
  kilometersToMiles,
  milesToKilometers
} from 'src/app/shared/utils/distance-converter-utils';
import { isShapeDisabled } from 'src/app/locator/helpers/locator-shape.helper';
import { CatchmentReportingSettings } from 'src/app/core/models/catchment-reporting-settings';
import { MultiCatchment } from 'src/app/core/models/multi-catchment';

@Component({
  selector: 'atlas-catchment-reporting-settings-tab-content',
  templateUrl: './catchment-reporting-settings-tab-content.component.html',
  styleUrls: ['./catchment-reporting-settings-tab-content.component.less']
})
export class CatchmentReportingSettingsTabContentComponent
  implements OnInit, OnDestroy
{
  locatorSettingsFormGroup: FormGroup;
  locatorUserSettings$ = this.userSettingsStore$.select(
    fromUserSettings.getUserLocatorSettings
  );

  locatorShapesDefaults$ = this.store$.select(getLocatorShapesDefaults);
  defaultDistanceUnit$ = this.store$.select(getDefaultDistanceUnit);

  multiCatchmentsMode$ = this.store$.select(getMultiCatchmentMode);
  applyMultiCatchmentsToExistingLocations$ = this.store$.select(
    getApplyMultiCatchmentsToExistingLocations
  );
  multipleCatchmentsSelection$ = this.store$.select(
    getMultipleCatchmentsSelection
  );
  locatorShapeDefaults: LocatorShapeDefaults | null;
  locatorShapeSettingsToUse: LocatorShapeDefaults | null;

  driveUnit: string | null = null;
  walkUnit: string | null = null;
  circleUnit: string | null = null;
  polygonUnitToShow: string;
  publicTransportUnit: string;

  multiCatchmentsMode: boolean;
  applyMultiCatchmentsToExistingLocations: boolean;

  private subscription = new Subscription();
  maxCarRangeLimit: number;
  maxWalkRangeLimit: number;
  maxCircleRangeLimit: number;
  maxPublicTransportRangeLimit: number;
  minRangeLimit: number = 1;
  minCircleRangeLimit: number = 0;
  positiveIntegerPattern = new RegExp(`^[1-9]\\d*$`);
  multiCatchmentSelection: MultiCatchment[];

  constructor(
    private userSettingsStore$: Store<fromUserSettings.State>,
    private store$: Store<fromLocatorStore.State>,
    private fb: UntypedFormBuilder
  ) {
    this.locatorSettingsFormGroup = this.fb.group({
      carCatchmentSizeControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(this.minRangeLimit),
          Validators.pattern(this.positiveIntegerPattern)
        ])
      ],
      circleCatchmentSizeControl: [
        '',
        [Validators.required, Validators.min(this.minCircleRangeLimit)]
      ],
      walkCatchmentSizeControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(this.minRangeLimit),
          Validators.pattern(this.positiveIntegerPattern)
        ])
      ],
      polyCatchmentSizeControl: [
        null,
        Validators.compose([Validators.required, Validators.min(0.1)])
      ],
      publicTransportCatchmentSizeControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(this.minRangeLimit),
          Validators.pattern(this.positiveIntegerPattern)
        ])
      ],
      multiCatchmentMode: [false],
      multipleCatchmentsSelection: this.fb.array([]),
      applyMultiCatchmentsToExistingLocations: [false]
    });
  }

  ngOnInit() {
    this.subscription.add(
      this.locatorUserSettings$
        .pipe(
          withLatestFrom(this.locatorShapesDefaults$),
          map(([userDefaults, appDefaults]) => {
            if (userDefaults != null) {
              const locatorShapeSettingsToUse: LocatorShapeDefaults = {
                car: null,
                walk: null,
                circle: null,
                polygon: null,
                publicTransport: null
              };
              locatorShapeSettingsToUse.car =
                userDefaults.car == null ? appDefaults!.car : userDefaults.car;
              locatorShapeSettingsToUse.walk =
                userDefaults.walk == null
                  ? appDefaults!.walk
                  : userDefaults.walk;
              locatorShapeSettingsToUse.circle =
                userDefaults.circle == null
                  ? appDefaults!.circle
                  : userDefaults.circle;
              locatorShapeSettingsToUse.polygon =
                userDefaults.polygon == null
                  ? appDefaults!.polygon
                  : userDefaults.polygon;
              locatorShapeSettingsToUse.publicTransport =
                userDefaults.publicTransport == null
                  ? appDefaults!.publicTransport
                  : userDefaults.publicTransport;
              return locatorShapeSettingsToUse;
            } else {
              return appDefaults;
            }
          })
        )
        .subscribe((defaults) => {
          this.locatorShapeSettingsToUse = defaults;
          this.locatorSettingsFormGroup.patchValue({
            carCatchmentSizeControl: defaults?.car?.timeInSeconds! / 60,
            circleCatchmentSizeControl: defaults?.circle?.radius,
            walkCatchmentSizeControl: defaults?.walk?.timeInSeconds! / 60,
            publicTransportCatchmentSizeControl:
              defaults?.publicTransport?.timeInSeconds! / 60
          });
        })
    );

    this.subscription.add(
      this.locatorShapesDefaults$.subscribe((defaults) => {
        this.locatorShapeDefaults = defaults;
        this.maxCarRangeLimit = defaults?.car?.rangeLimit!;
        this.maxCircleRangeLimit = defaults?.circle?.rangeLimit!;
        this.maxWalkRangeLimit = defaults?.walk?.rangeLimit!;
        this.maxPublicTransportRangeLimit =
          defaults?.publicTransport?.rangeLimit!;
      })
    );

    this.subscription.add(
      this.defaultDistanceUnit$.subscribe((defaultDistanceUnit) => {
        this.driveUnit = getShapeUnit(
          LocatorShapeTypes.Car,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.walkUnit = getShapeUnit(
          LocatorShapeTypes.Walk,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.circleUnit = getShapeUnit(
          LocatorShapeTypes.Circle,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.publicTransportUnit = getShapeUnit(
          LocatorShapeTypes.PublicTransport,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.polygonUnitToShow = defaultDistanceUnit;
        this.locatorSettingsFormGroup.patchValue({
          // we need to know the distance unit to see if the convertion to miles is needed.
          // Polygon radius is always stored in KM
          polyCatchmentSizeControl:
            this.polygonUnitToShow === 'mile' &&
            this.locatorShapeSettingsToUse?.polygon
              ? kilometersToMiles(
                  this.locatorShapeSettingsToUse?.polygon.radiusInKilometers
                )
              : this.locatorShapeSettingsToUse?.polygon?.radiusInKilometers
        });
      })
    );

    this.subscription.add(
      this.multiCatchmentsMode$.subscribe((multiCatchmentMode) => {
        this.multiCatchmentsMode = multiCatchmentMode;
        this.locatorSettingsFormGroup.patchValue({
          multiCatchmentMode: multiCatchmentMode
        });
      })
    );

    this.subscription.add(
      this.applyMultiCatchmentsToExistingLocations$.subscribe(
        (applyMultiCatchmentsToExistingLocations) => {
          this.applyMultiCatchmentsToExistingLocations =
            applyMultiCatchmentsToExistingLocations;
          this.locatorSettingsFormGroup.patchValue({
            applyMultiCatchmentsToExistingLocations:
              applyMultiCatchmentsToExistingLocations
          });
        }
      )
    );

    this.subscription.add(
      this.multipleCatchmentsSelection$.subscribe((multiCatchments) => {
        this.multiCatchmentSelection = multiCatchments!;
        this.populateMultipleCatchmentsFormArray(
          multiCatchments as MultiCatchment[]
        );
        this.locatorSettingsFormGroup.patchValue({
          multipleCatchmentsSelection: multiCatchments
        });
        if (
          !this.multiCatchmentSelection ||
          this.multiCatchmentSelection.length === 0
        ) {
          this.locatorSettingsFormGroup.patchValue({
            multiCatchmentMode: false
          });
          this.multiCatchmentsMode = false;
        } else {
          this.locatorSettingsFormGroup.patchValue({
            multipleCatchmentsSelection: multiCatchments
          });
        }
      })
    );

    this.locatorSettingsFormGroup
      .get('multiCatchmentMode')
      ?.valueChanges.subscribe((multiCatchmentMode) => {
        const shouldDisable = multiCatchmentMode;
        this.toggleFieldState(shouldDisable);
      });

    this.toggleFieldState(
      this.locatorSettingsFormGroup.get('multiCatchmentMode')?.value === true
    );
  }

  onRadioChange(event: any) {
    this.multiCatchmentsMode = event.value;
  }

  onApplyMultiCatchmentsRadioChange(event: any) {
    this.applyMultiCatchmentsToExistingLocations = event.value;
  }

  get multipleCatchmentsFormArray(): FormArray {
    return this.locatorSettingsFormGroup.get(
      'multipleCatchmentsSelection'
    ) as FormArray;
  }

  populateMultipleCatchmentsFormArray(data: MultiCatchment[]): void {
    this.multipleCatchmentsFormArray.clear(); // Clear existing rows
    if (data && data.length > 0) {
      data.map((item) =>
        this.multipleCatchmentsFormArray.push(
          this.fb.group({
            ...item
          })
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleFieldState(shouldDisable: boolean) {
    const fieldsToToggle = [
      {
        name: 'carCatchmentSizeControl',
        shouldDisable:
          shouldDisable || isShapeDisabled(this.locatorShapeDefaults?.car)
      },
      {
        name: 'circleCatchmentSizeControl',
        shouldDisable:
          shouldDisable || isShapeDisabled(this.locatorShapeDefaults?.circle)
      },
      {
        name: 'walkCatchmentSizeControl',
        shouldDisable:
          shouldDisable || isShapeDisabled(this.locatorShapeDefaults?.walk)
      },
      {
        name: 'polyCatchmentSizeControl',
        shouldDisable:
          shouldDisable || isShapeDisabled(this.locatorShapeDefaults?.polygon)
      },
      {
        name: 'publicTransportCatchmentSizeControl',
        shouldDisable:
          shouldDisable ||
          isShapeDisabled(this.locatorShapeDefaults?.publicTransport)
      },
      {
        name: 'applyMultiCatchmentsToExistingLocations',
        shouldDisable: !shouldDisable
      }
    ];

    fieldsToToggle.forEach(({ name, shouldDisable }) => {
      const control = this.locatorSettingsFormGroup.get(name);
      if (control) {
        shouldDisable ? control.disable() : control.enable();
      }
    });
  }

  saveSettings() {
    const editedLocatorSettings: LocatorShapeDefaults = {
      car: {
        rangeLimit: this.maxCarRangeLimit,
        timeInSeconds:
          this.locatorSettingsFormGroup.get('carCatchmentSizeControl')!.value *
          60,
        distance: 0,
        isDisabled: this.locatorShapeDefaults?.car?.isDisabled!,
        driveCatchmentSpeed:
          this.locatorShapeDefaults?.car?.driveCatchmentSpeed!
      },
      circle: {
        radius: this.locatorSettingsFormGroup.get('circleCatchmentSizeControl')!
          .value,
        isDisabled: this.locatorShapeDefaults?.circle?.isDisabled!,
        rangeLimit: this.maxCircleRangeLimit
      },
      walk: {
        timeInSeconds:
          this.locatorSettingsFormGroup.get('walkCatchmentSizeControl')!.value *
          60,
        isDisabled: this.locatorShapeDefaults?.walk?.isDisabled!,
        rangeLimit: this.maxWalkRangeLimit,
        distance: 0,
        driveCatchmentSpeed:
          this.locatorShapeDefaults?.walk?.driveCatchmentSpeed!
      },
      polygon: {
        radiusInKilometers:
          this.polygonUnitToShow === 'mile' &&
          this.locatorShapeSettingsToUse?.polygon
            ? milesToKilometers(
                this.locatorSettingsFormGroup.get('polyCatchmentSizeControl')!
                  .value
              )
            : this.locatorSettingsFormGroup.get('polyCatchmentSizeControl')!
                .value,
        isDisabled: this.locatorShapeDefaults?.polygon?.isDisabled!,
        steps: this.locatorShapeDefaults?.polygon?.steps!
      },
      publicTransport: isShapeDisabled(
        this.locatorShapeDefaults?.publicTransport
      )
        ? null
        : {
            rangeLimit: this.maxPublicTransportRangeLimit,
            timeInSeconds:
              this.locatorSettingsFormGroup.get(
                'publicTransportCatchmentSizeControl'
              )!.value * 60,
            distance: 0,
            isDisabled: this.locatorShapeDefaults?.publicTransport?.isDisabled!,
            driveCatchmentSpeed:
              this.locatorShapeDefaults?.publicTransport?.driveCatchmentSpeed!
          }
    };
    this.store$.dispatch(
      updateLocatorUserSettingsOnSaveClicked({
        locator: editedLocatorSettings
      })
    );
    this.updateMultiCatchmentMode();
    const editedcatchmentSettings: CatchmentReportingSettings = {
      multiCatchmentMode: this.multiCatchmentsMode,
      showLibraryLocationsLayer: false,
      importUsingGeocoding: false,
      disableImportLocations: false,
      readOnlySystem: false,
      showSummaryStatsReport: false,
      multipleCatchmentsSelection: this.multiCatchmentSelection,
      applyMultiCatchmentsToExistingLocations:
        this.locatorSettingsFormGroup.get(
          'applyMultiCatchmentsToExistingLocations'
        )?.value
    };
    this.store$.dispatch(
      updateCatchmentReportingUserSettingsOnSaveClicked({
        catchmentReporting: editedcatchmentSettings
      })
    );
    this.locatorSettingsFormGroup.markAsPristine();
    this.multipleCatchmentsFormArray.markAsPristine();
  }

  emptyCarCatchmentError() {
    return this.locatorSettingsFormGroup.controls.carCatchmentSizeControl.errors
      ?.required;
  }
  emptyWalkCatchmentError() {
    return this.locatorSettingsFormGroup.controls.walkCatchmentSizeControl
      .errors?.required;
  }
  emptyCircleCatchmentError() {
    return this.locatorSettingsFormGroup.controls.circleCatchmentSizeControl
      .errors?.required;
  }
  emptyPublicTransportCatchmentError() {
    return this.locatorSettingsFormGroup.controls
      .publicTransportCatchmentSizeControl.errors?.required;
  }
  emptyPolyCatchmentError() {
    return this.locatorSettingsFormGroup.controls.polyCatchmentSizeControl
      .errors?.required;
  }

  minCarCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('carCatchmentSizeControl')
      ?.hasError('min');
  }

  minWalkCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('walkCatchmentSizeControl')
      ?.hasError('min');
  }

  minCircleCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('circleCatchmentSizeControl')
      ?.hasError('min');
  }
  minPublicTransportCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('publicTransportCatchmentSizeControl')
      ?.hasError('min');
  }

  minPolyCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('polyCatchmentSizeControl')
      ?.hasError('min');
  }

  maxCarCatchmentSizeError(): boolean {
    const control = this.locatorSettingsFormGroup.get(
      'carCatchmentSizeControl'
    );
    return control !== null && control.value > this.maxCarRangeLimit;
  }

  maxWalkCatchmentSizeError() {
    const control = this.locatorSettingsFormGroup.get(
      'walkCatchmentSizeControl'
    );
    return control !== null && control.value > this.maxWalkRangeLimit;
  }

  maxCircleCatchmentSizeError() {
    const control = this.locatorSettingsFormGroup.get(
      'circleCatchmentSizeControl'
    );
    return control !== null && control.value > this.maxCircleRangeLimit;
  }

  maxPublicTransportCatchmentSizeError() {
    const control = this.locatorSettingsFormGroup.get(
      'publicTransportCatchmentSizeControl'
    );
    return (
      control !== null && control.value > this.maxPublicTransportRangeLimit
    );
  }

  positiveIntegersCircleCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('circleCatchmentSizeControl')
      ?.hasError('pattern');
  }
  positiveIntegersWalkCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('walkCatchmentSizeControl')
      ?.hasError('pattern');
  }

  positiveIntegersCarCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('carCatchmentSizeControl')
      ?.hasError('pattern');
  }

  positiveIntegersPublicTransportCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('publicTransportCatchmentSizeControl')
      ?.hasError('pattern');
  }

  invalidForm() {
    return (
      this.emptyCarCatchmentError() ||
      this.emptyWalkCatchmentError() ||
      this.emptyCircleCatchmentError() ||
      this.emptyPolyCatchmentError() ||
      this.emptyPublicTransportCatchmentError() ||
      this.minCarCatchmentSizeError() ||
      this.minCircleCatchmentSizeError() ||
      this.minWalkCatchmentSizeError() ||
      this.minPolyCatchmentSizeError() ||
      this.minPublicTransportCatchmentSizeError() ||
      this.maxCarCatchmentSizeError() ||
      this.maxCircleCatchmentSizeError() ||
      this.maxWalkCatchmentSizeError() ||
      this.maxPublicTransportCatchmentSizeError() ||
      this.positiveIntegersCarCatchmentSizeError() ||
      this.positiveIntegersWalkCatchmentSizeError() ||
      this.positiveIntegersPublicTransportCatchmentSizeError() ||
      !this.locatorSettingsFormGroup.dirty
    );
  }

  disableFormControl(controlName: string, isDisabled: boolean) {
    const control = this.locatorSettingsFormGroup.get(controlName);
    if (control && isDisabled) {
      control.disable();
    }
  }

  private updateMultiCatchmentMode() {
    const hasNoSelection =
      !this.multiCatchmentSelection ||
      this.multiCatchmentSelection.length === 0;

    this.multiCatchmentsMode = hasNoSelection
      ? false
      : this.locatorSettingsFormGroup.get('multiCatchmentMode')?.value ?? false;

    this.locatorSettingsFormGroup.patchValue({
      multiCatchmentMode: this.multiCatchmentsMode
    });
  }
}
