import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Scenario } from '../../../models/scenario';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { Subscription } from 'rxjs';
import { ScenarioService } from 'src/app/spatial-modeller/services/scenario.service';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { swapScenarioAttempt } from 'src/app/spatial-modeller-store/actions/scenario.actions';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DeleteScenarioConfirmationDialogComponent } from '../delete-scenario-confirmation-dialog/delete-scenario-confirmation-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';

@Component({
  selector: 'atlas-swap-scenario-dialog',
  styleUrls: ['./swap-scenario-dialog.component.less'],
  templateUrl: './swap-scenario-dialog.component.html'
})
export class SwapScenarioDialogComponent implements OnInit, OnDestroy {
  dialogMode: DialogMode;
  userScenarios: Scenario[] = [];
  private subscription = new Subscription();

  closeText: string = $localize`:@@closeButtonText:Close`;

  constructor(
    private scenarioService: ScenarioService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerText: string;
      currentScenarioId: number;
    },
    private dialogRef: MatDialogRef<SwapScenarioDialogComponent>,
    private store$: Store<fromSmStore.State>
  ) {}

  ngOnInit() {
    this.getAllScenarios();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllScenarios() {
    this.subscription.add(
      this.scenarioService
        .getAllScenarios()
        .subscribe((scenarios: Scenario[]) => {
          this.userScenarios = scenarios;
        })
    );
  }

  onSwitchClicked(scenarioId: number) {
    this.store$.dispatch(
      swapScenarioAttempt({
        id: scenarioId
      })
    );

    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }

  onDeleteClicked(event: any) {
    this.dialogService.show(
      DeleteScenarioConfirmationDialogComponent,
      {
        width: DialogWidth.Small,
        panelClass: 'dialog-95vw-width',
        data: {
          scenario: event
        }
      },
      (id: number) =>
        (this.userScenarios = this.userScenarios.filter(
          (scenario) => scenario.id !== id
        ))
    );
  }
}
