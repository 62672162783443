export type ProfilerStatusTypes =
  | 'FileUploadStarted'
  | 'FileUploadCompleted'
  | 'FileUploadFailed'
  | 'FileValidationStarted'
  | 'FileValidationFailed'
  | 'FileValidationCompleted'
  | 'BulkCoderCopyStarted'
  | 'BulkCoderCopyFailed'
  | 'BulkCoderProcessingStarted'
  | 'BulkCoderProcessingCompleted'
  | 'BulkCoderProcessingFailed'
  | 'ProcessBulkCoderOutputStarted'
  | 'ProcessBulkCoderOutputCompleted'
  | 'ProcessBulkCoderOutputFailed'
  | 'ReportAggregateStarted'
  | 'ReportAggregateCompleted'
  | 'ReportAggregateFailed'
  | 'UpdateProfileCountsStarted'
  | 'UpdateProfileCountsCompleted'
  | 'UpdateProfileCountsFailed';

export type ProfilerProgressStepsState =
  | 'NotStarted'
  | 'Processing'
  | 'Completed'
  | 'Error';

export const sendingDataToTheCloud = $localize`:@@sendingDataToTheCloud:Sending data to the cloud`;
export const preparingData = $localize`:@@preparingData:Preparing data`;
export const profilingData = $localize`:@@profilingData:Profiling data`;
export const savingProfiledData = $localize`:@@savingProfiledData:Saving Profiled data`;

export function buildProfilerProgressSteps(currentStatus: ProfilerStatusTypes) {
  const progressStates = {
    FileUploadStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Processing'
      },
      {
        name: preparingData,
        state: 'NotStarted'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    FileUploadCompleted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Processing'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    FileUploadFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Error'
      },
      {
        name: preparingData,
        state: 'NotStarted'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    FileValidationStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Processing'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    FileValidationFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Error'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    FileValidationCompleted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Processing'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    BulkCoderCopyStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Processing'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    BulkCoderCopyFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Error'
      },
      {
        name: profilingData,
        state: 'NotStarted'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    BulkCoderProcessingStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Processing'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    BulkCoderProcessingCompleted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Processing'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    BulkCoderProcessingFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Error'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    ProcessBulkCoderOutputStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Processing'
      },
      {
        name: savingProfiledData,
        state: 'NotStarted'
      }
    ],
    ProcessBulkCoderOutputCompleted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Processing'
      }
    ],
    ProcessBulkCoderOutputFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Error'
      }
    ],
    UpdateProfileCountsStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Processing'
      }
    ],
    UpdateProfileCountsCompleted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Processing'
      }
    ],
    UpdateProfileCountsFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Error'
      }
    ],
    ReportAggregateStarted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Processing'
      }
    ],
    ReportAggregateCompleted: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Completed'
      }
    ],
    ReportAggregateFailed: () => [
      {
        name: sendingDataToTheCloud,
        state: 'Completed'
      },
      {
        name: preparingData,
        state: 'Completed'
      },
      {
        name: profilingData,
        state: 'Completed'
      },
      {
        name: savingProfiledData,
        state: 'Error'
      }
    ]
  };

  return progressStates[currentStatus]();
}
