<div class="main-container">
  <div>
    <button
      mat-flat-button
      type="button"
      class="secondary-button back-button"
      (click)="onBack()"
    >
      <mat-icon class="chevron-back">chevron_left</mat-icon>
      <span i18n="@@backButton">Back</span>
    </button>
  </div>
  <div class="workspace-container">
    <atlas-release-notes-content></atlas-release-notes-content>
  </div>
</div>
