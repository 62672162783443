<dx-data-grid
  style="padding-top: 10px"
  class="standard-grid"
  [dataSource]="dataSource"
  keyExpr="email"
  atlasDxDataGridDefaultOptions
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customLoad]="loadState"
    [customSave]="saveState"
  >
  </dxo-state-storing>

  <dxo-load-panel [enabled]="false"> </dxo-load-panel>
  <dxi-column
    caption="Name"
    name="fullName"
    [calculateCellValue]="fullName"
    [allowFiltering]="true"
    i18n-caption="@@nameColumnCaption"
  ></dxi-column>
  <dxi-column dataField="email"></dxi-column>
  <dxi-column
    dataField="loginCount"
    caption="Total Logins"
    [alignment]="'left'"
    i18n-caption="@@totalLoginsColumnCaption"
  >
  </dxi-column>
  <dxi-column
    dataField="loginDate"
    caption="Last Login Date"
    [allowFiltering]="true"
    dataType="date"
    [format]="'dd MMMM yy'"
    i18n-caption="@@lastLoginDateColumnCaption"
  ></dxi-column>

  <div>
    <atlas-dx-grid-header
      gridTitle="All Users"
      i18n-gridTitle="@@allUsersGridTitle"
    ></atlas-dx-grid-header>
  </div>
  <div *dxTemplate="let data of 'parentTemplate'">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</dx-data-grid>
