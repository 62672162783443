<div
  class="password-validation-container"
  [class.empty-password-validation-container]="passwordNotTouched"
  [class.wrong-password-validation-container]="isPasswordValidationWrong()"
  [class.passed-password-validation-container]="isPasswordValidationPassed()"
>
  <ul>
    <li
      i18n="@@minLengthRequirement"
      [class.validation-passed]="minLengthRequirementAccomplished"
    >
      Minimum password length: 12 characters.
    </li>
    <li
      i18n="@@numberRequirement"
      [class.validation-passed]="numberRequirementAccomplished"
    >
      Contains at least 1 number.
    </li>
    <li [class.validation-passed]="specialCharRequirementAccomplished">
      <span i18n="@@specialCharRequirement"
        >Contains at least 1 special character</span
      >
      (! &#64; # $ % ^ & * + = ^ . [ ] &#123; &#125; ( ) ? - " ! / \ , &gt; &lt;
      ' : ; | _ ~ `).
    </li>
    <li
      i18n="@@upperCaseLetterRequirement"
      [class.validation-passed]="upperCaseLetterRequirementAccomplished"
    >
      Contains at least 1 uppercase letter.
    </li>
    <li
      i18n="@@lowerCaseLetterRequirement"
      [class.validation-passed]="lowerCaseLetterRequirementAccomplished"
    >
      Contains at least 1 lowercase letter.
    </li>
  </ul>
</div>
