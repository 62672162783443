import { FeatureAction } from 'src/app/shared/types/feature-action.types';

export const locatorFeatureActions: Record<string, FeatureAction> = {
  selectedLibrary: {
    actionType: 'SelectedLibrary',
    updatables: ['libraryId']
  },
  selectedLocation: {
    actionType: 'SelectedLocation',
    updatables: ['locationId']
  },
  selectedShape: {
    actionType: 'SelectedShape',
    updatables: ['shapeId']
  }
};
