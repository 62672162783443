import { Layer } from '../models/layer';
import { GeoJsonLayer } from '@deck.gl/layers';
import { getMapLayerDefaultProperties } from '../helpers/map-layer-helper';
import { MapService } from '../services/map.service';
import { styleBoundaryGeometryLines } from '../helpers/boundary-geometry-style-helper';
import { getFromLocalStorage } from '../utils/local-storage-utils';

export class AtlasLineLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string, mapService: MapService) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties('Line', jwtToken);

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    /*
      // TODO work out the line layer focus
      layerProperties.onHover = (info: any) => {
      styleBoundaryGeometryLines(info, mapService);
    };*/

    // properties for highlight the geometry that is hovered border gets thicker and turns black
    layerProperties.orginalLineColor = layerProperties.getLineColor;
    layerProperties.highlightColor = [129, 179, 255, 0]; // transparent

    return new GeoJsonLayer(layerProperties);
  }
}
