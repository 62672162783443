<div
  class="gazetteer-button"
  [class.button-enabled]="!(isDropPinButtonDisabled$ | async)"
  [matTooltip]="addNewMapPinTooltip()"
  [matTooltipShowDelay]="1000"
>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="Main menu"
    (click)="onManuallyAddPinToMapClick()"
    [class.manual-pin-drop-active]="pinDropActive"
    [disabled]="isDropPinButtonDisabled$ | async"
  >
    <mat-icon>add_location</mat-icon>
  </button>

  <div class="button-underline"></div>
</div>
