import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';

import { LocalStorageService } from 'src/app/core/services/local-storage.service';

import { getLocatorLibraryId } from '../selectors';
import * as fromLocatorStore from 'src/app/locator-store';
import { Store } from '@ngrx/store';
import { State } from '../reducers';

import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

import { DialogService } from 'src/app/core/services/dialog.service';

import {
  clearLocatorLibraryStateOnLogoff,
  createLibraryDialogSaveClicked,
  createLibrarySucceeded,
  generateDefaultFreeFormPolygonSucceded,
  generateTempFreeFormLocatorLibraryDataPointAttempt,
  generateTempLocatorLibraryDataPointAttempt,
  generateTempLocatorLibraryDataPointErrorOccurred,
  generateTempLocatorLibraryDataPointSucceded,
  saveTempFreeFormLocatorLibraryDataPointAttempt,
  getLocatorLibrarySucceeded,
  updateLibraryDialogSaveClicked,
  updateLibrarySucceeded,
  swapLibraryAttempt,
  swapLibrarySucceeded,
  deleteLibraryAttempt,
  deleteLibrarySucceeded,
  getLocatorLoFiReportAttempt,
  updateLocationNameSucceeded,
  updateLocationNameDialogSaveClicked,
  ReferenceLibrariesErrorOccurred,
  getReferenceLibrariesSucceeded,
  importReferenceLibraryDataClicked,
  importReferenceLibraryDataSucceded,
  importReferenceLibraryErrorOccurred
} from '../actions/locator-library.actions';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { LocatorService } from 'src/app/locator/services/locator.service';

import {
  reverseLocationSucceded,
  searchTextClearAttempt,
  selectLocationSucceded
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import { locatorEditPolygonIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { PolygonSelectionService } from 'src/app/shared/atlas-mapping/services/polygon-selection-service';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';
import { getSelectedPoint } from 'src/app/shared/atlas-gazetteer/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { showSelectedLocatorDataShapeAfterAddEdit } from 'src/app/locator/helpers/locator-shape.helper';
import {
  addingEditingPolygonAttempt,
  addingEditingPolygonComplete,
  addingNewPolygonComplete
} from 'src/app/core/store/actions/locator-ui.actions';
import { LocatorReferenceLibrary } from 'src/app/locator/models/locator-reference-library';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { getMultiCatchmentMode } from 'src/app/core/store';

@Injectable()
export class LocatorLibraryEffects {
  constructor(
    private actions$: Actions,
    private locatorDataService: LocatorService,
    private localStorageService: LocalStorageService,
    private store$: Store<State>,
    private mapService: MapService,
    private polygonService: PolygonSelectionService,
    private snackbarService: SnackbarService,
    public dialogService: DialogService,
    private appFeatureStateService: AppFeatureStateService,
    private pinDropAndSelectionService: PinDropAndSelectionService
  ) {}

  getLocatorLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenLocatorFeatureAllowed(),
      switchMap(() => {
        this.setDefaultLocatorShape();
        const id = this.getLocatorLibraryId();
        if (id > 0) {
          return this.locatorDataService.getLocatorLibraryById(id).pipe(
            map((library: LocatorLibrary) => {
              this.localStorageService.set('locator-library-id', library.id);
              this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
                library.id
              );
              return getLocatorLibrarySucceeded({ library });
            })
          );
        } else {
          return this.locatorDataService.getLastedEditedLocatorLibrary().pipe(
            map((library: LocatorLibrary) => {
              this.localStorageService.set('locator-library-id', library.id);
              this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
                library.id
              );
              return getLocatorLibrarySucceeded({ library });
            })
          );
        }
      })
    )
  );

  createLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLibraryDialogSaveClicked),
      switchMap(({ libraryRequest }) =>
        this.locatorDataService.createLibrary(libraryRequest).pipe(
          map((library: LocatorLibrary) => {
            this.localStorageService.set('locator-library-id', library.id);

            return createLibrarySucceeded({
              library
            });
          }),
          catchError((error) =>
            of(
              fromLocatorStore.LocatorLibraryActions.libraryErrorOccurred({
                errorOn: $localize`:@@errorLibrary:Error library`,
                error: $localize`:@@libraryNotCreated:Library ${libraryRequest.name} has not been created.`
              })
            )
          )
        )
      )
    )
  );

  updateLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLibraryDialogSaveClicked),
      switchMap(({ id, libraryRequest }) =>
        this.locatorDataService.updateLibrary(id, libraryRequest).pipe(
          map((library: LocatorLibrary) => {
            return updateLibrarySucceeded({
              library
            });
          }),
          catchError((error) =>
            of(
              fromLocatorStore.LocatorLibraryActions.libraryErrorOccurred({
                errorOn: $localize`:@@errorLibrary:Error library`,
                error: $localize`:@@libraryNotUpdated:Library ${libraryRequest.name} has not been updated.`
              })
            )
          )
        )
      )
    )
  );

  searchTextClearAttemptLocationUndefinedOnReverseLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reverseLocationSucceded),
      this.appFeatureStateService.allowWhenLocatorFeatureSelected(),
      filter(({ requestFrom }) => requestFrom === null),
      filter(
        ({ location }) =>
          location.latitude == undefined || location.longitude == undefined
      ),
      map(() => {
        return searchTextClearAttempt();
      })
    )
  );

  createLocationDataPointAfterReverseLocationForFreeForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reverseLocationSucceded),
      withLatestFrom(this.store$.select(getMultiCatchmentMode)),
      this.appFeatureStateService.allowWhenLocatorFeatureSelected(),
      filter(([{ requestFrom }]) => requestFrom === null),
      filter(
        ([_, isMultiCatchmentMode]) =>
          !isMultiCatchmentMode && this.isFreeFormShape()
      ),
      filter(
        ([{ location }]) =>
          location.latitude !== undefined || location.longitude !== undefined
      ),
      switchMap(([{ location }]) => {
        return [
          generateTempFreeFormLocatorLibraryDataPointAttempt({
            location
          }),
          addingEditingPolygonAttempt()
        ];
      })
    )
  );

  createLocationDataPointAfterReverseLocationOtherShapes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reverseLocationSucceded),
      withLatestFrom(this.store$.select(getMultiCatchmentMode)),
      this.appFeatureStateService.allowWhenLocatorFeatureSelected(),
      filter(([{ requestFrom }]) => requestFrom === null),
      filter(
        ([_, isMultiCatchmentMode]) =>
          !this.isFreeFormShape() || isMultiCatchmentMode
      ),
      filter(
        ([{ location }]) =>
          location.latitude !== undefined || location.longitude !== undefined
      ),
      map(([{ location }]) => {
        return generateTempLocatorLibraryDataPointAttempt({ location });
      })
    )
  );

  searchTextClearAttemptWhenLocationUndefinedSelectLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectLocationSucceded),
        this.appFeatureStateService.allowWhenLocatorFeatureSelected(),
        filter(
          ({ location }) =>
            location.latitude == undefined || location.longitude == undefined
        ),
        map(() => {
          return searchTextClearAttempt();
        })
      )
  );

  createLocationDataPointAfterSelectLocationForFreeForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLocationSucceded),
      withLatestFrom(this.store$.select(getMultiCatchmentMode)),
      this.appFeatureStateService.allowWhenLocatorFeatureSelected(),
      filter(
        ([_, isMultiCatchmentMode]) =>
          !isMultiCatchmentMode && this.isFreeFormShape()
      ),
      filter(
        ([{ location }]) =>
          location.latitude !== undefined || location.longitude !== undefined
      ),
      switchMap(([{ location }]) => {
        return [
          generateTempFreeFormLocatorLibraryDataPointAttempt({
            location
          }),
          addingEditingPolygonAttempt()
        ];
      })
    )
  );

  createLocationDataPointAfterSelectLocationOtherShapes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLocationSucceded),
      withLatestFrom(this.store$.select(getMultiCatchmentMode)),
      this.appFeatureStateService.allowWhenLocatorFeatureSelected(),
      filter(
        ([_, isMultiCatchmentMode]) =>
          !this.isFreeFormShape() || isMultiCatchmentMode
      ),
      filter(
        ([{ location }]) =>
          location.latitude !== undefined || location.longitude !== undefined
      ),
      map(([{ location }]) => {
        return generateTempLocatorLibraryDataPointAttempt({ location });
      })
    )
  );

  generateTempFreeFormLocatorLibraryDataAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateTempFreeFormLocatorLibraryDataPointAttempt),
      switchMap(({ location }) => {
        this.createDefaultPolygon(location);
        return of(generateDefaultFreeFormPolygonSucceded());
      })
    )
  );

  generateTempLocatorLibraryDataAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateTempLocatorLibraryDataPointAttempt),
      withLatestFrom(
        this.store$.select(getLocatorLibraryId),
        this.store$.select(getSelectedPoint)
      ),
      switchMap(([_, libraryId, selectedPoint]) => {
        return this.locatorDataService
          .createLocatorLibraryTemporaryPoint(
            libraryId,
            _.location,
            this.getFirstLineOfAddress(selectedPoint.address),
            '',
            '' //can be empty string for other shapes
          )
          .pipe(
            map((locationData: LocatorLibraryData) => {
              return generateTempLocatorLibraryDataPointSucceded({
                locationData
              });
            }),
            catchError((error) =>
              of(
                generateTempLocatorLibraryDataPointErrorOccurred({
                  errorOn: '',
                  error
                })
              )
            )
          );
      })
    )
  );

  generateTempLocatorLibraryDataPointErrorOccurred$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateTempLocatorLibraryDataPointErrorOccurred),
      map(() => searchTextClearAttempt())
    )
  );

  saveTempFreeFormLocatorLibraryDataPointAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveTempFreeFormLocatorLibraryDataPointAttempt),
      withLatestFrom(
        this.store$.select(getLocatorLibraryId),
        this.store$.select(getSelectedPoint)
      ),
      switchMap(([_, libraryId, selectedPoint]) => {
        return this.locatorDataService
          .createLocatorLibraryTemporaryPoint(
            libraryId,
            _.location,
            this.getFirstLineOfAddress(selectedPoint.address),
            _.geoJson,
            _.description
          )
          .pipe(
            switchMap((locationData: LocatorLibraryData) => {
              return [
                generateTempLocatorLibraryDataPointSucceded({
                  locationData
                }),
                addingEditingPolygonComplete()
              ];
            }),
            catchError((error) =>
              of(
                generateTempLocatorLibraryDataPointErrorOccurred({
                  errorOn: '',
                  error
                })
              )
            )
          );
      })
    )
  );

  generateTempLocatorLibraryDataPointSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateTempLocatorLibraryDataPointSucceded),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      switchMap(([{ locationData }, libraryId]) => {
        let shape = locationData.shapes[locationData.shapes.length - 1];
        showSelectedLocatorDataShapeAfterAddEdit(
          this.mapService,
          libraryId,
          shape
        );

        this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
          libraryId
        );

        return [
          getLocatorLoFiReportAttempt({
            libraryId,
            locationDataId: shape.libraryDataId,
            shapeId: shape.id
          }),
          addingNewPolygonComplete()
        ];
      })
    )
  );

  clearLocatorLibraryStateOnLogoff$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearLocatorLibraryStateOnLogoff),
        map(() => {
          this.mapService.resetMapService();
          this.snackbarService.closeSnackBar();
        })
      ),
    { dispatch: false }
  );

  swapLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(swapLibraryAttempt),
      switchMap(({ id }) => {
        return this.locatorDataService.getLocatorLibraryById(id).pipe(
          map((library: LocatorLibrary) => {
            this.localStorageService.set('locator-library-id', library.id);

            return swapLibrarySucceeded({ library });
          }),
          catchError((error) =>
            of(
              fromLocatorStore.LocatorLibraryActions.libraryErrorOccurred({
                errorOn: $localize`:@@errorLibrary:Error library`,
                error: $localize`:@@swapLibraryFailed:Swap Library has failed.`
              })
            )
          )
        );
      })
    )
  );

  deleteLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibraryAttempt),
      switchMap(({ id }) => {
        return this.locatorDataService.deleteLocatorLibrary(id).pipe(
          map(() => deleteLibrarySucceeded({ id })),
          catchError((error) =>
            of(
              fromLocatorStore.LocatorLibraryActions.libraryErrorOccurred({
                errorOn: $localize`:@@errorLibrary:Error library`,
                error: $localize`:@@deleteLibraryFailed:Delete Library has failed.`
              })
            )
          )
        );
      })
    )
  );

  updateLocationName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocationNameDialogSaveClicked),
      switchMap(({ libraryId, libraryDataId, locationRequest }) =>
        this.locatorDataService
          .updateLocationName(libraryId, libraryDataId, locationRequest)
          .pipe(
            map((locationData: LocatorLibraryData) => {
              this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
                locationData.libraryId
              );
              return updateLocationNameSucceeded({
                locationData
              });
            }),
            catchError((error) =>
              of(
                fromLocatorStore.LocatorLibraryActions.LocationNameErrorOccurred(
                  {
                    errorOn: 'Error location',
                    error: `Location ${locationRequest.name} has not been updated.`
                  }
                )
              )
            )
          )
      )
    )
  );

  getReferenceLibraries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenLocatorFeatureAllowed(),
      switchMap(() =>
        this.locatorDataService.getReferenceLibraries().pipe(
          map((libraries: LocatorReferenceLibrary[]) => {
            return getReferenceLibrariesSucceeded({ libraries });
          }),
          catchError((error) =>
            of(
              ReferenceLibrariesErrorOccurred({
                errorOn: '',
                error
              })
            )
          )
        )
      )
    )
  );

  importReferenceLibraryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importReferenceLibraryDataClicked),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      switchMap(([{ referenceLibraryId }, libraryId]) =>
        this.locatorDataService
          .canReferenceLibraryBeImported(referenceLibraryId, libraryId)
          .pipe(
            switchMap(({ canBeImported }) => {
              if (canBeImported) {
                return this.locatorDataService
                  .importReferenceLibrary(libraryId, referenceLibraryId)
                  .pipe(map(() => importReferenceLibraryDataSucceded()));
              } else {
                return of(
                  importReferenceLibraryErrorOccurred({
                    errorOn: '',
                    error: $localize`:@@importReferenceLibraryError:This data cannot be added as it would exceed the number of locations allowed in this library.`
                  })
                );
              }
            }),
            catchError((error) =>
              of(
                importReferenceLibraryErrorOccurred({
                  errorOn: '',
                  error
                })
              )
            )
          )
      )
    )
  );

  refreshLocatorLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importReferenceLibraryDataSucceded),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      switchMap(([_, libraryId]) => {
        return this.locatorDataService.getLocatorLibraryById(libraryId).pipe(
          map((library: LocatorLibrary) => {
            this.localStorageService.set('locator-library-id', library.id);
            this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
              library.id
            );
            return getLocatorLibrarySucceeded({ library });
          })
        );
      })
    )
  );

  private isFreeFormShape(): boolean {
    return (
      this.localStorageService.get('locator-default-shape') ===
        LocatorShapeTypes.FreeForm ||
      this.localStorageService.get('locator-default-shape') === 'FreeForm' // Backwards compatibility before prod_v1.3.2 version
    );
  }

  private createDefaultPolygon(location: Point) {
    var layerSettings =
      this.polygonService.buildDefaultFreeFormPolygonFromPoint(location);

    var layer = this.mapService.getLayer(locatorEditPolygonIdentifier);

    if (layer) {
      this.mapService.updateLayer(locatorEditPolygonIdentifier, layerSettings);
      this.mapService.centreAndZoomExtentMap(layerSettings.data);
    }
  }

  private setDefaultLocatorShape() {
    if (
      this.localStorageService.get('locator-default-shape') == null ||
      this.localStorageService.get('locator-default-shape') == ''
    ) {
      this.localStorageService.set('locator-default-shape', 'Circle');
    }
  }

  private getFirstLineOfAddress(address: string) {
    var addressArray = address.split(',');
    return address.length == 0 ? address : addressArray[0];
  }
  private getLocatorLibraryId() {
    var id = this.localStorageService.get('locator-library-id');
    return id ? id : 0;
  }
}
