<form [formGroup]="smReportingForm" autocomplete="off">
  <div class="form-controls-padding">
    <div style="margin-bottom: 10px">
      <span i18n="@@generatingReportMessage">
        You are currently generating a report for “{{
          selectedSupplyPoint?.name
        }}”
      </span>
    </div>
    <div>
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label i18n="@@reportNamesLabel">Report names</mat-label>
        <input
          matInput
          type="text"
          formControlName="filter"
          placeholder="Search reports"
          (keyup)="onFilterChanged($event)"
          i18n-placeholder="@@searchReportsPlaceholder"
        />
        <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div>
      <div class="reports-label" i18n="@@reportsLabel">Reports</div>
      <div class="scrollable-list">
        <mat-selection-list
          #pbiReports
          [multiple]="false"
          [hideSingleSelectionIndicator]="true"
          (selectionChange)="onSelectionChange($event)"
        >
          <mat-list-option
            *ngFor="let smReport of dataSource"
            [value]="smReport"
          >
            <div class="report-item-content">
              <span class="report-name">{{ smReport.name }}</span>
              <div
                class="report-icon pbi-icon-background"
                *ngIf="smReport.reportType === reportTypes.PowerBi"
                i18n="@@powerBiIcon"
              >
                Power BI
              </div>
              <div
                class="report-icon excel-icon-background"
                *ngIf="smReport.reportType === reportTypes.Excel"
                i18n="@@excelIcon"
              >
                Excel
              </div>
            </div>
            <div>
              <span class="report-description">{{ smReport.description }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</form>
