<div class="button-container">
  <button
    mat-flat-button
    class="export-button secondary-button"
    (click)="onExportProfile()"
    i18n="@@exportRecordsButton"
  >
    Export records
  </button>
</div>
