export function translate(
  translationValue: string,
  dictionary: { [key: string]: string }
): string {
  // Loop through the dictionary object and replace the key with the value
  Object.entries(dictionary).forEach(([key, value]) => {
    if (translationValue.includes(key)) {
      translationValue = translationValue.replace(key, value);
    }
  });

  return translationValue;
}
