<div
  class="gazetteer-button"
  [class.button-enabled]="!(isAddNewLocationDisabled$ | async)"
  matTooltip="Add new location"
  [matTooltipShowDelay]="1000"
  i18n-matTooltip="@@addNewLocationTooltip"
>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="Main menu"
    (click)="onOpenDefineNewLocationDialogClick()"
    [disabled]="isAddNewLocationDisabled$ | async"
  >
    <span class="material-symbols-outlined"> captive_portal </span>
  </button>

  <div class="button-underline"></div>
</div>
