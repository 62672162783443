export class LocatorLofiQueryResult {
  name: string;
  shapeName: string;
  isStats: boolean;
  isGraph: boolean;
  graphConfiguration: string;
  statsData: LocatorLofiData[] = [];
  graphData: LocatorLofiGraphData[] = [];
  pageTitle: string;
}

export class LocatorLofiData {
  id: number;
  name: string;
  value: string;
  iconName: string;
}

export class LocatorLofiGraphData {
  id: number;
  name: string;
  value: number;
}
