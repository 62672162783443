import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';

import { DataUpdateService } from '../../services/data-update.service';
import {
  updateDataUpdateAcceptedAttempt,
  updateDataUpdateAcceptedError,
  checkDataUpdateAccepted,
  showDataUpdateDialog
} from '../actions/data-update.actions';
import { DialogService } from '../../services/dialog.service';
import { DataUpdateDialogComponent } from '../../components/data-update-dialog/data-update-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { getUserTenantsInfoAttempt } from '../actions';

@Injectable()
export class DataUpdateEffects {
  constructor(
    private actions$: Actions,
    private dataUpdateService: DataUpdateService,
    private dialogService: DialogService
  ) {}

  checkDataUpdateAccepted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkDataUpdateAccepted),
      switchMap(() =>
        this.dataUpdateService.hasUserAcceptedDataUpdate().pipe(
          map((dataUpdateSeen) => {
            return dataUpdateSeen
              ? getUserTenantsInfoAttempt()
              : showDataUpdateDialog();
          })
        )
      )
    )
  );

  acknowledgeDataUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDataUpdateAcceptedAttempt),
      switchMap(() =>
        this.dataUpdateService.updateUserDataAccepted().pipe(
          map(() => {
            return getUserTenantsInfoAttempt();
          })
        )
      ),
      catchError(() =>
        of(
          updateDataUpdateAcceptedError({
            errorOn: $localize`:@@dataUpdateError:Data update`,
            error: $localize`:@@errorSavingDataAcknowledgement:Error saving your data update acknowledgement`
          })
        )
      )
    )
  );

  showDataUpdateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showDataUpdateDialog),
        map(() => {
          this.dialogService.show(DataUpdateDialogComponent, {
            width: DialogWidth.Small,
            panelClass: 'dialog-full-width-height',
            disableClose: true
          });
        })
      ),
    { dispatch: false }
  );
}
