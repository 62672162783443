<div class="verify-user-body">
  <form
    class="form-container"
    (ngSubmit)="onVerify()"
    [formGroup]="verifyCodeForm"
    autocomplete="off"
  >
    <div>
      <h2 i18n="@@userValidationTitle" class="title">
        Atlas - User validation
      </h2>
    </div>
    <hr class="line-hr" />
    <div class="verify-user-div">
      <div class="verify-user-controls">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@validationCodeLabel">Validation Code</mat-label>
          <input
            matInput
            type="text"
            formControlName="verificationCode"
            placeholder="Validation Code"
            i18n-placeholder="@@validationCodePlaceholder"
          />
          <mat-error
            i18n="@@emptyVerificationCodeError"
            *ngIf="emptyVerificationCode()"
          >
            Please enter your validation code
          </mat-error>
        </mat-form-field>
      </div>
      <div class="verify-user-btn-div">
        <button
          i18n="@@verifyCodeButton"
          mat-raised-button
          color="primary"
          type="submit"
        >
          Verify Code
        </button>
      </div>
    </div>
  </form>
  <br />
</div>
