<div class="auth-container">
  <div class="auth-body">
    <form
      class="form-container"
      (ngSubmit)="onForgotPassword()"
      [formGroup]="forgotPasswordForm"
      autocomplete="off"
    >
      <div class="centered">
        <img
          src="../../../../assets/logos/insite-logo.png"
          class="insite-everywhere-logo"
        />
      </div>
      <div class="centered column-direction">
        <span i18n="@@resetPasswordTitle" class="auth-title"
          >Reset Password</span
        >
        <span
          i18n="@@resetPasswordDescription"
          class="reset-password-description body-large"
        >
          Please provide the email address that is registered to your account
          and we will email you a verification code.
        </span>
      </div>
      <div>
        <div class="auth-input-fields">
          <div class="auth-control">
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label i18n="@@emailLabel">Email</mat-label>
              <input
                matInput
                type="email"
                formControlName="email"
                placeholder="Email"
                i18n-placeholder="@@emailPlaceholder"
              />
              <mat-error i18n="@@invalidEmailError" *ngIf="invalidEmail()">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="auth-control auth-btn-div">
          <button
            i18n="@@continueButton"
            mat-raised-button
            class="auth-btn"
            color="primary"
            type="submit"
            [disabled]="disabledButton()"
          >
            Continue
          </button>
        </div>
        <div class="back-to-login centered">
          <span i18n="@@goBackToLogin">Go back to login?</span>
          <span i18n="@@clickHere" class="link" (click)="onGoBackToLogin()"
            >Click here</span
          >
        </div>
      </div>
    </form>
  </div>
  <div class="auth-footer">
    <img src="../../../../assets/logos/CACI-logo 1.svg" class="caci-logo" />
  </div>
</div>
