import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { SnackbarService } from 'src/app/core/services/snackbar.service';

import {
  updateDataUpdateAcceptedError,
  getUserTenantsInfoFailed,
  updateGeneralUserSettingsError,
  updateGeneralUserSettingsSucceeded,
  updateLocatorUserSettingsError,
  updateLocatorUserSettingsSucceeded,
  updateNetworkPlanningUserSettingSucceeded,
  updateUserTenantFailed,
  updateCatchmentReportingUserSettingsSucceeded,
  updateNetworkPlanningUserSettingError,
  updateCatchmentReportingUserSettingsError
} from '../actions';
import { Action } from '@ngrx/store';
import { ActionCreatorWithProps } from 'src/app/shared/actions/action-creator-with-props';
import { UserSettings } from '../../models/user-settings';
import { mapScreenShotNotification } from '../actions/map.actions';

@Injectable()
export class SnackbarEffects {
  constructor(private actions$: Actions, private snackbar: SnackbarService) {}

  mapScreenShotProgressSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mapScreenShotNotification),
        tap(({ message }) => {
          this.snackbar.show(this.getSnackbarText(`${message}`));
        })
      ),
    { dispatch: false }
  );

  updateLocatorUserSettingsSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [updateLocatorUserSettingsSucceeded],
    $localize`:@@catchmentReportingSettingsSaved:Catchment reporting settings saved.`
  );

  updateCatchmentReportingUserSettingsSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [updateCatchmentReportingUserSettingsSucceeded],
    $localize`:@@catchmentReportingSettingsSaved:Catchment reporting settings saved.`
  );

  updateNetworkPlanningUserSettingsSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [updateNetworkPlanningUserSettingSucceeded],
    $localize`:@@networkPlanningSettingsSaved:Network planning settings saved.`
  );

  updateGeneralUserSettingsSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [updateGeneralUserSettingsSucceeded],
    $localize`:@@generalUserSettingsSaved:General user settings saved.`
  );

  snackbarOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          updateUserTenantFailed,
          getUserTenantsInfoFailed,
          updateLocatorUserSettingsError,
          updateGeneralUserSettingsError,
          updateNetworkPlanningUserSettingError,
          updateCatchmentReportingUserSettingsError,
          updateDataUpdateAcceptedError
        ),
        tap(({ errorOn, error: message }) => {
          this.snackbar.error(errorOn, message);
        })
      ),
    { dispatch: false }
  );

  private createSnackbarEffect(
    actions$: Actions,
    initiatedActions:
      | ActionCreatorWithProps[]
      | ActionCreatorWithProps<{
          message: string;
        }>[]
      | ActionCreatorWithProps<{
          userSettings: UserSettings;
        }>[],

    actionString: string,
    headerActionString?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(() => this.snackbar.show(actionString, headerActionString))
        ),

      { dispatch: false }
    );
  }

  private getSnackbarText(
    action: string,
    objectName?: string,
    objectPosition?: string
  ) {
    if (objectName) {
      if (objectPosition) {
        switch (objectPosition) {
          case 'prefix':
            return objectName ? `${objectName} ${action}` : `${action}`;

          case 'suffix':
            return objectName ? `${action} ${objectName}` : `${action}`;

          default:
            return objectName ? `${objectName} ${action}` : `${action}`;
        }
      }
    }

    return `${action}`;
  }
}
