import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function formValueContainsSpecialCharValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const specialCharsRegex = /[!@#$%^&*+\=\^.\[\]{}()?"!\/\\,><':;|_~`-]/;

    return specialCharsRegex.test(control.value)
      ? null
      : { NoContainSpecialChar: true };
  };
}
