import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { Scenario } from 'src/app/core/models/scenario';

@Component({
  selector: 'atlas-delete-scenario-confirmation-dialog',
  templateUrl: './delete-scenario-confirmation-dialog.component.html',
  styleUrls: ['./delete-scenario-confirmation-dialog.component.less']
})
export class DeleteScenarioConfirmationDialogComponent {
  get headerText() {
    return $localize`:@@deleteScenarioHeader:Delete scenario`;
  }

  constructor(
    private store$: Store<fromSmStore.State>,
    private dialogRef: MatDialogRef<DeleteScenarioConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scenario: Scenario;
    }
  ) {}

  onDeleteClicked() {
    this.store$.dispatch(
      fromSmStore.ScenarioActions.deleteScenarioAttempt({
        id: this.data.scenario.id
      })
    );
    this.dialogRef.close({
      action: DialogResult.Affirmative,
      data: this.data.scenario.id
    });
  }
}
