import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromLocatorStore from 'src/app/locator-store';
import {
  locationFromLocationGridClicked,
  selectLocationsForBatchOperation
} from 'src/app/locator-store/actions/locator-location.actions';
import { FeatureManagementOptions } from './feature-management-options';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';

@Component({
  selector: 'atlas-feature-management-panel',
  templateUrl: './feature-management-panel.component.html',
  styleUrls: ['./feature-management-panel.component.less']
})
export class FeatureManagementComponent {
  previousLocation: LocatorLibraryData | null;

  constructor(private locatorStore$: Store<fromLocatorStore.State>) {}

  libraryId$ = this.locatorStore$.select(fromLocatorStore.getLocatorLibraryId);

  selectedLocationId$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocationId
  );

  featureManagementOption: FeatureManagementOptions = 'Layers';

  onFeatureManagementOptionSelected(option: FeatureManagementOptions) {
    this.featureManagementOption = option;
  }

  onLocationClickSelected(locations: LocatorLibraryData[]) {
    if (locations.length === 1) {
      const newLocation = locations[0];
      if (this.hasLocationChanged(newLocation)) {
        this.previousLocation = newLocation;
        this.locatorStore$.dispatch(
          locationFromLocationGridClicked({ locationData: newLocation })
        );
      }
    } else {
      this.previousLocation = null;
      const locationIds = locations.map((location) => location.id);
      this.locatorStore$.dispatch(
        selectLocationsForBatchOperation({ locationIds })
      );
    }
  }

  private hasLocationChanged(newLocation: LocatorLibraryData): boolean {
    return (
      this.previousLocation?.id !== newLocation.id ||
      this.previousLocation?.shapes !== newLocation.shapes ||
      (this.previousLocation?.shapes?.length !== newLocation.shapes.length &&
        newLocation.latitude != null &&
        newLocation.longitude != null)
    );
  }
}
