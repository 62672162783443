<div class="auth-container">
  <div class="auth-body">
    <form
      class="form-container"
      (ngSubmit)="onVerifyNewPassword()"
      [formGroup]="verifyNewPasswordForm"
      autocomplete="off"
    >
      <div class="centered">
        <img
          src="../../../../assets/logos/insite-logo.png"
          class="insite-everywhere-logo"
        />
      </div>
      <div class="centered column-direction">
        <span i18n="@@verifyPasswordTitle" class="auth-title"
          >Verify password</span
        >
        <span
          i18n="@@verifyPasswordDescription"
          class="verify-password-description body-large"
        >
          Please enter your verification code and a new password to confirm
          changes.
        </span>
        <span
          class="error-message"
          [style.display]="
            (authService.authServiceError$ | async) != '' ? 'inherit' : 'none'
          "
        >
          {{ authService.authServiceError$.value }}
        </span>
      </div>
      <div>
        <div class="auth-input-fields">
          <div class="verify-new-password-control">
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label i18n="@@verificationCodeLabel"
                >Verification code</mat-label
              >
              <input
                matInput
                type="text"
                formControlName="verificationCode"
                placeholder="Verification code"
                i18n-placeholder="@@verificationCodePlaceholder"
              />
              <mat-error
                i18n="@@emptyVerificationCodeError"
                *ngIf="emptyVerificationCode()"
              >
                Please enter your validation code
              </mat-error>
            </mat-form-field>
          </div>
          <div class="verify-new-password-control">
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label i18n="@@newPasswordLabel">New password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                placeholder="New password"
                i18n-placeholder="@@newPasswordPlaceholder"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
              <mat-error i18n="@@emptyNewPasswordError" *ngIf="emptyPassword()">
                Please enter your new password
              </mat-error>
            </mat-form-field>
          </div>
          <div
            class="verify-new-password-control"
            [style.margin-bottom]="invalidConfirmPassword() ? '10px' : '25px'"
          >
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label i18n="@@confirmNewPasswordLabel"
                >Confirm new password</mat-label
              >
              <input
                matInput
                [type]="hideConfirm ? 'password' : 'text'"
                formControlName="confirmPassword"
                placeholder="Confirm new password"
                i18n-placeholder="@@confirmNewPasswordPlaceholder"
              />
              <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
                hideConfirm ? 'visibility_off' : 'visibility'
              }}</mat-icon>
              <mat-error
                i18n="@@emptyConfirmPasswordError"
                *ngIf="showEmptyConfirmPasswordError()"
              >
                Please confirm your password
              </mat-error>
            </mat-form-field>
            <mat-error
              i18n="@@matchingPasswordError"
              class="matching-password-error"
              [style.display]="invalidConfirmPassword() ? 'inherit' : 'none'"
            >
              Please make sure your passwords match
            </mat-error>
          </div>
          <atlas-password-validation-panel
            [passwordNotTouched]="notTouchedPassword()"
            [minLengthRequirementAccomplished]="hasMinLenght()"
            [numberRequirementAccomplished]="hasNumber()"
            [specialCharRequirementAccomplished]="hasSpecialChar()"
            [upperCaseLetterRequirementAccomplished]="hasUpperCase()"
            [lowerCaseLetterRequirementAccomplished]="hasLowerCase()"
          ></atlas-password-validation-panel>
        </div>
        <div class="auth-btn-div">
          <button
            i18n="@@confirmChangesButton"
            mat-raised-button
            class="auth-btn"
            color="primary"
            type="submit"
            [disabled]="invalidForm()"
          >
            Confirm changes
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="auth-footer">
    <img src="../../../../assets/logos/CACI-logo 1.svg" class="caci-logo" />
  </div>
</div>
