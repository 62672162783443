<form [formGroup]="editLocationNameForm" autocomplete="off">
  <div class="form-controls-padding">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label i18n="@@locationNameLabel">Location name</mat-label>
      <input
        matInput
        type="text"
        placeholder="What are you working on?"
        formControlName="locationName"
        cdkFocusInitial
        i18n-placeholder="@@locationNamePlaceholder"
      />
      <mat-error *ngIf="emptyLocationName()" i18n="@@locationNameError">
        Please enter a location name
      </mat-error>
      <mat-error
        *ngIf="locationNameAlreadyExists()"
        i18n="@@locationNameExistsError"
      >
        Another location has this name, please choose another name
      </mat-error>
    </mat-form-field>
  </div>
</form>
