/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

import { Amplify } from '@aws-amplify/core';
import amplify from './aws-exports';

import { datadogRum } from '@datadog/browser-rum';

Amplify.configure(amplify);

// Configuring DevExtreme License
config({ licenseKey });

/**
 * Hammerjs must be imported for gestures
 */
import 'hammerjs';

datadogRum.init({
  applicationId: environment.ddAppId,
  clientToken: environment.ddClientToken,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.eu',
  service: environment.ddService,
  env: environment.ddEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow'
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));
