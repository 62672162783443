@if(layoutService.isDesktopView$ | async){
<div class="properties-container">
  <mat-expansion-panel
    #panel
    class="mat-app-background"
    [disabled]="
      (isPropertiesPanelDisabled$ | async) ||
      (isPropertiesPanelDisabledForNetworkPlanningReadonlySystems$ | async)
    "
    [expanded]="
      (isPropertiesPanelExpandedForCatchmentReporting$ | async) ||
      (isPropertiesPanelExpandedForNetworkPlanning$ | async) ||
      (isPropertiesPanelExpandedForProfiler$ | async)
    "
  >
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
      <mat-panel-title i18n="@@properties">Properties</mat-panel-title>
    </mat-expansion-panel-header>
    <atlas-properties-panel></atlas-properties-panel>
  </mat-expansion-panel>
  @if(panel.expanded){
  <atlas-street-view-jpeg></atlas-street-view-jpeg>
  }
  <mat-expansion-panel
    *ngIf="enableReportPanel$ | async as reportAvailable"
    class="panel-gap mat-app-background"
    [disabled]="!reportAvailable"
    [expanded]="reportAvailable"
  >
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
      <mat-panel-title i18n="@@reports">Reports</mat-panel-title>
    </mat-expansion-panel-header>
    <atlas-reports-panel></atlas-reports-panel>
  </mat-expansion-panel>
</div>
} @else{
<div class="properties-container mat-app-background">
  <atlas-expansion-panel>
    <div class="swipeViewX">
      <div class="panel-body" role="region">
        <atlas-management-mobile-view-panel></atlas-management-mobile-view-panel>
      </div>
      @if (!((isPropertiesPanelDisabled$ | async) ||
      (isPropertiesPanelDisabledForNetworkPlanningReadonlySystems$ | async))) {
      <div class="panel-body" role="region">
        <atlas-properties-panel></atlas-properties-panel>
      </div>

      <div class="panel-body" role="region">
        <atlas-reports-panel></atlas-reports-panel>
      </div>

      <div class="panel-body" role="region">
        <atlas-profile-status-screen></atlas-profile-status-screen>
      </div>
      }
    </div>
  </atlas-expansion-panel>
</div>
}
