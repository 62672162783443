<div class="name">
  <div class="name-bold">
    {{ dataSource?.fullName }}
  </div>
  <div i18n="@@yourConsumption">Your consumption</div>
</div>
<div class="display-flex usage-border">
  <div class="panel-line usage-line"></div>
  <div class="panel usage-panel-background">
    <div>
      <div class="usage-details">
        <div class="usage">
          {{ dataSource?.modelRuns ?? 0 | number : '2.' }}
        </div>
        <div class="subscription" i18n="@@modelRuns">model runs</div>
      </div>
      <div>
        <div class="usage-percentage">
          <span i18n="@@consumedText">You have consumed</span>
          {{ dataSource?.percentageOfExecutedModels ?? 0 | number : '1.2-2' }}%
          <div class="renewal" i18n="@@ofAllModelRuns">of all model runs</div>
        </div>
      </div>
    </div>
  </div>
</div>
