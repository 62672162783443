import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { Scenario } from 'src/app/core/models/scenario';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogMode } from '../../../../shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from '../../../../shared/atlas-dialog/enums/dialog-width.enum';
import { EditScenarioDialogComponent } from 'src/app/core/components/scenario-management/edit-scenario-dialog/edit-scenario-dialog.component';
import * as fromUIStore from 'src/app/core/store';

@Component({
  selector: 'atlas-edit-scenario',
  templateUrl: './edit-scenario.component.html',
  styleUrls: ['../less/scenario-management-button.component.less']
})
export class EditScenarioComponent {
  @Output() editScenarioClicked = new EventEmitter();
  @Input() disabled: boolean | null = false;
  @Input() currentScenario: Scenario;

  constructor(public dialogService: DialogService) {}

  onOpenEditScenarioDialog() {
    this.dialogService.show(EditScenarioDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: $localize`:@@scenarioEntityName:Scenario`,
        mode: DialogMode.Edit,
        scenario: this.currentScenario,
        affirmativeButtonText: $localize`:@@updateAffirmativeButtonText:Update`
      },
      disableClose: true
    });
  }
}
