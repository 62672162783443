import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromNetworkPlanningStore from 'src/app/spatial-modeller-store';
import { generateNetworkPlanningImportTemplate } from 'src/app/spatial-modeller/helpers/import-template.helper';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';

@Component({
  selector: 'atlas-sm-import-scenario-data-dialog-form',
  templateUrl: './sm-import-scenario-data-dialog-form.component.html',
  styleUrl: './sm-import-scenario-data-dialog-form.component.less'
})
export class SmImportScenarioDataDialogFormComponent {
  fileName: string;
  selectedFile: File;
  smImportScrenarioDataForm: UntypedFormGroup;

  @Input() scenarioId: number;
  @Input() tenantImportUsingGeocoding: boolean;

  @Output() private selectedFileChanged = new EventEmitter<string>();

  private supplyPointAttributeList$ = this.networkPlanningStore$.select(
    fromNetworkPlanningStore.getSpatialModellerSupplyPointAttributes
  );
  supplyPointAttributeList: SupplyPointAttribute[];

  private subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private networkPlanningStore$: Store<fromNetworkPlanningStore.State>
  ) {}

  ngOnInit() {
    const initialValue = {
      scenarioId: this.scenarioId ?? null,
      geocodingSelection: this.tenantImportUsingGeocoding ?? null
    };
    this.smImportScrenarioDataForm =
      this.createImportLocationsForm(initialValue);

    this.subscription.add(
      this.supplyPointAttributeList$.subscribe((supplyPointAttributes) => {
        this.supplyPointAttributeList = supplyPointAttributes ?? [];
      })
    );
  }

  getFilename() {
    return this.fileName || $localize`:@@fileLocationPlaceholder:File location`;
  }

  createImportLocationsForm(initialValue: {
    scenarioId: number;
    geocodingSelection: boolean;
  }) {
    var form = this.fb.group({
      id: initialValue.scenarioId,
      geocodingSelection: [initialValue.geocodingSelection, Validators.required]
    });
    if (!this.tenantImportUsingGeocoding) {
      form.controls.geocodingSelection.disable();
    }
    return form;
  }

  emptyImportFileName() {
    if (this.smImportScrenarioDataForm.controls.importFileName.value) {
      return (
        this.smImportScrenarioDataForm.controls.importFileName.value?.trim()
          .length == 0
      );
    }

    return this.smImportScrenarioDataForm.controls.importFileName.errors
      ?.required;
  }

  invalidForm(): boolean {
    return (
      !(
        this.smImportScrenarioDataForm &&
        this.smImportScrenarioDataForm.controls
      ) || !this.selectedFile
    );
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.fileName = file.name;
    this.selectedFile = file;
    this.selectedFileChanged.emit(this.fileName);
  }

  onDownloadClicked() {
    //Todo: ATLAS-2942
    //   const csvTemplateUrl = '';
    //   this.downloadFile(csvTemplateUrl, '');
    generateNetworkPlanningImportTemplate(this.supplyPointAttributeList);
  }
}
