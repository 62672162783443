import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { SmImportScenarioDataDialogFormComponent } from '../sm-import-scenario-data-dialog-form/sm-import-scenario-data-dialog-form.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SmImportStatusError } from 'src/app/spatial-modeller/models/sm-import-status-error';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import * as fromUsageStore from 'src/app/reporting/store';
import { Store } from '@ngrx/store';
import {
  hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt,
  smClearScenarioImportStatusAttempt,
  smFileImportAttempt
} from 'src/app/spatial-modeller-store/actions/sm-import.actions';
import { Subscription } from 'rxjs';
import { SmImportStatusProgressStep } from 'src/app/spatial-modeller/models/sm-status-progress-step';
import { SmImportStatusTypes } from 'src/app/spatial-modeller/helpers/spatial-modeller-status-helper';

@Component({
  selector: 'atlas-sm-import-scenario-data-dialog',
  templateUrl: './sm-import-scenario-data-dialog.component.html',
  styleUrl: './sm-import-scenario-data-dialog.component.less'
})
export class SmImportScenarioDataDialogComponent
  implements OnInit, AfterViewInit
{
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  @ViewChild('smImportScrenarioDataForm')
  smImportScrenarioDataForm: SmImportScenarioDataDialogFormComponent;
  hasGeocodingCompanyLicenseExpired: boolean;
  hasGeocodingCompanyLimitBeenExceeded: boolean;
  hasSmDeltaLimitBeenExceeded: boolean;
  geocodingSelected: boolean = false;

  importProcessStepState: SmImportStatusProgressStep[] = [
    {
      name: 'select_csv_file',
      state: 'NotStarted'
    } as SmImportStatusProgressStep,
    {
      name: 'processing_data',
      state: 'NotStarted'
    } as SmImportStatusProgressStep
  ];

  smImportProcessStepState$ = this.smStore$.select(
    fromSmStore.getSmImportDataProgressSteps
  );

  smImportProcessStatus$ = this.smStore$.select(
    fromSmStore.getSmImportDataStatus
  );

  hasSmDeltaLimitBeenExceeded$ = this.smStore$.select(
    fromSmStore.getHasSmScenarioDeltaImportLimitBeenExceeded
  );

  hasCompanyGeocodingLimitBeenExceeded$ = this.usageStore$.select(
    fromUsageStore.getHasCompanyGeocodingLimitExceeded
  );

  hasCompanyGeocodingLicenseExpired$ = this.usageStore$.select(
    fromUsageStore.getHasCompanyGeocodingLicenseExpired
  );

  smImportStatusError: SmImportStatusError = {
    status: 'SmImportDialogOpened',
    errors: []
  };

  affirmativeButtonText: string;
  affirmativeButtonDisabled: boolean = false;
  currentStage: number = 1;
  totalRecords: number = 0;
  processedRecords: number = 0;
  importedRecords: number = 0;
  importStatusType: SmImportStatusTypes;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      scenarioId: number;
      affirmativeButtonText: string;
      tenantImportUsingGeocoding: boolean;
      openAfterRefreshingApp: boolean;
    },
    private smStore$: Store<fromSmStore.State>,
    private usageStore$: Store<fromUsageStore.State>
  ) {
    this.affirmativeButtonText = data.affirmativeButtonText;
    this.smStore$.dispatch(
      hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt({
        scenarioId: this.data.scenarioId
      })
    );

    if (this.data.openAfterRefreshingApp) {
      this.addCheckScreenSubscriptions();
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.hasSmDeltaLimitBeenExceeded$.subscribe((limitExceeded) => {
        this.hasSmDeltaLimitBeenExceeded = limitExceeded;
        this.checkSmDeltaLimitBeenExceeded();
      })
    );

    if (this.data.tenantImportUsingGeocoding) {
      this.subscription.add(
        this.hasCompanyGeocodingLimitBeenExceeded$.subscribe(
          (limitExceeded) => {
            this.hasGeocodingCompanyLimitBeenExceeded = limitExceeded;
            this.checkGeocodingCompanyRestrictions();
          }
        )
      );
      this.subscription.add(
        this.hasCompanyGeocodingLicenseExpired$.subscribe((licenseExpired) => {
          this.hasGeocodingCompanyLicenseExpired = licenseExpired;
          this.checkGeocodingCompanyRestrictions();
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.geocodingSelected =
      this.smImportScrenarioDataForm.smImportScrenarioDataForm.controls.geocodingSelection.value;
    this.subscription.add(
      this.smImportScrenarioDataForm.smImportScrenarioDataForm.controls.geocodingSelection.valueChanges.subscribe(
        (geocodingSelected) => {
          this.geocodingSelected = geocodingSelected;
          this.checkSmDeltaLimitBeenExceeded();
          if (geocodingSelected) {
            this.checkGeocodingCompanyRestrictions();
          }
        }
      )
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSelectedFileChanged(newFileName: string) {
    this.smImportStatusError.errors = [];
    this.checkSmDeltaLimitBeenExceeded();
  }

  onSmImportDataClicked(
    smImportScenarioDataDialogFormComponent: SmImportScenarioDataDialogFormComponent
  ) {
    if (this.currentStage === 1) {
      this.smImportStatusError.errors = [];
      if (
        smImportScenarioDataDialogFormComponent.smImportScrenarioDataForm.valid
      ) {
        this.smStore$.dispatch(
          smFileImportAttempt({
            file: smImportScenarioDataDialogFormComponent.selectedFile,
            importUsingGeocoding: this.geocodingSelected
          })
        );
        this.addCheckScreenSubscriptions();
      }
    } else {
      this.currentStage = 3;
      this.affirmativeButtonText = $localize`:@@closeButton:Close`;
    }
  }

  getNextScreen(importProcessStepState: SmImportStatusProgressStep[]): number {
    if (
      importProcessStepState.length === 0 ||
      importProcessStepState[0].state !== 'Completed'
    ) {
      return 1;
    } else {
      return 2;
    }
  }

  isAffirmativeButtonDisabled(
    smImportScrenarioDataForm: SmImportScenarioDataDialogFormComponent
  ) {
    return (
      (!this.data.openAfterRefreshingApp &&
        smImportScrenarioDataForm?.invalidForm()) || // checking done only if the user opened the dialog.
      this.smImportStatusError.errors.length > 0 ||
      this.importProcessStepState[0]?.state === 'Processing' ||
      (this.importProcessStepState[0]?.state === 'Completed' &&
        this.importProcessStepState[1]?.state !== 'Completed')
    );
  }

  isCancelButtonDisabled() {
    return (
      this.importProcessStepState[0]?.state === 'Completed' &&
      this.importProcessStepState[1]?.state === 'Processing'
    );
  }

  checkGeocodingCompanyRestrictions() {
    // This error has priority over the others
    if (this.hasSmDeltaLimitBeenExceeded) {
      return;
    }

    if (this.data.tenantImportUsingGeocoding) {
      if (this.hasGeocodingCompanyLicenseExpired) {
        this.smImportStatusError.errors = [];
        this.smImportStatusError.errors.push({
          error: $localize`:@@geocodingLicenseExpiredError:The geocoding company license has expired. Please contact your system administrator.`
        });
      }
      if (this.hasGeocodingCompanyLimitBeenExceeded) {
        this.smImportStatusError.errors = [];
        this.smImportStatusError.errors.push({
          error: $localize`:@@geocodingLimitExceededError:The company limit of geocoded records has been reached. Please contact your system administrator.`
        });
      }
    }
  }

  checkSmDeltaLimitBeenExceeded() {
    this.smImportStatusError.errors = [];
    // This error is not shown if the scenario has reached the limit after the import process has been successfully completed.
    if (this.hasSmDeltaLimitBeenExceeded &&
        this.importProcessStepState[1]?.state !== 'Completed') {
      this.smImportStatusError.errors.push({
        error: $localize`:@@smDeltaLimitExceededError:You have reached the limit for the number of locations in this scenario.`
      });
    }
  }

  addCheckScreenSubscriptions() {
    this.subscription.add(
      this.smImportProcessStepState$.subscribe((importProcessStepState) => {
        this.importProcessStepState = importProcessStepState;
        this.currentStage = this.getNextScreen(this.importProcessStepState);
      })
    );

    this.subscription.add(
      this.smImportProcessStatus$.subscribe((smImportProcessStatus) => {
        if (smImportProcessStatus) {
          this.importStatusType = smImportProcessStatus.status;
          if (smImportProcessStatus.errorMessage) {
            this.smImportStatusError = JSON.parse(
              smImportProcessStatus?.errorMessage
            );
          }
          if (
            smImportProcessStatus.totalRecords !== null &&
            smImportProcessStatus.processedRecords !== null
          ) {
            this.totalRecords = smImportProcessStatus.totalRecords;
            this.processedRecords = smImportProcessStatus.processedRecords;
            this.importedRecords = smImportProcessStatus.importedRecords;
          }
        }
      })
    );
  }

  onSmCancelImportDataClicked() {
    if (
      this.importProcessStepState[0]?.state === 'Error' ||
      this.importProcessStepState[1]?.state === 'Error'
    ) {
      this.smStore$.dispatch(
        smClearScenarioImportStatusAttempt({
          scenarioId: this.data.scenarioId
        })
      );
    }
  }
}
