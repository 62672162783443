import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromLocatroStore from 'src/app/locator-store';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

@Component({
  selector: 'atlas-delete-library-confirmation-dialog',
  templateUrl: './delete-library-confirmation-dialog.component.html',
  styleUrls: ['./delete-library-confirmation-dialog.component.less']
})
export class DeleteLibraryConfirmationDialogComponent {
  isShared: boolean;
  get headerText() {
    return $localize`:@@deleteLibraryHeader:Delete library`;
  }

  constructor(
    private store$: Store<fromLocatroStore.State>,
    private dialogRef: MatDialogRef<DeleteLibraryConfirmationDialogComponent>,
    private locatorService: LocatorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      library: LocatorLibrary;
    }
  ) {
    this.locatorService
      .checkLibraryReportDataShared(this.data.library.id)
      .subscribe((isShared) => {
        this.isShared = isShared;
      });
  }

  onDeleteClicked() {
    this.store$.dispatch(
      fromLocatroStore.LocatorLibraryActions.deleteLibraryAttempt({
        id: this.data.library.id
      })
    );
    this.dialogRef.close({
      action: DialogResult.Affirmative,
      data: this.data.library.id
    });
  }
}
