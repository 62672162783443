<mat-error *ngIf="requiredError()">
  {{ control.displayText }} <span i18n="@@isRequired">is required</span>
</mat-error>
<mat-error *ngIf="minLengthError()">
  {{ control.displayText }} <span i18n="@@minLength">must be at least</span>
  {{ control.strMinLength }} <span i18n="@@characters">characters</span>
</mat-error>
<mat-error *ngIf="maxLengthError()">
  {{ control.displayText }} <span i18n="@@maxLength">must be at most</span>
  {{ control.strMaxLength }} <span i18n="@@characters">characters</span>
</mat-error>
<mat-error *ngIf="minError()">
  {{ control.displayText }} <span i18n="@@minLength">must be at least</span>
  {{ control.minValue }}
</mat-error>
<mat-error *ngIf="maxError()">
  {{ control.displayText }} <span i18n="@@maxLength">must be at most</span>
  {{ control.maxValue }}
</mat-error>
