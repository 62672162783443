import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsageSummary } from '../../models/usage-summary';
import { UsageService } from '../../services/usage.service';
import {
  usageErrorOccured,
  getUsageSummaryAttempt,
  getUsageSummarySucceeded,
  hasGeocodingCompanyLimitBeenExceededAttempt,
  hasGeocodingCompanyLimitBeenExceededResult,
  hasGeocodingCompanyLimitBeenExceededErrorOccurred,
  hasGeocodingLicenseExpiredAttempt,
  hasGeocodingLicenseExpiredResult,
  hasGeocodingLicenseExpiredErrorOccurred
} from '../actions/usage.actions';
@Injectable()
export class UsageEffects {
  constructor(private actions$: Actions, private usageService: UsageService) {}
  getUsageSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsageSummaryAttempt),
      switchMap(() => {
        return this.usageService.getUsageSummary().pipe(
          map((usageSummary: UsageSummary) => {
            return getUsageSummarySucceeded({ usageSummary });
          }),
          catchError((error) =>
            of(
              usageErrorOccured({
                errorOn: $localize`:@@errorGettingUsage:Error getting usage`,
                error: error
              })
            )
          )
        );
      })
    )
  );

  hasCompanyGeocodedLimitBeenExceededAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hasGeocodingCompanyLimitBeenExceededAttempt),
      switchMap(() =>
        this.usageService.hasGeocodingCompanyLimitBeenExceeded().pipe(
          map((hasExcceeded) =>
            hasGeocodingCompanyLimitBeenExceededResult({
              result: hasExcceeded.result
            })
          ),
          catchError((error) =>
            of(
              hasGeocodingCompanyLimitBeenExceededErrorOccurred({
                errorOn: $localize`:@@importLibraryData:Import library data`,
                error: error
              })
            )
          )
        )
      )
    )
  );

  hasGeocodingLicenseExpiredAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hasGeocodingLicenseExpiredAttempt),
      switchMap(() =>
        this.usageService.hasGeocodingLicenseExpired().pipe(
          map((hasExpired) =>
            hasGeocodingLicenseExpiredResult({
              result: hasExpired.result
            })
          ),
          catchError((error) =>
            of(
              hasGeocodingLicenseExpiredErrorOccurred({
                errorOn: $localize`:@@importLibraryData:Import library data`,
                error: error
              })
            )
          )
        )
      )
    )
  );
}
