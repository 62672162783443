<form [formGroup]="addEditScenarioForm" autocomplete="off">
  <div class="form-controls-padding">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label i18n="@@scenarioNameLabel">Scenario name</mat-label>
      <input
        matInput
        type="text"
        formControlName="scenarioName"
        placeholder="What are you working on?"
        i18n-placeholder="@@scenarioNamePlaceholder"
      />
      <mat-error *ngIf="emptyScenarioName()" i18n="@@emptyScenarioNameError">
        Please enter a scenario name
      </mat-error>
      <mat-error *ngIf="!uniqueScenarioName()" i18n="@@uniqueScenarioNameError">
        Another scenario has this name, please choose another
      </mat-error>
    </mat-form-field>
    <h4 i18n="@@optionalLabel">Optional</h4>
    <atlas-scenario-name-chip-list
      placeholder="Add scenarios"
      formControlName="deltaScenarios"
      chipListTitle="Changes"
      [allItems]="deltaScenarios"
      [hint]="baseHint"
      (valueChanged)="onDeltaScenariosChanged($event)"
      [allowFreeTyping]="true"
      i18n-placeholder="@@deltaScenariosPlaceholder"
      i18n-chipListTitle="@@deltaScenariosTitle"
    ></atlas-scenario-name-chip-list>
    <div style="margin-bottom: 15px"></div>
    <atlas-scenario-name-chip-list
      placeholder="Add scenarios"
      formControlName="baseScenarios"
      chipListTitle="Base"
      [allItems]="baseScenarios"
      [fixedItems]="baseFixedScenarios"
      [hint]="baseHint"
      (valueChanged)="onBaseScenariosChanged($event)"
      [allowFreeTyping]="true"
      i18n-placeholder="@@baseScenariosPlaceholder"
      i18n-chipListTitle="@@baseScenariosTitle"
    ></atlas-scenario-name-chip-list>
  </div>
</form>
