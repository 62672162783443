import {
  Component,
  AfterViewInit,
  NgZone,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import { Map as MapboxMap } from 'mapbox-gl';
import { Deck } from '@deck.gl/core';

import { MapService } from '../../services/map.service';
import { buildTooltip } from '../../helpers/map-tooltip-helper';
import { PinDropAndSelectionService } from '../../services/pin-drop-and-selection-service';
import { BaseMapLayerService } from '../../services/base-map-layer.service';
import { BaseMapLayersType } from '../../models/base-map-layer-types';
import { getBaseMapLayerValue } from '../../utils/base-map-layer-type-utils';
import { PolygonSelectionService } from '../../services/polygon-selection-service';

import * as fromAppFeatureStore from 'src/app/core/store';
import { Store } from '@ngrx/store';

import {
  ViewMode,
  ModifyMode,
  MeasureAreaMode
} from '@deck.gl-community/editable-layers';
import {
  locatorEditPolygonIdentifier,
  measureDistanceIdentifier,
  systemLayerIdentifiers
} from '../../layers/layer.constants';
import { TooltipLayouts } from 'src/app/core/enums/tooltip-layout.enum';

import { MapScreenshotRenderer } from '../../helpers/map-screenshot-helper';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  public deck: any = null;
  public geojsonData$: any = null;

  initialMapView: any;

  @ViewChild('mapboxContainer', { static: true }) mapboxContainer: ElementRef;
  @ViewChild('deckCanvas', { static: true }) deckCanvas: ElementRef;

  allowManualPinDrop: boolean;
  subscription = new Subscription();
  baseMapLayerType: BaseMapLayersType;
  mapTooltipLayout: TooltipLayouts;
  hideLegendOnMapDownload: boolean | null;

  generalUserSettings$ = this.store$.select(
    fromAppFeatureStore.getUserGeneralSettings
  );
  defaultTooltipLayout$ = this.store$.select(
    fromAppFeatureStore.getDefaultTooltipLayout
  );

  defaultHideLegendOnMapDownload$ = this.store$.select(
    fromAppFeatureStore.getHideLegendOnMapDownload
  );

  private map: MapboxMap;
  private mapSceenShotRender: MapScreenshotRenderer;
  private DOUBLE_CLICK_THRESHOLD = 300;
  private lastClickTime: number = 0;

  constructor(
    private zone: NgZone,
    private mapService: MapService,
    private polgonSelectionService: PolygonSelectionService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private baseMapLayerService: BaseMapLayerService,
    private store$: Store<fromAppFeatureStore.State>
  ) {}

  ngOnInit(): void {
    this.subscribeToBaseMapLayerType();
    this.subscribeToAllowManualPinDrop();
    this.subscribeToGetMapPosition();
    this.subscribeToPolygonData();
    this.subscribeToMapTooltipLayout();
    this.subscribeToHideLegendOnMapDownload();

    this.disableMapContextMenu();
  }

  ngAfterViewInit() {
    this.launchMap(this.initialMapView);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.mapService.resetMapService();
  }

  private disableMapContextMenu() {
    this.deckCanvas.nativeElement.addEventListener(
      'contextmenu',
      (event: any) => {
        event.preventDefault();
      }
    );
  }

  private launchMap(initialViewState: any) {
    this.zone.runOutsideAngular(() => {
      this.map = new MapboxMap({
        container: this.mapboxContainer.nativeElement,
        interactive: false,
        center: [initialViewState.longitude, initialViewState.latitude],
        zoom: initialViewState.zoom,
        attributionControl: false,
        style: getBaseMapLayerValue(this.baseMapLayerType),
        accessToken:
          'pk.eyJ1IjoiY2FjaS1pZGctZGV2b3BzIiwiYSI6ImNsb3BuaWNmMTBiMDAya2x3ZTlmaGVkY3IifQ.FrtmJ9noDRI1hr5WmhQheg'
      });

      this.mapSceenShotRender = new MapScreenshotRenderer();

      this.deck = new Deck({
        canvas: this.deckCanvas.nativeElement,
        initialViewState,
        controller: { doubleClickZoom: false },
        onViewStateChange: ({ viewState }) => {
          this.mapService.handleViewStateChange(viewState);
        },
        onAfterRender: () => {
          this.mapScreenshot(this.deck, this.hideLegendOnMapDownload!);
        },
        onBeforeRender: () => {
          if (this.deck) {
            const viewport = this.deck.getViewports()[0];
            if (viewport) {
              this.map.jumpTo({
                center: [viewport?.longitude, viewport?.latitude],
                zoom: viewport?.zoom,
                bearing: viewport?.bearing,
                pitch: viewport?.pitch
              });
            }
            this.map.setStyle(getBaseMapLayerValue(this.baseMapLayerType));
            this.redrawMapbox(map);
          }
        },
        getTooltip: (info: any) => this.tooltip(info),
        onClick: (info: any, event: any) => this.click(info, event),
        getCursor: () => 'default', // deck gl default cursor is a grab
        layerFilter: ({ layer, renderPass }) => {
          if (
            (layer as any).props?.disableTooltip &&
            renderPass === 'picking:hover'
          ) {
            return false;
          }
          return true;
        }
      });

      this.mapService.setDeckInstance(this.deck);
    });
  }

  redrawMapbox(map: any) {
    if (map.style) {
      if (map._frame) {
        map._frame.cancel();
        map._frame = null;
      }
      map._render();
    }
  }

  tooltip(pickingInfo: any) {
    if (this.allowManualPinDrop || this.mapService.isMeasureMode) {
      this.mapService.updateMouseCursor('crosshair');
      return null;
    }

    if (pickingInfo.object) {
      return this.setTooltip(pickingInfo);
    }

    this.mapService.updateMouseCursor('default');
    return null;
  }

  getNextTooltipLayerInfo(pickingInfo: any) {
    const pickableLayers = this.deck
      .pickMultipleObjects({
        x: pickingInfo.x,
        y: pickingInfo.y
      })
      ?.filter((l: any) => !isSystemLayer(l.layer));
    const toolTipEnabledLayerInfo = pickableLayers.find(
      (l: any) => !l.layer?.props.disableTooltip
    );
    return toolTipEnabledLayerInfo;
  }

  click(info: any, event: any) {
    if (this.isPolgonBeingEdited()) {
      this.handleModifyEditComplete(info, event);
    } else {
      this.allowManualPinDrop
        ? this.pinDropAndSelectionService.onManualPinDrop(info, event)
        : this.handlePinSelection(info);
    }

    this.isDoubleClick(event);
  }

  private isDoubleClick(event: any) {
    const currentTime = new Date().getTime();
    if (currentTime - this.lastClickTime < this.DOUBLE_CLICK_THRESHOLD) {
      this.handleDoubleClick(event);
    }
    this.lastClickTime = currentTime;
  }

  private handleDoubleClick(event: any) {
    if (this.isMeasureArea()) {
      this.mapService.updateLayer(measureDistanceIdentifier, {
        mode: ViewMode
      });

      this.mapService.setMeasureMapMode(false);
      this.mapService.updateMouseCursor('default');
      this.mapService.clearMeasureMapMode();
    }
  }

  private async mapScreenshot(deckgl: any, hideLegend: boolean) {
    if (deckgl.takeScreenshot) {
      // forum suggested this was needed
      this.redrawMapbox(this.map);

      if (deckgl.props.layers.length === 0) return;

      if (!this.mapSceenShotRender.areAllLayersLoaded(deckgl)) return;

      await this.mapSceenShotRender.generateScreenshot({
        map: this.map,
        deckgl,
        layers: this.mapService.getAllLayers(),
        hideLegend
      });

      let successMsg = 'Completed';

      if (deckgl.screenShotOption == 'Download') {
        successMsg = $localize`:@@mapDownloadedSuccess:The Map has been downloaded`;
      }

      if (deckgl.screenShotOption == 'Clipboard') {
        successMsg = $localize`:@@clipboadCopiedSuccess:The Map has been copied`;
      }

      deckgl.takeScreenshot = false;

      this.mapService.mapScreenShotNotification(successMsg);
    }
  }

  private setTooltip(pickingInfo: any) {
    this.mapService.updateMouseCursor('pointer');

    let layerInfo = pickingInfo;
    if (this.isTooltipDisabled(pickingInfo)) {
      // check for the next layer down that mighthave a tooltip, used in SM when the drivetime is shown on the catchment
      layerInfo = this.getNextTooltipLayerInfo(pickingInfo);
    }

    return buildTooltip(layerInfo, this.mapTooltipLayout);
  }

  private isTooltipDisabled(pickingInfo: any): boolean {
    return pickingInfo.layer && pickingInfo.layer.props.disableTooltip;
  }

  private handlePinSelection(info: any) {
    if (this.ignoreLayerClicked(info)) return;

    info.picked
      ? this.pinDropAndSelectionService.onPinSelection(info)
      : this.pinDropAndSelectionService.onPinDeselection();
  }

  private ignoreLayerClicked(info: any) {
    return info.layer && info.layer.props.ignoreClickEvent;
  }

  // nebular does not stop editing when theuser double clicks, code
  // to stop editing if clicks off the polygon
  private handleModifyEditComplete(info: any, event: any) {
    var layer = this.mapService.getLayer(locatorEditPolygonIdentifier);

    if (layer) {
      if (info?.object?.properties == undefined) {
        this.mapService.updateLayer(locatorEditPolygonIdentifier, {
          mode: ViewMode
        });

        var geoJson = JSON.stringify(layer.props.data);
        this.polgonSelectionService.saveFreeformPolygon(geoJson);
        return true;
      }
    }

    return false;
  }

  private subscribeToBaseMapLayerType() {
    this.subscription.add(
      this.baseMapLayerService.baseMapLayerType$
        .pipe(
          map((baseMapLayerType) => {
            this.baseMapLayerType = baseMapLayerType;
            if (this.map) {
              this.map.setStyle(getBaseMapLayerValue(this.baseMapLayerType));
            }
          })
        )
        .subscribe()
    );
  }

  private subscribeToAllowManualPinDrop() {
    this.subscription.add(
      // this Observable should be part of the Map store when implemented
      this.pinDropAndSelectionService.allowManualPinDrop$
        .pipe(
          map((value) => {
            this.allowManualPinDrop = value;
            value
              ? this.mapService.updateMouseCursor('crosshair')
              : this.mapService.updateMouseCursor('pointer');
          })
        )
        .subscribe()
    );
  }

  private subscribeToGetMapPosition() {
    this.subscription.add(
      this.mapService.getMapPosition$
        .pipe(
          map((position) => {
            this.initialMapView = {
              latitude: position?.latitude,
              longitude: position?.longitude,
              zoom: position?.zoom
            };
            this.mapService.zoomMap(
              {
                latitude: this.initialMapView?.latitude,
                longitude: this.initialMapView?.longitude
              },
              this.initialMapView.zoom
            );
            return;
          })
        )
        .subscribe()
    );
  }

  private subscribeToPolygonData() {
    this.subscription.add(
      this.polgonSelectionService.polygonDataDrop$
        .pipe(
          map((value: any) => {
            var layer = this.mapService.getLayer(locatorEditPolygonIdentifier);
            if (layer) {
              if (value.editType == 'addFeature') {
                this.mapService.updateLayer(locatorEditPolygonIdentifier, {
                  data: value.updatedData,
                  mode: ViewMode
                });
              } else {
                if (
                  layer.props.data != value.updatedData &&
                  !Array.isArray(value.updatedData)
                ) {
                  this.mapService.updateLayer(locatorEditPolygonIdentifier, {
                    data: value.updatedData,
                    mode: ModifyMode
                  });
                }
              }
            }
          })
        )
        .subscribe()
    );
  }

  private subscribeToMapTooltipLayout() {
    this.subscription.add(
      this.generalUserSettings$
        .pipe(combineLatestWith(this.defaultTooltipLayout$))
        .subscribe(([generalUsersettings, defaultTooltipLayout]) => {
          this.mapTooltipLayout =
            generalUsersettings?.tooltipLayout == null
              ? defaultTooltipLayout
              : generalUsersettings.tooltipLayout;
        })
    );
  }

  private subscribeToHideLegendOnMapDownload() {
    this.subscription.add(
      this.generalUserSettings$
        .pipe(combineLatestWith(this.defaultHideLegendOnMapDownload$))
        .subscribe(([generalUsersettings, defaultHideLegendOnMapDownload]) => {
          this.hideLegendOnMapDownload =
            generalUsersettings?.tooltipLayout == null
              ? defaultHideLegendOnMapDownload
              : generalUsersettings.hideLegendOnMapDownload;
        })
    );
  }

  private isPolgonBeingEdited() {
    var layer = this.mapService.getLayer(locatorEditPolygonIdentifier);
    return layer && layer.props.mode == ModifyMode;
  }

  private isMeasureArea() {
    var layer = this.mapService.getLayer(measureDistanceIdentifier);
    return layer && layer.props.mode == MeasureAreaMode;
  }
}

export function isSystemLayer(layerId: string) {
  return systemLayerIdentifiers.includes(layerId);
}
