<form [formGroup]="editProfilerLibraryForm" autocomplete="off">
  <div class="form-controls-padding">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label i18n="@@profileNameLabel">Profile name</mat-label>
      <input
        matInput
        type="text"
        placeholder="What are you working on?"
        formControlName="libraryName"
        i18n-placeholder="@@profileNamePlaceholder"
      />
      <mat-error
        *ngIf="emptyLibraryName()"
        i18n="@@emptyProfilerLibraryNameError"
      >
        Please enter a profile name
      </mat-error>
      <mat-error
        *ngIf="!uniqueLibraryName()"
        i18n="@@uniqueProfilerLibraryNameError"
      >
        Another profile has this name, please choose another name
      </mat-error>
    </mat-form-field>
  </div>
</form>
