import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  //https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
  addGlobalContextProperty(key: string, value: any): void {
    datadogRum.setGlobalContextProperty(key, value);
  }

  trackUserAction(
    actionName: string,
    extraData: Record<string, any> = {},
    durationName?: string
  ) {
    durationName
      ? this.addActionWithDuration(actionName, extraData, durationName)
      : this.addAction(actionName, extraData);
  }

  trackApiTiming(apiName: string, duration: number) {
    datadogRum.addTiming(apiName, duration);
  }

  private addAction(actionName: string, extraData: Record<string, any> = {}) {
    datadogRum.addAction(actionName, { ...extraData });
  }

  private addActionWithDuration(
    actionName: string,
    extraData: Record<string, any> = {},
    durationName: string
  ) {
    datadogRum.addAction(actionName, {
      ...extraData,
      action: {
        loading_time: datadogRum.stopDurationVital(durationName)
      }
    });
  }
}
