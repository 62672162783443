<form [formGroup]="locatorImportLibraryDataForm" autocomplete="off">
  <div class="header-padding text">
    <span i18n="@@selectCsvFileText"
      >Select a .csv file to upload a location data file.</span
    >
  </div>
  <div class="link">
    <a (click)="onDownloadClicked()" i18n="@@downloadTemplateLink"
      >Download template</a
    >
  </div>
  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <div class="file-button-padding">
      <input
        type="file"
        (change)="onFileSelected($event)"
        accept=".csv"
        class="file file-button-padding"
        #fileUpload
      />
      <button (click)="fileUpload.click()" class="file-button">
        <span i18n="@@selectFileButton">Select File</span>
      </button>
    </div>
    <mat-label i18n="@@fileLocationLabel">File location</mat-label>
    <input matInput type="text" [value]="getFilename()" readonly />
  </mat-form-field>
  <div class="radio-buttons-container">
    <div>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="geocodingSelection"
      >
        <mat-radio-button
          [value]="false"
          class="radio-button-margin-left radio-button-margin-right"
          ><mat-label i18n="@@latitudeLongitudeLabel"
            >Latitude and Longitude</mat-label
          >
        </mat-radio-button>

        <mat-radio-button [value]="true"
          ><mat-label i18n="@@geocodeLabel"
            >Geocode</mat-label
          ></mat-radio-button
        >
      </mat-radio-group>
    </div>
    @if(locatorImportLibraryDataForm.controls.geocodingSelection.value){
    <div class="warning-text" i18n="@@automatedGeocodingWarning">
      <span> Automated geocoding will be used to provide coordinates. </span>
    </div>
    }
  </div>
</form>
