<div class="button-container" *ngIf="!(layoutService.isDesktopView | async)">
  <button
    class="atlas-icon-button atlas-icon icon-street-view atlas-secondary-icon-button"
    mat-flat-button
    color="primary"
    aria-label="Street view"
    (click)="onStreetViewClicked()"
    matTooltip="Show street view"
    [matTooltipShowDelay]="1000"
    i18n-aria-label="@@streetViewButtonAriaLabel"
    i18n-matTooltip="@@streetViewButtonTooltip"
  ></button>
</div>
