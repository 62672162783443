@if(hasReportsForSelectedShapes$ | async) {
<div class="button-container">
  <button
    mat-flat-button
    color="primary"
    class="launch-button"
    (click)="onLaunchReporting()"
    [disabled]="pbiReportList.length === 0 || !reportDataAvailable"
    i18n="@@launchReporting"
  >
    Launch Reporting
  </button>
</div>
} @else if(showSummaryStatsReport){
<div class="button-container">
  <button
    mat-flat-button
    color="primary"
    class="launch-button"
    (click)="onLaunchSummaryReport()"
    [disabled]="!summaryStatsReportAvailable"
  >
    <span i18n="@@comparisonReport">Comparison Report</span>
  </button>
</div>
}
