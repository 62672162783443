<form [formGroup]="profilerReportingForm" autocomplete="off">
  <div class="form-controls-padding" style="margin-top: 10px">
    <!-- <div style="margin-bottom: 10px">
      You are currently generating a report for “{{ selectedShape.name }}” in
      "{{ selectedLocationData.name }}”
    </div> -->
    <div>
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label i18n="@@reportNamesLabel">Report names</mat-label>
        <input
          matInput
          type="text"
          formControlName="filter"
          placeholder="Search reports"
          (keyup)="onFilterChanged($event)"
          i18n-placeholder="@@searchReportsPlaceholder"
        />
        <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div>
      <div class="reports-label" i18n="@@reportsLabel">Reports</div>
      <div class="scrollable-list">
        <mat-selection-list
          #pbiReports
          [multiple]="false"
          [hideSingleSelectionIndicator]="true"
          (selectionChange)="onSelectionChange($event)"
        >
          <mat-list-option
            *ngFor="let pbiReport of dataSource"
            [value]="pbiReport"
          >
            <span class="report-name">{{ pbiReport.name }}</span>
            <div>
              <span class="report-description">{{
                pbiReport.description
              }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</form>
