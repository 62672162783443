<div class="container">
  <div class="column">
    <div class="info-container">
      <div class="text">
        <div class="header">
          <span class="material-symbols-outlined">call</span>
          <h2 i18n="@@contact">Contact</h2>
        </div>
        <div>
          <div i18n="@@contactHours">
            Mon-Fri 9am - 5pm GMT (excl. UK public holidays)
          </div>
          <div class="header">
            <a href="tel:+442076056055" class="link">+442076056055</a>
            <span
              class="material-symbols-outlined copy-text"
              (click)="copyContactNumber()"
              matTooltip="Copy"
              i18n-matTooltip="@@copyTooltip"
            >
              content_copy
            </span>
          </div>
          <div class="header">
            <a
              href="mailto:insitehelpline@caci.co.uk"
              target="_blank"
              class="link"
            >
              insitehelpline&#64;caci.co.uk
            </a>
            <span
              (click)="copyHelpEmail()"
              class="material-symbols-outlined copy-text"
              matTooltip="Copy"
              i18n-matTooltip="@@copyTooltip"
            >
              content_copy
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column">
    <div class="info-container">
      <div class="text">
        <div class="header">
          <mat-icon>help_outline</mat-icon>
          <h2 i18n="@@resources">Resources</h2>
        </div>
        <div i18n="@@resourcesDescription">
          Our Resources page contains everything from user guides, to video
          guides and templates
        </div>
        <div class="button-padding">
          <button
            matSuffix
            mat-flat-button
            color="primary"
            class="button"
            (click)="navigateToResources()"
            i18n="@@openResources"
          >
            Open Resources
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
