<div class="catchment-table-header">
  <div class="header">
    <h4 i18n="@@catchmentsHeader">Catchments</h4>
    <mat-checkbox
      [checked]="allSelected"
      [disabled]="dataSource && dataSource.length <= 1"
      (change)="setAll($event.checked)"
      *ngIf="
        dataSource && dataSource.length > 0 && !areMultipleLocationsSelected
      "
      i18n="@@selectAllCheckbox"
    >
      Select all
    </mat-checkbox>
  </div>
  <mat-divider></mat-divider>
</div>

<div *ngIf="!areMultipleLocationsSelected">
  <div
    *ngIf="dataSource?.length === 0"
    class="empty-list"
    i18n="@@noCatchmentsMessage"
  >
    There are currently no catchments associated with this location.
  </div>
  <mat-divider></mat-divider>
</div>

<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    class="catchment-table"
    *ngIf="dataSource && dataSource.length > 0"
  >
    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="first-column-width"
        i18n="@@typeColumn"
      >
        Type
      </th>
      <td (click)="onClick(element)" mat-cell *matCellDef="let element">
        <div
          *ngIf="element.type === locatorShapeTypes.Circle"
          matListItemIcon
          class="atlas-icon icon-circle padding-circle"
        ></div>
        <mat-icon
          *ngIf="element.type === locatorShapeTypes.Car"
          matListItemIcon
        >
          directions_car
        </mat-icon>
        <mat-icon
          *ngIf="element.type === locatorShapeTypes.Walk"
          matListItemIcon
        >
          directions_walk
        </mat-icon>
        <mat-icon
          *ngIf="element.type === locatorShapeTypes.PublicTransport"
          matListItemIcon
        >
          train
        </mat-icon>
        <span
          *ngIf="element.type === locatorShapeTypes.FreeForm"
          class="material-symbols-outlined freeform-position"
        >
          activity_zone
        </span>
        <span
          *ngIf="element.type === locatorShapeTypes.FreeFormLocked"
          class="material-symbols-outlined freeform-position"
        >
          activity_zone
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-width description-align"
        i18n="@@descriptionColumn"
      >
        Description
      </th>
      <td
        (click)="onClick(element)"
        mat-cell
        *matCellDef="let element"
        class="description-align truncate-description"
      >
        {{ element.name | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef class="last-column-width"></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          (click)="onDelete(element)"
          class="delete-icon"
          [disabled]="isCatchmentReportingReadonlySystem$ | async"
          i18n-aria-label="@@deleteButtonAriaLabel"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ highlighted: selection.isSelected(row) }"
    ></tr>
  </table>
</div>
