<div class="atlas-menu-container" atlasModifyPolyDisableUI>
  <button
    mat-icon-button
    color="primary"
    aria-label="Main menu"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <!-- consider moving this to its own component if we are going to keep it-->
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onToggleTheme()" *ngIf="showDarkMode()">
      <mat-icon>color_lens</mat-icon>
      <span i18n="@@toggleTheme">Toggle theme</span>
    </button>
    <button
      mat-menu-item
      (click)="onNavigateToStyleGuide()"
      *ngIf="showStyleGuide()"
    >
      <mat-icon>collections</mat-icon>
      <span i18n="@@devStyleGuide">Dev Style guide</span>
    </button>

    <button
      mat-menu-item
      (click)="onShowReferenceLibaries()"
      *ngIf="showLocatorReferenceLibraries()"
    >
      <mat-icon>collections</mat-icon>
      <span i18n="@@dataExplorer">Data explorer</span>
    </button>
    <button
      mat-menu-item
      (click)="onNavigateToUsage()"
      *ngIf="!(hideUsageSection$ | async)"
    >
      <mat-icon>show_chart</mat-icon>
      <span i18n="@@usage">Usage</span>
    </button>

    <button mat-menu-item (click)="onNavigateToSettings()">
      <mat-icon>settings</mat-icon>
      <span i18n="@@settings">Settings</span>
    </button>

    <button mat-menu-item (click)="onNavigateToHelp()">
      <mat-icon>help_outline</mat-icon>
      <span i18n="@@helpResources">Help & Resources</span>
    </button>

    <button mat-menu-item (click)="onNavigateToReleaseNotes()">
      <mat-icon>new_releases</mat-icon>
      <span i18n="@@whatsNew">What's new</span>
    </button>

    <button mat-menu-item (click)="onLogout()">
      <mat-icon>exit_to_app</mat-icon>
      <span i18n="@@logout">
        Log out
        {{
          (userInfo$ | async)?.attributes?.given_name +
            ' ' +
            (userInfo$ | async)?.attributes?.family_name
        }}
      </span>
    </button>
  </mat-menu>
</div>
