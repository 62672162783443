<form [formGroup]="addLibraryForm" autocomplete="off">
  <div
    class="header-padding text error-message"
    *ngIf="hasProfilerLimitBeenExceededResult"
    i18n="@@profilerLimitExceededMessage"
  >
    New Profiles can not be created. You have reached your allowance of records
    imported. Please contact your account manager to increase your allowance
  </div>

  <div class="header-padding text" i18n="@@selectCsvFileMessage">
    Select a .csv file to profile. By providing only postcodes in the file the
    quicker postcode only matcher will be used. If address lines are provided
    for one or more records, the slower full address matcher will be used.
  </div>
  <div class="file-container">
    <div class="link">
      <a (click)="onDownloadClicked()" i18n="@@downloadTemplateLink"
        >Download template</a
      >
    </div>
  </div>

  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <div class="file-button-padding">
      <input
        type="file"
        (change)="onFileSelected($event)"
        accept=".csv"
        class="file file-button-padding"
        #fileUpload
      />
      <button (click)="fileUpload.click()" class="file-button">
        <span i18n="@@selectFileButton">Select File</span>
      </button>
    </div>
    <mat-label i18n="@@fileLocationLabel">File location</mat-label>
    <input matInput type="text" [value]="getFilename()" readonly />
  </mat-form-field>
  <div class="form-controls-padding header-padding">
    <div class="profile-text" i18n="@@nameYourProfile">Name your profile</div>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label i18n="@@profileNameLabel">Profile name</mat-label>
      <input
        matInput
        type="text"
        placeholder="What are you working on?"
        i18n-placeholder="@@profileNamePlaceholder"
        formControlName="libraryName"
      />
      <mat-error
        *ngIf="emptyLibraryName()"
        i18n="@@emptyProfilerLibraryNameError"
      >
        Please enter a profile name
      </mat-error>
      <mat-error
        *ngIf="!uniqueLibraryName()"
        i18n="@@uniqueProfilerLibraryNameError"
      >
        Another profile has this name, please choose another name
      </mat-error>
    </mat-form-field>
  </div>
</form>
