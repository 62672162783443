<div class="name">
  <div class="name-bold">{{ dataSource?.companyName }}</div>
  <div i18n="@@totalConsumption">Total consumption</div>
</div>
<div class="display-flex usage-border">
  <div class="panel-line usage-line"></div>
  <div class="panel usage-panel-background">
    <div>
      <div class="usage-details">
        <div class="usage">
          {{ dataSource?.totalRuns ?? 0 | number : '2.' }}
        </div>
        <div class="subscription" i18n="@@ofAnnualModelLimit">
          of {{ dataSource?.annualModelLimit ?? 0 | number : '2.' }}
        </div>
      </div>
      <div>
        <div class="usage-percentage">
          {{ dataSource?.companyUsage ?? 0 | number : '1.2-2' }}%
          <span i18n="@@consumedForPeriod">consumed for the period</span>
          <div class="renewal">
            {{ dataSource?.licenseStartDate | date : 'dd MMM yyyy' }}
            <span i18n="@@to">to</span>
            {{ dataSource?.renewalDate | date : 'dd MMM yyyy' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
