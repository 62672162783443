import {
  DriveTimeShapeDefaults,
  LocatorShapeDefaults
} from 'src/app/core/models/locator-shape-defaults';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';

export function getShapeUnit(
  shapeType: LocatorShapeTypes,
  locatorShapeDefaults: LocatorShapeDefaults | null,
  defaultDistanceUnit: string
) {
  const shapeUnit = {
    1: () => getDriveTimeUnit(locatorShapeDefaults?.car!, defaultDistanceUnit!),
    2: () =>
      getDriveTimeUnit(locatorShapeDefaults?.walk!, defaultDistanceUnit!),
    3: () => defaultDistanceUnit!,
    4: () => '',
    5: () =>
      getDriveTimeUnit(
        locatorShapeDefaults?.publicTransport!,
        defaultDistanceUnit!
      ),
    6: () => ''
  };

  return shapeUnit[shapeType]();
}

function getDriveTimeUnit(
  shapeDefault: DriveTimeShapeDefaults,
  defaultDistanceUnit: string
) {
  return shapeDefault?.distance! === 0
    ? $localize`:@@minute:minute`
    : defaultDistanceUnit;
}
