import { Component, Input } from '@angular/core';

import * as fromUIStore from 'src/app/core/store';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { EditLibraryDialogComponent } from '../edit-library-dialog/edit-library-dialog.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'atlas-edit-library',
  templateUrl: './edit-library.component.html',
  styleUrls: ['../../less/library-management-button.component.less']
})
export class EditLibraryComponent {
  @Input() currentLibrary: LocatorLibrary;

  isCatchmentReportingReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getCatchmentReportingReadonlySystem
  );

  constructor(
    public dialogService: DialogService,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  onOpenEditLibraryDialog() {
    this.dialogService.show(EditLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: $localize`:@@libraryEntityName:Library`,
        mode: DialogMode.Edit,
        headerPrefix: $localize`:@@editHeaderPrefix:Edit`,
        library: this.currentLibrary,
        affirmativeButtonText: $localize`:@@updateAffirmativeButtonText:Update`
      },
      disableClose: true
    });
  }
}
