<dx-data-grid
  style="padding-top: 10px"
  class="standard-grid"
  [dataSource]="dataSource"
  keyExpr="email"
  atlasDxDataGridDefaultOptions
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customLoad]="loadState"
    [customSave]="saveState"
  >
  </dxo-state-storing>

  <dxo-load-panel [enabled]="false"> </dxo-load-panel>

  <dxi-column
    dataField="fullName"
    caption="Name"
    i18n-caption="@@nameColumn"
  ></dxi-column>
  <dxi-column dataField="email"></dxi-column>
  <dxi-column
    dataField="circle"
    caption="Circle"
    [alignment]="'left'"
    i18n-caption="@@circleColumn"
  ></dxi-column>
  <dxi-column
    dataField="polygon"
    caption="Polygon"
    [alignment]="'left'"
    i18n-caption="@@polygonColumn"
  ></dxi-column>
  <dxi-column
    dataField="car"
    caption="Drive"
    [alignment]="'left'"
    i18n-caption="@@driveColumn"
  ></dxi-column>
  <dxi-column
    dataField="walk"
    caption="Walk"
    [alignment]="'left'"
    i18n-caption="@@walkColumn"
  ></dxi-column>
  <dxi-column
    dataField="publicTransport"
    caption="Public Transport"
    [alignment]="'left'"
    i18n-caption="@@publicTransportColumn"
  ></dxi-column>
  <dxi-column
    dataField="totalCatchments"
    caption="All Catchments"
    [alignment]="'left'"
    i18n-caption="@@allCatchmentsColumn"
  ></dxi-column>
  <dxi-column
    dataField="percentageOfExecutedCatchments"
    caption="All Catchments %"
    [alignment]="'left'"
    [calculateCellValue]="formatAllCatchmentsPercentage"
    i18n-caption="@@allCatchmentsPercentageColumn"
  ></dxi-column>

  <div>
    <atlas-dx-grid-header
      gridTitle="All Users"
      i18n-gridTitle="@@allUsersGridTitle"
    ></atlas-dx-grid-header>
  </div>
  <div *dxTemplate="let data of 'parentTemplate'">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</dx-data-grid>
