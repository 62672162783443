<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>
  <div>
    <mat-dialog-content class="mat-dialog-content">
      <p></p>
      <div class="message-content text">
        @if (locationsCount === 0) {
        <span i18n="@@deleteSingleLocationConfirmation">
          Are you sure you want to delete '{{ selectedLocation?.name }}' and all
          its catchments?
        </span>
        } @else {
        <div
          class="multi-locations-text"
          i18n="@@deleteMultipleLocationsConfirmation"
        >
          Are you sure you would like to delete {{ locationsCount }} locations?
        </div>
        <div class="text-padding">
          <mat-checkbox
            (change)="isCheckboxChecked = $event.checked"
            [disableRipple]="true"
            i18n="@@deleteLocationsCheckbox"
          >
            By checking this tick box you are confirming that you would like to
            delete all the checked locations
          </mat-checkbox>
        </div>
        }
      </div>
      @if (isShared){
      <div class="shared-text" i18n="@@sharedLocationDeleteWarning">
        '{{ selectedLocation?.name }}' contains catchment(s) shared in a report,
        by deleting this location the report(s) will no longer be accessible.
      </div>
      }
      <p></p>
    </mat-dialog-content>

    <atlas-dialog-footer>
      <div class="buttons-container">
        <atlas-dialog-cancel-button></atlas-dialog-cancel-button>
        <atlas-dialog-affirmative-close-button
          label="Delete"
          (clicked)="onDeleteClicked(selectedLocation)"
          [disabled]="locationsCount !== 0 && !isCheckboxChecked"
          i18n-label="@@deleteButtonLabel"
        ></atlas-dialog-affirmative-close-button>
      </div>
    </atlas-dialog-footer>
  </div>
</div>
