import { Component } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { validateCoordinate } from 'src/app/shared/utils/form-validators/form-value-contains-invalid-coordinate.validator';

@Component({
  selector: 'atlas-define-new-location-form',
  templateUrl: './atlas-define-new-location-form.component.html',
  styleUrls: ['./atlas-define-new-location-form.component.less']
})
export class DefineNewLocationFormComponent {
  defineNewLocationForm: UntypedFormGroup;

  requiredErrorMessage: string = $localize`:@@requiredErrorMessage: is required`;
  invalidPrefix: string = $localize`:@@invalidPrefix: This`;
  invalidSuffix: string = $localize`:@@invalidSuffix: is invalid, please try again`;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.defineNewLocationForm = this.createDefineNewLocationForm();
  }

  createDefineNewLocationForm() {
    return this.fb.group({
      latitude: [null, this.validate(-90, 90)],
      longitude: [null, this.validate(-180, 180)]
    });
  }

  isLatitudeValid() {
    return (
      this.defineNewLocationForm &&
      this.defineNewLocationForm.controls &&
      this.checkValidity(this.defineNewLocationForm.controls.latitude)
    );
  }

  isLongitudeValid() {
    return (
      this.defineNewLocationForm &&
      this.defineNewLocationForm.controls &&
      this.checkValidity(this.defineNewLocationForm.controls.longitude)
    );
  }

  checkValidity(control: AbstractControl) {
    return control.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(errors: any, type: string) {
    return errors['required'] === true
      ? `${type} ${this.requiredErrorMessage}`
      : this.isCoordinateValid(errors)
      ? this.getInvalidErrorMessage(type)
      : null;
  }

  invalidForm(): boolean {
    return (
      !(this.defineNewLocationForm && this.defineNewLocationForm.controls) ||
      this.defineNewLocationForm.invalid
    );
  }

  validate(lower: number, upper: number): any {
    return Validators.compose([
      Validators.required,
      Validators.min(lower),
      Validators.max(upper),
      validateCoordinate()
    ]);
  }

  private isCoordinateValid(errors: any) {
    return (
      errors['invalidCoordinate'] ||
      errors['max']?.max < errors['max']?.actual ||
      errors['min']?.min > errors['min']?.actual
    );
  }

  private getInvalidErrorMessage(type: string) {
    return `${this.invalidPrefix} ${type.toLocaleLowerCase()} ${
      this.invalidSuffix
    }`;
  }
}
