<atlas-single-button-dialog
  [buttonText]="closeText"
  [headerText]="data.headerText"
>
  <atlas-swap-profiler-library-form
    formContent
    [userLibraries]="userLibraries"
    (switchClicked)="onSwitchClicked($event)"
    [currentLibraryId]="data.currentLibraryId"
    (deleteClicked)="onDeleteClicked($event)"
  ></atlas-swap-profiler-library-form>
</atlas-single-button-dialog>
