<atlas-single-button-dialog
  [buttonText]="closeText"
  [headerText]="data.headerText"
>
  <atlas-swap-scenario-form
    formContent
    [userScenarios]="userScenarios"
    (switchClicked)="onSwitchClicked($event)"
    (deleteClicked)="onDeleteClicked($event)"
    [currentScenarioId]="data.currentScenarioId"
  ></atlas-swap-scenario-form>
</atlas-single-button-dialog>
