import { Component, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { datadogRum } from '@datadog/browser-rum';
import { loadMessages, locale } from 'devextreme/localization';
import frMessages from 'devextreme/localization/messages/fr.json';
import esMessages from 'devextreme/localization/messages/es.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  locale: string;

  constructor(public themeService: ThemeService, private router: Router) {
    this.locale = this.getLocale();

    this.initMessages();
    locale(this.locale);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        // The SDK will automatically detect this as a route_change
        datadogRum.startView({
          name: event.urlAfterRedirects
        });
      });
  }

  getLocale() {
    const lang = localStorage.getItem('lang');
    return lang != null ? lang.split('-')[0] : 'en';
  }

  initMessages() {
    if (this.locale === 'fr') {
      loadMessages(frMessages);
    }

    if (this.locale === 'es') {
      loadMessages(esMessages);
    }
  }
}
