<div *dxTemplate="let data of 'gridTitleTemplate'" class="title-count">
  <div class="gridTitleStyle">
    <h3 class="inline">{{ gridTitle }}</h3>
    &nbsp;&nbsp;
    <h4 class="inline">{{ rowCount.get$ | async }}</h4>
  </div>
</div>

<!--<div *dxTemplate="let data of 'filterTextTemplate'">
  <div *ngIf="filterValues" class="filter-values-style">
    <div class="filter-text" i18n="@@filterValues"> {{ filterValues }} </div>
    <button class="reset-filter-button" (click)="resetFilters()" i18n="@@clearButton"> Clear </button>
  </div>
</div>-->

<div *dxTemplate="let data of 'rowCountTemplate'">
  <div>
    <button
      class="reset-filter-button"
      *ngIf="showFilterReset"
      (click)="resetFilters()"
      i18n="@@clearFilterButton"
    >
      Clear Filter
    </button>
  </div>
</div>
