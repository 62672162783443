<div class="atlas-dialog-container">
  <atlas-dialog-header
    headerText="Data explorer"
    i18n-headerText="@@dataExplorer"
  ></atlas-dialog-header>
  <mat-dialog-content class="mat-dialog-content">
    <atlas-locator-reference-library-form
      #referenceLibraryForm
      [libaries]="data.referenceLibraries"
    ></atlas-locator-reference-library-form>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <div class="dialog-buttons-container">
      <atlas-dialog-cancel-button
        (clicked)="buttonClicked.emit()"
        label="Close"
        i18n-label="@@closeButtonLabel"
      ></atlas-dialog-cancel-button>
      <button
        mat-flat-button
        color="primary"
        class="import-report-button"
        aria-label="Import Data"
        i18n-aria-label="@@importDataButton"
        (click)="onCopyReferenceLibrary()"
      >
        <span i18n="@@importButtonLabel">Import</span>
      </button>
    </div>
  </atlas-dialog-footer>
</div>
