<div style="padding-left: 10px">
  <div class="header-content">
    <h1 class="title-margin" i18n="@@releaseNotesTitle">Release Notes</h1>
    <!-- <span class="sub-title-padding"
      >Current InSite Everywhere version: {{ appVersion }}</span
    > -->
  </div>
  <div class="container">
    <div
      class="release-notes-content-list"
      (mousewheel)="onMouseWheel($event)"
      (scroll)="onScroll($event)"
      (scrollend)="onScrollEnd($event)"
    >
      <atlas-release-notes-content-list
        [goToRelease]="goToRelease$ | async"
      ></atlas-release-notes-content-list>
    </div>
    <div
      class="release-notes-anchors"
      *ngIf="layoutService.isDesktopView$ | async"
    >
      <mat-selection-list *ngFor="let release of listOfReleases; index as i">
        <mat-icon
          class="release-note-anchor-bullet"
          [class.selected-release]="release === selectedRelease"
          [style.visibility]="
            release !== selectedRelease ? 'hidden' : 'visible'
          "
          >arrow_forward_ios</mat-icon
        >
        <span
          (click)="goTo(release)"
          class="release-note-anchor"
          [class.selected-release]="release === selectedRelease"
          [id]="release"
          >{{ listOfReleaseNames[i] }}
        </span>
      </mat-selection-list>
    </div>
  </div>
</div>
