<div class="gazetteer-container">
  <mat-form-field class="search-input-height">
    <input
      matInput
      #searchInput
      type="search"
      placeholder="Search"
      [matAutocomplete]="auto"
      [formControl]="gazetteerControl"
      (keydown.enter)="$event.preventDefault()"
      value="{{ searchText$ | async }}"
      i18n-placeholder="@@searchPlaceholder"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="onFindLocation($event)"
      class="gazetteer-autocomplete"
    >
      <mat-optgroup *ngFor="let group of foundLocations$ | async">
        <img [src]="group.groupIcon" class="search-group-icon" />
        {{ group.groupName }}
        <mat-option *ngFor="let location of group.results" [value]="location">
          {{ location.description }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
  <div class="buttons-container">
    <atlas-clear-search></atlas-clear-search>
    <atlas-define-new-location></atlas-define-new-location>
    <atlas-drop-pin></atlas-drop-pin>
    <atlas-find-my-location></atlas-find-my-location>
  </div>
</div>
