import { createReducer, on } from '@ngrx/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import {
  clearAppFeaturesStateOnLogoff,
  loadAppFeaturesSucceeded
} from '../actions/app-feature-ui.actions';
import { locatorFeatureClicked } from '../actions/locator-ui.actions';
import { profilerFeatureClicked } from '../actions/profiler-ui.actions';
import {
  changeLocationCancelled,
  changeLocationCancelledCompleted,
  changeLocationClicked,
  changeLocationConfirmedAttempt,
  smFeatureClicked,
  newLocationSelected,
  resetChangeLocationState
} from '../actions/spatial-modeller-ui.actions';

export interface State {
  spatialModellerFeatureAllowed: boolean;
  featureSelected: boolean;
  changeLocation: boolean;
  newLocationSelected: boolean;
  existingSelectedLocation: {
    location: Point | null | undefined;
    address: string | undefined;
  };
}

export const initialState: State = {
  spatialModellerFeatureAllowed: false,
  featureSelected: false,
  changeLocation: false,
  newLocationSelected: false,
  existingSelectedLocation: { location: null, address: '' }
};

export const spatialModellerUIReducer = createReducer(
  initialState,
  on(changeLocationClicked, (state, { address, location }) => {
    return {
      ...state,
      changeLocation: true,
      newLocationSelected: false,
      existingSelectedLocation: {
        location: location,
        address: address
      }
    };
  }),
  on(changeLocationConfirmedAttempt, changeLocationCancelled, (state) => {
    return {
      ...state,
      changeLocation: false,
      newLocationSelected: false
    };
  }),
  on(newLocationSelected, (state) => {
    return {
      ...state,
      newLocationSelected: true
    };
  }),
  on(changeLocationCancelledCompleted, (state) => {
    return {
      ...state,
      existingSelectedLocation: { location: null, address: '' }
    };
  }),
  on(resetChangeLocationState, (state) => {
    return {
      ...state,
      changeLocation: false,
      newLocationSelected: false,
      existingSelectedLocation: { location: null, address: '' }
    };
  }),
  on(loadAppFeaturesSucceeded, (state, { appFeatures }) => {
    return {
      ...state,
      spatialModellerFeatureAllowed: appFeatures.spatialModellerFeature
    };
  }),
  on(smFeatureClicked, (state) => {
    return {
      ...state,
      featureSelected: true
    };
  }),
  on(locatorFeatureClicked, profilerFeatureClicked, (state) => {
    return {
      ...state,
      featureSelected: false
    };
  }),
  on(clearAppFeaturesStateOnLogoff, (state) => {
    return {
      ...initialState
    };
  })
);
