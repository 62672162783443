import { Component, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { formValueExistInArrayValidator } from 'src/app/shared/utils/form-validators/form-value-exist-in-array.validator';

@Component({
  selector: 'atlas-add-profiler-library-form',
  templateUrl: './add-profiler-library-form.component.html',
  styleUrls: ['./add-profiler-library-form.component.less']
})
export class AddProfilerLibraryFormComponent {
  fileName: string;
  selectedFile: File;
  private _library: ProfilerLibrary | null = null;
  @Input()
  set library(newValue: ProfilerLibrary | null) {
    this._library = newValue;
  }

  get library(): ProfilerLibrary | null {
    return this._library;
  }

  addLibraryForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) {}
  private _userLibraries: string[] = [];
  @Input()
  set userLibraries(newValue: string[]) {
    this._userLibraries = [...newValue];
    // Add validator to avoid libraries with the same name

    this.addLibraryForm?.controls.libraryName.setValidators([
      Validators.required,
      formValueExistInArrayValidator(
        this.library
          ? this._userLibraries.filter((item) => item !== this.library?.name)
          : this._userLibraries
      )
    ]);
  }
  get userLibraries(): string[] {
    return this._userLibraries;
  }

  @Input()
  hasProfilerLimitBeenExceededResult: boolean;

  ngOnInit() {
    const initialValue: ProfilerLibrary =
      this.library || ({} as ProfilerLibrary);
    this.addLibraryForm = this.createLibraryForm(initialValue);
  }

  getFilename() {
    return this.fileName || $localize`:@@fileLocationPlaceholder:File location`;
  }

  createLibraryForm(initialLibraryValue: ProfilerLibrary) {
    const form = this.fb.group({
      id: initialLibraryValue.id,
      libraryName: [initialLibraryValue.name]
    });

    return form;
  }

  emptyLibraryName() {
    if (this.addLibraryForm.controls.libraryName.value) {
      return;
      this.addLibraryForm.controls.libraryName.value?.trim().length === 0;
    }

    return this.addLibraryForm.controls.libraryName.errors?.required;
  }
  uniqueLibraryName() {
    return !this.addLibraryForm.controls.libraryName.errors?.valueAlreadyExist;
  }

  invalidForm(): boolean {
    return (
      !(this.addLibraryForm && this.addLibraryForm.controls) ||
      !this.addLibraryForm.dirty ||
      !this.addLibraryForm.controls?.libraryName.valid ||
      this.emptyLibraryName() ||
      !this.uniqueLibraryName() ||
      !this.selectedFile
    );
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.fileName = file.name;
    this.selectedFile = file;
  }

  downloadFile(url: string, fileName: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  onDownloadClicked() {
    const csvTemplateUrl = 'assets/templates/profiling_data_template.csv';
    this.downloadFile(csvTemplateUrl, 'profiling_data_template.csv');
  }
}
