import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromLocatorStore from 'src/app/locator-store';
import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorReportingFormComponent } from '../locator-reporting-form/locator-reporting-form.component';
import {
  logPowerBiReportAttempt,
  SharePowerBIReportAttempt
} from 'src/app/locator-store/actions/locator-shape.actions';
import { LocatorReportTypes } from 'src/app/locator/types/locator-report.types';
import { ThematicIconTileSetLayer } from 'src/app/shared/atlas-mapping/layers/thematic-icon-tileset-layer';
import { triggerCreateSummaryStatsAttempt } from 'src/app/locator-store/actions/locator-summary-stats-actions';
import { DataDogService } from 'src/app/core/services/datadog.service';

@Component({
  selector: 'atlas-locator-reporting-dialog',
  templateUrl: './locator-reporting-dialog.component.html',
  styleUrls: ['./locator-reporting-dialog.component.less']
})
export class LocatorReportingDialogComponent {
  @ViewChild('reportingForm') reportingForm: LocatorReportingFormComponent;
  @Output() buttonClicked = new EventEmitter();
  dialogMode: DialogMode;

  availablePbiReports: LocatorPowerBiReportingInfo[] = [];
  isMultiShapeSelection: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pbiReportList: LocatorPowerBiReportingInfo[];
      selectedLocationData: LocatorLibraryData;
      selectedShapes: LocatorShape[];
      showSummaryStatsReport: boolean;
    },
    private locatorStore$: Store<fromLocatorStore.State>,
    private dataDogService: DataDogService
  ) {
    this.isMultiShapeSelection = this.data.selectedShapes.length > 1;
    this.availablePbiReports = this.isMultiShapeSelection
      ? this.getMultiShapeSelectionReports()
      : this.getSingleShapeSelectionReports();
  }

  onLaunchReport() {
    this.dataDogService.trackUserAction('launch_report_button', {
      component: 'LocatorReportingDialogComponent',
      button: 'launch_report',
      value: this.reportingForm.selectedReport?.reportType
    });
    if (
      this.reportingForm.selectedReport?.reportType ===
      LocatorReportTypes.Summary_Stats
    ) {
      this.locatorStore$.dispatch(triggerCreateSummaryStatsAttempt());
    } else {
      window.open(this.reportingForm.selectedPbiReportUrl, '_blank');
      this.locatorStore$.dispatch(logPowerBiReportAttempt());
    }
  }

  onShareClicked() {
    this.locatorStore$.dispatch(
      SharePowerBIReportAttempt({
        shapeIds: this.data.selectedShapes.map((shape) => shape.id)
      })
    );
    navigator.clipboard.writeText(this.reportingForm.selectedPbiReportUrl);
  }

  isSpinnerVisible() {
    return !this.reportingForm
      ? null
      : this.reportingForm?.selectedReport &&
          !this.reportingForm?.selectedPbiReportUrl &&
          this.reportingForm.selectedReport?.reportType !==
            LocatorReportTypes.Summary_Stats;
  }

  isTextCopyButtonVisible() {
    return !this.isSpinnerVisible();
  }

  isSummaryStatsReportSelected() {
    return (
      this.reportingForm?.selectedReport &&
      this.reportingForm.selectedReport?.reportType ===
        LocatorReportTypes.Summary_Stats
    );
  }

  getMultiShapeSelectionReports() {
    let multiShapePbiReports = this.data.pbiReportList.filter(
      (pbiReport) => pbiReport.reportType === LocatorReportTypes.Multi_Shape
    );
    if (this.data.showSummaryStatsReport) {
      const summaryStatsReports = this.data.pbiReportList.filter(
        (pbiReport) => pbiReport.reportType === LocatorReportTypes.Summary_Stats
      );
      multiShapePbiReports.push(...summaryStatsReports);
    }
    return multiShapePbiReports;
  }

  getSingleShapeSelectionReports() {
    let singleShapePbiReports = this.data.pbiReportList.filter(
      (pbiReport) => pbiReport.reportType === LocatorReportTypes.Single_Shape
    );
    if (this.data.showSummaryStatsReport) {
      const summaryStatsReports = this.data.pbiReportList.filter(
        (pbiReport) => pbiReport.reportType === LocatorReportTypes.Summary_Stats
      );
      singleShapePbiReports.push(...summaryStatsReports);
    }
    return singleShapePbiReports;
  }
}
