<atlas-info-box
  #removeInfoBox
  [htmlContent]="infoBoxContent"
  [(element)]="infoButton"
>
</atlas-info-box>

<form [formGroup]="swapScenarioForm" autocomplete="off">
  <div class="form-controls-padding" style="margin-top: 10px">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label i18n="@@searchScenariosLabel"
        >Search existing scenarios</mat-label
      >
      <input
        matInput
        type="text"
        formControlName="filter"
        placeholder="Search existing scenarios"
        i18n-placeholder="@@searchScenariosPlaceholder"
        (keyup)="onFilterChanged($event)"
      />
      <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@sortLabel">Sort</mat-label>
      <mat-select
        formControlName="sort"
        (selectionChange)="onSortChanged($event)"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-option *ngFor="let option of sortOptions" [value]="option.value">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h3 class="scenario-list-header" i18n="@@scenarioNameHeader">
      Scenario name
    </h3>
    <div class="list-container">
      <mat-list *ngIf="dataSource.length > 0">
        <mat-list-item *ngFor="let scenario of dataSource">
          <div class="scenario-list-item-container">
            <div class="item-layout">
              <div
                matListItemLine
                [matTooltip]="scenario.name"
                matTooltipClass="multiline-tooltip-wrap"
                [matTooltipDisabled]="scenario.name.length < 40"
              >
                {{ scenario.name }}
              </div>
              <div matListItemLine>
                {{ scenario.editedDt.toString() | timeAgo }}
              </div>
            </div>
            <div class="button-layout">
              <div
                class="scenario-buttons-container"
                *ngIf="scenario.id === currentScenarioId"
              >
                <div
                  matTooltip="Current scenario"
                  i18n-matTooltip="@@currentScenarioTooltip"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                >
                  <button
                    class="atlas-icon-button scenario-list-button"
                    mat-flat-button
                    color="primary"
                    disabled="true"
                  >
                    <mat-icon>swap_horiz</mat-icon>
                  </button>
                </div>
                <div
                  matTooltip="Delete scenario"
                  i18n-matTooltip="@@deleteScenarioTooltip"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                >
                  <button
                    class="atlas-icon-button atlas-secondary-icon-button"
                    color="primary"
                    mat-flat-button
                    disabled="true"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>

              <div
                class="scenario-buttons-container"
                *ngIf="scenario.id !== currentScenarioId"
              >
                <button
                  class="atlas-icon-button scenario-list-button"
                  mat-flat-button
                  color="primary"
                  (click)="onSwitchToScenario(scenario.id)"
                  matTooltip="Open this scenario"
                  i18n-matTooltip="@@openScenarioTooltip"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                  *ngIf="scenario.id != currentScenarioId"
                >
                  <mat-icon>swap_horiz</mat-icon>
                </button>
                <div
                  matTooltip="Delete scenario"
                  i18n-matTooltip="@@deleteScenarioTooltip"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                >
                  <button
                    class="atlas-icon-button atlas-secondary-icon-button"
                    mat-flat-button
                    color="primary"
                    (click)="onDeleteScenario(scenario.id)"
                    [disabled]="scenario.dependentScenarios.length > 0"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>

              <div>
                <atlas-info-button
                  [scenarioId]="scenario.id"
                  *ngIf="isInfoButtonVisible(scenario)"
                  (removeInfoClicked)="openInfoBox($event)"
                >
                </atlas-info-button>
                <div
                  *ngIf="!isInfoButtonVisible(scenario)"
                  style="width: 20px"
                ></div>
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-list>

      <div
        class="scenario-list-header"
        *ngIf="dataSource.length == 0 && !scenariosLoading"
      >
        <div class="body-large" i18n="@@noRecordsFound">
          No records found, edit search criteria
        </div>
      </div>

      <div class="scenario-list-spinner" *ngIf="scenariosLoading">
        <mat-spinner diameter="34" strokeWidth="2"> </mat-spinner>
      </div>
    </div>
  </div>
</form>
