import { Component, OnDestroy, OnInit } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromStore from 'src/app/reporting/store';
import * as fromUIStore from 'src/app/core/store';
import { getRecordsAllUsersUsageSummary } from 'src/app/reporting/store';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-profiled-records-usage-chart',
  templateUrl: './profiled-records-usage-chart.component.html',
  styleUrls: ['./profiled-records-usage-chart.component.less']
})
export class ProfiledRecordsUsageChartComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  data: any[] = [];
  allowInternalUsersUsage: boolean;

  showXAxis: boolean = true;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = $localize`:@@usersLabel:Users`;
  showYAxisLabel: boolean = true;
  yAxisLabel: string = $localize`:@@recordsLabel:Records`;
  showGridLines: boolean = false;

  constructor(
    private Store$: Store<fromStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}
  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );
    this.subscription.add(
      this.Store$.select(getRecordsAllUsersUsageSummary).subscribe((usage) => {
        this.data = [];
        const filteredUsers = hideCACIUsers(
          usage,
          this.allowInternalUsersUsage
        );
        filteredUsers?.forEach((user) => {
          this.data.push({
            name: user.fullName,
            value: user.totalImportedRows
          });
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
