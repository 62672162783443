<div class="signup-body">
  <form
    class="form-container"
    (ngSubmit)="onSignup()"
    [formGroup]="signupForm"
    autocomplete="off"
  >
    <div>
      <h2 i18n="@@signupTitle" class="title">Signup Atlas</h2>
    </div>
    <hr class="line-hr" />
    <div class="signup-div">
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@firstNameLabel">First Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="firstName"
            placeholder="First Name"
            i18n-placeholder="@@firstNamePlaceholder"
          />
          <mat-error i18n="@@emptyFirstNameError" *ngIf="emptyFirstName()">
            Please enter your First Name
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@surnameLabel">Surname</mat-label>
          <input
            matInput
            type="text"
            formControlName="surname"
            placeholder="Surname"
            i18n-placeholder="@@surnamePlaceholder"
          />
          <mat-error i18n="@@emptySurnameError" *ngIf="emptySurname()">
            Please enter your Surname
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@companyIdLabel">Company Id</mat-label>
          <input
            matInput
            type="number"
            formControlName="tenantId"
            placeholder="Company Id"
            i18n-placeholder="@@companyIdPlaceholder"
          />
          <mat-error i18n="@@emptyCompanyIdError" *ngIf="emptyCompanyId()">
            Please enter your Company Id
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@emailLabel">Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
            i18n-placeholder="@@emailPlaceholder"
          />
          <mat-error i18n="@@invalidEmailError" *ngIf="invalidEmail()">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@passwordLabel">Password</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Password"
            i18n-placeholder="@@passwordPlaceholder"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <mat-error i18n="@@emptyPasswordError" *ngIf="emptyPassword()">
            Please enter your password
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-btn-div">
        <button
          i18n="@@registerButton"
          mat-raised-button
          color="primary"
          type="submit"
        >
          REGISTER
        </button>
      </div>
      <p i18n="@@alreadyMemberText" style="font-size: 12px">
        Already a member?
        <span class="link" href="#" (click)="showLogin()">Login</span>
      </p>
    </div>
  </form>
  <br />
</div>
