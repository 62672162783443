import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { AddEditCatchmentDialogComponent } from '../add-edit-catchment-dialog/add-edit-catchment-dialog.component';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromLocatorUIStore from 'src/app/core/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { generateTempFreeFormLocatorLibraryDataPointAttempt } from 'src/app/locator-store/actions/locator-library.actions';
import { addingEditingPolygonAttempt } from 'src/app/core/store/actions/locator-ui.actions';
import { AddEditPolygonConfirmationDialogComponent } from '../add-edit-polygon-confirmation-dialog/add-edit-polygon-confirmation-dialog.component';
import { editLocatorPolygonShapeAttempt } from 'src/app/locator-store/actions/locator-shape.actions';
import {
  getShapeTypeName,
  isShapeDisabled
} from 'src/app/locator/helpers/locator-shape.helper';
import { LocatorShapeDefaults } from 'src/app/core/models/locator-shape-defaults';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-add-edit-catchment-buttons',
  templateUrl: './add-edit-catchment-buttons.component.html',
  styleUrls: ['./add-edit-catchment-buttons.component.less']
})
export class AddEditCatchmentButtonsComponent {
  @Input()
  set selectedShapes(selectedShapes: LocatorShape[] | null) {
    this.enableEditMode = selectedShapes && selectedShapes.length === 1;
    this.selectedShape = selectedShapes ? selectedShapes[0] : null;
  }

  @Input() location: LocatorLibraryData | null;
  @Input() areMultipleLocationsSelected: boolean | null;

  selectedShape: LocatorShape | null;
  enableEditMode: boolean | null = false;
  locatorShapeType = LocatorShapeTypes;
  locatorShapeDefaults: LocatorShapeDefaults | null;

  locatorShapeDefaults$ = this.locatorUIStore$.select(
    fromLocatorUIStore.getLocatorShapesDefaults
  );

  generatingCatchmentInProgress$ = this.locatorUIStore$.select(
    fromLocatorUIStore.getGeneratingCatchments
  );

  isCatchmentReportingReadonlySystem$ = this.store$.select(
    fromLocatorUIStore.getCatchmentReportingReadonlySystem
  );

  isDisabled: (shapeType: any) => boolean = isShapeDisabled; //getting function from locator-shape.herlper.ts

  private subscription = new Subscription();

  constructor(
    public dialogService: DialogService,
    private store$: Store<fromLocatorStore.State>,
    private locatorUIStore$: Store<fromLocatorUIStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.locatorShapeDefaults$.subscribe((locatorShapeDefaults) => {
        this.locatorShapeDefaults = locatorShapeDefaults;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAddCatchment(type: LocatorShapeTypes) {
    this.dialogService.show(AddEditCatchmentDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: getShapeTypeName(type),
        mode: DialogMode.Add,
        headerPrefix: $localize`:@@addHeaderPrefix:Add`,
        affirmativeButtonText: $localize`:@@confirmAffirmativeButtonText:Confirm`,
        shapeType: type,
        shape: null
      },
      disableClose: true
    });
  }

  onAddPolygon() {
    this.store$.dispatch(
      generateTempFreeFormLocatorLibraryDataPointAttempt({
        location: {
          latitude: this.location?.latitude!,
          longitude: this.location?.longitude!
        }
      })
    );

    this.locatorUIStore$.dispatch(addingEditingPolygonAttempt());
  }

  onEditCatchment() {
    this.dialogService.show(AddEditCatchmentDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: getShapeTypeName(this.selectedShape!.type),
        mode: DialogMode.Edit,
        headerPrefix: $localize`:@@editHeaderPrefix:Edit`,
        affirmativeButtonText: $localize`:@@confirmAffirmativeButtonText:Confirm`,
        shapeType: this.selectedShape!.type,
        shape: this.selectedShape,
        libraryId: this.location?.libraryId
      },
      disableClose: true
    });
  }

  onEditDescription() {
    this.dialogService.show(AddEditPolygonConfirmationDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: $localize`:@@polygonDescription:Polygon Description`,
        mode: DialogMode.Edit,
        headerPrefix: $localize`:@@editHeaderPrefix:Edit`,
        affirmativeButtonText: $localize`:@@confirmAffirmativeButtonText:Confirm`,
        location: null,
        geoJson: null,
        currentDescription: this.selectedShape?.name
      },
      disableClose: true
    });
  }

  onEditPolygon() {
    this.store$.dispatch(editLocatorPolygonShapeAttempt());

    this.locatorUIStore$.dispatch(addingEditingPolygonAttempt());
  }
}
