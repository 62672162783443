export class ProfilerLofiQueryResult {
  name: string;
  shapeName: string;
  isStats: boolean;
  isGraph: boolean;
  graphConfiguration: string;
  statsData: ProfilerLofiData[] = [];
  graphData: ProfilerLofiGraphData[] = [];
  pageTitle: string;
}

export class ProfilerLofiData {
  id: number;
  name: string;
  value: string;
  iconName: string;
}

export class ProfilerLofiGraphData {
  id: number;
  name: string;
  value: number;
}
