import { FeatureAction } from 'src/app/shared/types/feature-action.types';

export const smFeatureActions: Record<string, FeatureAction> = {
  selectedScenario: {
    actionType: 'SelectedScenario',
    updatables: ['scenarioId']
  },
  selectedSupplyPoint: {
    actionType: 'SelectedSupplyPoint',
    updatables: ['latitude', 'longitude']
  },
  selectedSupplyPointModelRunComplete: {
    actionType: 'SelectedSupplyPointModelRunComplete',
    updatables: ['scenarioId', 'supplyId', 'testId']
  }
};
