import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromStore from 'src/app/reporting/store';
import * as fromUIStore from 'src/app/core/store';
import { getCatchmentsAllUsersUsageSummary } from 'src/app/reporting/store';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-user-catchment-usage-chart',
  templateUrl: './user-catchment-usage-chart.component.html',
  styleUrls: ['./user-catchment-usage-chart.component.less']
})
export class UserCatchmentUsageChartComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  allowInternalUsersUsage: boolean;
  data: any[] = [];

  // options

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = $localize`:@@usersLabel:Users`;
  showYAxisLabel: boolean = true;
  yAxisLabel: string = $localize`:@@catchmentsLabel:Catchments`;
  showGridLines: boolean = false;

  constructor(
    private store$: Store<fromStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );
    this.subscription.add(
      this.store$
        .select(getCatchmentsAllUsersUsageSummary)
        .subscribe((usage) => {
          this.data = [];
          const filteredUsers = hideCACIUsers(
            usage,
            this.allowInternalUsersUsage
          );
          filteredUsers?.forEach((user) => {
            this.data.push({
              name: user.fullName,
              // value: user.totalCatchments
              series: [
                {
                  name: $localize`:@@circleSeries:Circle`,
                  value: user.circle
                },
                {
                  name: $localize`:@@driveSeries:Drive`,
                  value: user.car
                },
                {
                  name: $localize`:@@walkSeries:Walk`,
                  value: user.walk
                },
                {
                  name: $localize`:@@polygonSeries:Polygon`,
                  value: user.polygon
                }
                // {
                //   name: $localize`:@@totalSeries:Total`,
                //   value: user.totalCatchments
                // }
              ]
            });
          });
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
