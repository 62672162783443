<div class="selected-location-info">
  <div class="address-info-container">
    <atlas-properties-legend></atlas-properties-legend>
    @if (areMultipleLocationsSelected) {
    <div class="address-info">
      <h3 i18n="@@multipleLocationsNA">N/a</h3>
      <span class="secondary-address-info" i18n="@@multipleLocationsNA"
        >N/a</span
      >
    </div>
    } @else { @if(!(updateLocation$ | async)){
    <div class="address-info">
      @if(areSupplyPointAddressPropertiesEmpty()){
      <h3>{{ address | mainAddressInfo }}</h3>
      <span class="secondary-address-info">
        {{ address | secondaryAddressInfo }}
      </span>
      } @else {
      <span>{{
        [
          selectedSupplyPoint!.address1,
          selectedSupplyPoint!.address2,
          selectedSupplyPoint!.address3,
          selectedSupplyPoint!.address4
        ] | concatSupplyPointAddressProperties
      }}</span>
      }
    </div>
    } @else{
    <div class="change-location-container">
      @if(!(newLocationSelected$ | async)){
      <span i18n="@@selectNewLocation">Select a new location</span>
      } @else{
      <div>
        <span class="secondary-address-info" i18n="@@locationSelected"
          >Location selected:</span
        >
        <span class="secondary-address-info">
          {{ address | mainAddressInfo }}, {{ address | secondaryAddressInfo }}
        </span>
      </div>
      }
    </div>
    } } @if(isSmFeatureSelected$ | async){
    <div>
      @if(!(updateLocation$ | async)){
      <span
        [class]="isChangeLocationAllowed() ? 'link' : 'link-disabled'"
        (click)="
          isChangeLocationAllowed()
            ? onChangeLocation()
            : $event.preventDefault()
        "
        i18n="@@changeLocationLink"
      >
        <u>Change location</u>
      </span>
      } @else{
      <div class="change-location-options">
        <span class="link" (click)="onConfirm()" i18n="@@confirmLink">
          <u>Confirm</u>
        </span>
        <span class="link" (click)="onCancel()" i18n="@@cancelLink">
          <u>Cancel</u>
        </span>
      </div>
      }
    </div>
    }
  </div>

  <div class="coordinates-container">
    <div class="coordinates-info-title" i18n="@@coordinatesTitle">
      Coordinates
    </div>
    <div class="coordinates-info">
      @if (areMultipleLocationsSelected) {
      <span i18n="@@multipleLocationsNA">N/a</span>
      } @else {
      <span> {{ selectedLocation?.latitude }}</span>
      <span> {{ selectedLocation?.longitude }}</span>
      }
    </div>
  </div>
</div>
