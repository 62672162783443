<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="import scenario data"
    i18n-aria-label="@@importScenarioDataAriaLabel"
    (click)="onOpenNpImportDataDialog()"
    matTooltip="Import scenario data"
    i18n-matTooltip="@@importScenarioDataTooltip"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
  >
    <mat-icon>save_alt</mat-icon>
  </button>
</div>
