<atlas-panel-header>
  <div header i18n="@@reportsPanelHeader">Reports panel</div>
  <div description i18n="@@swipeRightDescription">
    Swipe right to access the Properties panel
  </div>
</atlas-panel-header>

<atlas-spatial-modeller-reports
  *ngIf="isSMFeatureSelected$ | async"
></atlas-spatial-modeller-reports>

<atlas-locator-reports
  *ngIf="isLocatorFeatureSelected$ | async"
></atlas-locator-reports>

<atlas-profiler-reports
  *ngIf="
    (isProfilerFeatureSelected$ | async) &&
    (profilerStatus$ | async) === 'ReportAggregateCompleted'
  "
></atlas-profiler-reports>
