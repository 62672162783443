<form [formGroup]="swapLibraryForm" autocomplete="off">
  <div class="form-controls-padding" style="margin-top: 10px">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label i18n="@@searchExistingLibrariesLabel"
        >Search existing libraries</mat-label
      >
      <input
        matInput
        type="text"
        formControlName="filter"
        placeholder="Search existing libraries"
        i18n-placeholder="@@searchExistingLibrariesPlaceholder"
        (keyup)="onFilterChanged($event)"
      />
      <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@sortLabel">Sort</mat-label>
      <mat-select
        formControlName="sort"
        (selectionChange)="onSortChanged($event)"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-option *ngFor="let option of sortOptions" [value]="option.value">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h3 class="library-list-header" i18n="@@libraryNameHeader">Library name</h3>
    <div class="list-container">
      <mat-list *ngIf="dataSource.length > 0">
        <mat-list-item *ngFor="let library of dataSource">
          <div class="library-list-item-container">
            <div class="item-layout">
              <div
                matListItemLine
                [matTooltip]="library.name"
                matTooltipClass="multiline-tooltip-wrap"
                [matTooltipDisabled]="library.name.length < 40"
              >
                {{ library.name }}
              </div>
              <div matListItemLine>
                {{ library.editedDt.toString() | timeAgo }}
              </div>
            </div>
            <div class="button-layout">
              <div
                class="library-buttons-container"
                *ngIf="library.id === currentLibraryId"
              >
                <div
                  matTooltip="Current library"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                  i18n-matTooltip="@@currentLibraryTooltip"
                >
                  <button
                    class="atlas-icon-button"
                    mat-flat-button
                    color="primary"
                    disabled="true"
                  >
                    <mat-icon>swap_horiz</mat-icon>
                  </button>
                </div>
                <div
                  matTooltip="Delete library"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                  i18n-matTooltip="@@deleteLibraryTooltip"
                >
                  <button
                    class="atlas-icon-button atlas-secondary-icon-button"
                    color="primary"
                    mat-flat-button
                    disabled="true"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>
              <div
                class="library-buttons-container"
                *ngIf="library.id !== currentLibraryId"
              >
                <button
                  class="atlas-icon-button"
                  mat-flat-button
                  color="primary"
                  (click)="onSwitchToLibrary(library.id)"
                  matTooltip="Open this library"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                  i18n-matTooltip="@@openLibraryTooltip"
                >
                  <mat-icon>swap_horiz</mat-icon>
                </button>
                <div
                  matTooltip="Delete library"
                  [matTooltipShowDelay]="1000"
                  [matTooltipHideDelay]="1000"
                  i18n-matTooltip="@@deleteLibraryTooltip"
                >
                  <button
                    class="atlas-icon-button atlas-secondary-icon-button"
                    mat-flat-button
                    color="primary"
                    (click)="onDeleteLibrary(library.id)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <div
        class="library-list-header"
        *ngIf="dataSource.length == 0 && !librariesLoading"
      >
        <div class="body-large" i18n="@@noRecordsFoundMessage">
          No records found, edit search criteria
        </div>
      </div>

      <div class="library-list-spinner" *ngIf="librariesLoading">
        <mat-spinner diameter="34" strokeWidth="2"> </mat-spinner>
      </div>
    </div>
  </div>
</form>
