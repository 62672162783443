import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '../utils/translation-utils';

const dictionary = {
  circle: $localize`:@@circleDescription:circle`,
  walk: $localize`:@@walkDescription:walk`,
  drive: $localize`:@@driveDescription:drive`,
  'public transport': $localize`:@@publicTransportDescription:public transport`,
  minute: $localize`:@@minuteDescription:minute`,
  mile: $localize`:@@mileDescription:mile`,
  km: $localize`:@@kmDescription:km`,
  midday: $localize`:@@middayPipeDescription:midday`,
  rush: $localize`:@@rushPipeDescription:rush`,
  night: $localize`:@@nightPipeDescription:night`
};

@Pipe({
  name: 'translate'
})
export class TranslationPipe implements PipeTransform {
  transform(translationValue: string): string {
    translationValue = translate(translationValue, dictionary);
    return translationValue;
  }
}
