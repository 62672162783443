<div>
  <div>
    @if(queryItems.length === 0){
    <div i18n="@@lowFiStatsUnavailable">
      Low-fi stats are unavailable for this profile type
    </div>
    } @if(selectedQueryItem){
    <div class="image-container">
      @if(queryItems.length > 1){
      <div style="text-align: center" id="{{ identifier }}">
        <span
          *ngFor="let row of queryItems; let i = index"
          style="text-align: center"
          class="dot"
          [ngClass]="{ ' active': i === 0 }"
          (click)="selectQuery(i)"
        ></span>
      </div>
      } @if(title){
      <div style="text-align: center; padding-bottom: 5px">
        {{ title }}
      </div>
      }
      <atlas-profiler-reports-stats
        [queryItem]="selectedQueryItem"
      ></atlas-profiler-reports-stats>

      <atlas-profiler-reports-graph
        [pieGridView]="pieGridView"
        [numberCardView]="numberCardView"
        [height]="graphHeight"
        [queryItem]="selectedQueryItem"
        [graphConfig]="graphConfig"
        (graphClicked)="onGraphClicked($event)"
      >
      </atlas-profiler-reports-graph>
    </div>
    }
  </div>
</div>
