<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div
      class="message-content"
      *ngIf="selectedSupplypoint$ | async as supplypoint"
    >
      <div
        *ngIf="
          supplypoint?.userAction == userAction.Added && !supplypoint?.isClosed
        "
        i18n="@@newLocationDeleted"
      >
        This new location will be deleted from the scenario.
      </div>
      <div
        *ngIf="
          supplypoint?.userAction == userAction.Edited && !supplypoint?.isClosed
        "
        i18n="@@editsRemoved"
      >
        Edits to this location will be removed.
      </div>
      <div *ngIf="supplypoint?.isClosed" i18n="@@locationReopened">
        This closed location will be reopened.
      </div>
      <div
        *ngIf="supplypoint?.userAction == userAction.Temporary"
        i18n="@@temporaryLocation"
      >
        This is a temporary location. Either deselect the pin in the map or
        clear the current location to be removed.
      </div>
      <div
        *ngIf="supplypoint?.userAction == userAction.Untouched"
        i18n="@@locationCannotBeDeleted"
      >
        This location cannot be deleted.
      </div>
    </div>
    <p></p>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Delete"
        (clicked)="onDeleteClicked()"
        [disabled]="
          (selectedSupplypoint$ | async)?.userAction == userAction.Untouched ||
          (selectedSupplypoint$ | async)?.userAction == userAction.Temporary
        "
        i18n-label="@@deleteButton"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
