import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { LocatorSummaryStatsStatusTypes } from 'src/app/locator/helpers/locator-status-helper';

@Component({
  selector: 'atlas-locator-library-summary-stats-step-1',
  templateUrl: './locator-library-summary-stats-step-1.component.html',
  styleUrls: ['./locator-library-summary-stats-step-1.component.less']
})
export class LocatorLibrarySummaryStatsStep1 {
  mode: ProgressSpinnerMode = 'indeterminate';

  @Input() errorsProcessingData = false;

  private _summaryStatsStatus: LocatorSummaryStatsStatusTypes;
  @Input()
  set summaryStatsStatus(newValue: LocatorSummaryStatsStatusTypes) {
    this._summaryStatsStatus = newValue;
    if (this._summaryStatsStatus === 'SummaryStatsCompleted') {
      this.percentage = 100;
      this.mode = 'determinate';
      this.titleTxt = $localize`:@@reportComplete:Report complete`;
    } else {
      this.percentage = 0;
      this.mode = 'indeterminate';
      this.titleTxt = $localize`:@@creatingReport:Creating report`;
    }
  }
  get summaryStatsStatus(): LocatorSummaryStatsStatusTypes {
    return this._summaryStatsStatus;
  }

  percentage: number = 0;
  titleTxt: string = $localize`:@@creatingReport:Creating report`;

  constructor() {}
}
