<div class="buttons-container">
  <button
    class="cancel-button"
    mat-flat-button
    aria-label="cancel shape"
    (click)="onCancel()"
    i18n-aria-label="@@cancelShapeButtonAriaLabel"
  >
    <span i18n="@@cancelButtonText">Cancel</span>
  </button>
  <button
    class="confirm-button"
    mat-flat-button
    color="primary"
    aria-label="confirm shape"
    (click)="onConfirm()"
    i18n-aria-label="@@confirmShapeButtonAriaLabel"
  >
    <span i18n="@@confirmShapeButtonText">Confirm Shape</span>
  </button>
</div>
<div class="polygon-text">
  <p *ngIf="addingEditingPolygon" i18n="@@polygonManipulationText">
    Manipulate the polygon by moving, adding or deleting vertices.
  </p>
</div>
