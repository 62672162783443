<div
  class="gazetteer-button"
  [class.button-enabled]="
    !(isSelectedPointEmpty$ | async) && !(isLocationChanging$ | async)
  "
  i18n-matTooltip="@@clearCurrentLocationTooltip"
  matTooltip="Clear current location"
  [matTooltipShowDelay]="1000"
>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="Main menu"
    (click)="onClearSearch()"
    [disabled]="
      (isSelectedPointEmpty$ | async) || (isLocationChanging$ | async)
    "
  >
    <mat-icon>cancel</mat-icon>
  </button>
  <div class="button-underline"></div>
</div>
