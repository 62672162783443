<div class="settings-container">
  <div i18n="@@autoRunTitle" class="title">Auto Run</div>
  <mat-slide-toggle
    class="live-mode-layout"
    [checked]="!spatialModellerLiveModeDisabled"
    [hideIcon]="true"
    [disabled]="isNetworkPlanningReadonlySystem$ | async"
    (change)="onToggleChange($event)"
  >
  </mat-slide-toggle>

  <div style="height: 100%">
    <div class="description">
      <div i18n="@@autoRunOnDescription">
        Auto Run On - Model runs automatically when selecting an existing site
        or when a new location is defined.
      </div>
      <div i18n="@@autoRunOffDescription">
        Auto Run Off - Automatic model runs are prevented. Model runs must
        always be invoked manually using the Run button.
      </div>
    </div>
  </div>
  <div class="save-button-container">
    <button
      mat-flat-button
      color="primary"
      class="save-button"
      type="submit"
      (click)="updateLiveMode()"
      [disabled]="!isChanged"
    >
      <span i18n="@@saveButtonLabel">Save</span>
    </button>
  </div>
</div>
