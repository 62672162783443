<atlas-single-button-dialog
  buttonText="Confirm"
  headerText="Data has been updated"
  [buttonDisabled]="!check.checked"
  [primaryButton]="true"
  (buttonClicked)="handleButtonClicked()"
  i18n-buttonText="@@confirmButton"
  i18n-headerText="@@dataUpdatedHeader"
>
  <form formContent>
    <div style="margin-top: 10px" formContent>
      <p class="message" i18n="@@dataUpdatedMessage">
        You have been updated to the latest data, please be aware that numbers
        in your app and reports previously shared will now be different and
        should be rerun.
      </p>
      <mat-checkbox #check class="checkbox" i18n="@@dataUpdatedCheckbox">
        I understand the data has been updated.
      </mat-checkbox>
    </div>
  </form>
</atlas-single-button-dialog>
