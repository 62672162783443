<div class="atlas-dialog-container">
  <atlas-dialog-header
    headerText="Reporting"
    i18n-headerText="@@reportingHeader"
  ></atlas-dialog-header>
  <mat-dialog-content class="mat-dialog-content">
    <atlas-sm-reporting-form
      #reportingForm
      [selectedSupplyPoint]="data.selectedSupplyPoint"
      [smReports]="data.smReportList"
      [testModelJobId]="data.testModelJobId"
    ></atlas-sm-reporting-form>
  </mat-dialog-content>

  <div class="reports-label" i18n="@@shareLabel">Share</div>
  <p></p>
  <div class="url-container">
    <mat-form-field appearance="outline">
      <mat-label i18n="@@reportLinkLabel">Report Link</mat-label>
      <input
        matInput
        #reportUrlInput
        type="text"
        [value]="reportingForm.selectedPbiReportUrl"
        readonly
        [disabled]="!reportingForm.selectedPbiReportUrl"
      />
    </mat-form-field>
    <div class="button-padding">
      <button
        class="copy-button secondary-button"
        mat-flat-button
        (click)="onShareClicked()"
        [disabled]="!reportingForm.selectedPbiReportUrl"
      >
        <span *ngIf="isTextCopyButtonVisible()" i18n="@@copyButton">Copy</span>
        <mat-spinner
          class="update-button-spinner"
          *ngIf="isSpinnerVisible()"
          diameter="24"
          strokeWidth="2"
        >
        </mat-spinner>
      </button>
    </div>
  </div>

  <atlas-dialog-footer>
    <div class="dialog-buttons-container">
      <atlas-dialog-cancel-button
        (clicked)="buttonClicked.emit()"
        label="Close"
        i18n-label="@@closeButtonLabel"
      ></atlas-dialog-cancel-button>
      <button
        mat-flat-button
        color="primary"
        class="launch-report-button"
        aria-label="Launch Report"
        (click)="onLaunchReport()"
        [disabled]="!reportingForm.selectedReport"
      >
        <span i18n="@@launchReportButton">Launch Report</span>
      </button>
    </div>
  </atlas-dialog-footer>
</div>
