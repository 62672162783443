import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import {
  getLocatorLoFiReportAttempt,
  getLocatorLoFiReportErrorOccurred,
  getLocatorLoFiReportSucceded,
  getLocatorPowerBiReportingInfoErrorOccurred,
  getLocatorPowerBiReportingInfoSucceded
} from '../actions/locator-library.actions';
import { LocatorService } from 'src/app/locator/services/locator.service';
import * as fromLocatorStore from 'src/app/locator-store';

import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import {
  createLocatorShapesSucceeded,
  generateLocatorDataReportAttempt,
  generateLocatorDataReportErrorOccurred,
  generateLocatorDataReportSucceded,
  generateLocatorLoFiReportAttempt,
  generateLocatorLoFiReportErrorOccurred,
  generateLocatorLoFiReportSucceded,
  getPowerBiReportDataCountSucceded,
  libraryLocationShapeErrorOccurred,
  locatorLaunchReportingClicked,
  SharePowerBIReportAttempt,
  SharePowerBIReportErrorOccurred,
  SharePowerBIReportSucceeded
} from '../actions/locator-shape.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class LocatorReportingEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromLocatorStore.State>,
    private locatorDataService: LocatorService,
    public dialogService: DialogService,
    private appFeatureStateService: AppFeatureStateService
  ) {}

  getLocatorPowerBiReportingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenLocatorFeatureAllowed(),
      switchMap(() =>
        this.locatorDataService.getPowerBiReportCollection().pipe(
          map((pbiReports: LocatorPowerBiReportingInfo[]) => {
            return getLocatorPowerBiReportingInfoSucceded({ pbiReports });
          }),
          catchError((error) =>
            of(
              getLocatorPowerBiReportingInfoErrorOccurred({
                errorOn: '',
                error
              })
            )
          )
        )
      )
    )
  );

  getLofiReportForShapeAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocatorLoFiReportAttempt),
      switchMap(({ libraryId, locationDataId, shapeId }) => {
        return this.locatorDataService
          .getLofiReportForLibraryAndShape(libraryId, locationDataId, shapeId)
          .pipe(
            map((report: LocatorLofiQueryResult[]) => {
              return getLocatorLoFiReportSucceded({
                report: report
              });
            }),
            catchError((error) =>
              of(
                getLocatorLoFiReportErrorOccurred({
                  errorOn: 'Low fidelity report:',
                  error
                })
              )
            )
          );
      })
    )
  );

  generateLofiReportForShapeAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateLocatorLoFiReportAttempt),
      switchMap(({ libraryId, locationDataId, shapeId }) => {
        return this.locatorDataService
          .generateLofiReportForLibraryAndShape(
            libraryId,
            locationDataId,
            shapeId
          )
          .pipe(
            map((report: LocatorLofiQueryResult[]) => {
              return generateLocatorLoFiReportSucceded({
                report: report
              });
            }),
            catchError((error: any) => {
              if (
                error === 'Timeout during reading attempt' ||
                error === 'Endpoint request timed out'
              ) {
                return of(
                  generateLocatorLoFiReportErrorOccurred({
                    errorOn: $localize`:@@miniReportPanelFailed:Mini report panel failed`,
                    error: $localize`:@@miniReportTimeout:The time taken to accumulate the data for the mini report has exceeded the timeout limit. Please click Launch reporting to access a detailed report.`
                  })
                );
              }
              return of(
                generateLocatorLoFiReportErrorOccurred({
                  errorOn: $localize`:@@lowFidelityReport:Low fidelity report:`,
                  error
                })
              );
            })
          );
      })
    )
  );

  generatePowerBiReportDataForShapeAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateLocatorDataReportAttempt),
      switchMap(({ libraryId, locationDataId, shapeIds }) => {
        if (shapeIds && shapeIds[0] === undefined) {
          return of();
        }

        return this.locatorDataService
          .generateShapeReportData(libraryId, locationDataId, shapeIds)
          .pipe(
            map(() => {
              return generateLocatorDataReportSucceded();
            }),
            catchError((error) =>
              of(
                generateLocatorDataReportErrorOccurred({
                  errorOn: 'Low fidelity report:',
                  error
                })
              )
            )
          );
      })
    )
  );

  getPowerBiReportDataCountAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateLocatorDataReportAttempt),
      switchMap(({ libraryId, locationDataId, shapeIds }) => {
        return this.locatorDataService
          .getShapeReportDataCount(libraryId, locationDataId, shapeIds[0])
          .pipe(
            map((result) => {
              return getPowerBiReportDataCountSucceded({
                numberOfReportDataRecords: result
              });
            }),
            catchError((error) =>
              of(
                generateLocatorDataReportErrorOccurred({
                  errorOn: 'Low fidelity report:',
                  error
                })
              )
            )
          );
      })
    )
  );

  generatePowerBiReportDataForShapeCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLocatorShapesSucceeded),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      filter(([{ newShapes }, _]) => newShapes.length === 1),
      switchMap(([{ newShapes }, locatorLibraryId]) => {
        return of(
          generateLocatorDataReportAttempt({
            libraryId: locatorLibraryId,
            locationDataId: newShapes[0].libraryDataId,
            shapeIds: [newShapes[0].id]
          })
        );
      })
    )
  );

  generatePowerBiReportDataForShapesLaunchReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorLaunchReportingClicked),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getSelectedShapeIds),
        this.store$.select(fromLocatorStore.getSelectedLocationId),
        this.store$.select(fromLocatorStore.getLocatorLibraryId)
      ),
      filter(([_, newShapesIds]) => newShapesIds.length > 1),
      switchMap(([_, newShapeIds, locationId, locatorLibraryId]) => {
        return of(
          generateLocatorDataReportAttempt({
            libraryId: locatorLibraryId,
            locationDataId: locationId!,
            shapeIds: newShapeIds
          })
        );
      })
    )
  );

  sharePowerBiReportDataAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharePowerBIReportAttempt),
      withLatestFrom(this.store$.select(fromLocatorStore.getSelectedLocation)),
      switchMap(([{ shapeIds }, selectedLocationData]) => {
        return this.locatorDataService
          .sharePowerBIReportData(
            selectedLocationData!.libraryId,
            selectedLocationData!.id,
            shapeIds
          )
          .pipe(
            map(() => SharePowerBIReportSucceeded()),
            catchError((error) =>
              of(
                SharePowerBIReportErrorOccurred({
                  errorOn: $localize`:@@errorSharePowerBIReport:Error share power bi report`,
                  error: $localize`:@@sharePowerBIReportFailed:Error share power bi report failed.`
                })
              )
            )
          );
      })
    )
  );
}
