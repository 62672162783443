@if (areMultipleLocationsSelected) {
<div class="truncate-text" i18n="@@multipleLocationsText">
  Multiple Locations
</div>
} @else {
<div class="truncate-text">{{ currentLocation?.name }}</div>
<div atlasModifyPolyDisableUI>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="edit library"
    (click)="onOpenEditLocationNameDialog()"
    matTooltip="Edit location"
    [matTooltipShowDelay]="1000"
    i18n-aria-label="@@editLibraryButtonAriaLabel"
    i18n-matTooltip="@@editLocationTooltip"
  >
    <mat-icon class="edit-icon edit-location">edit</mat-icon>
  </button>
</div>
}
