<div class="auth-container">
  <div class="auth-body">
    <form
      class="form-container verification-container"
      (ngSubmit)="onConfirmVerificationCode()"
      [formGroup]="verificationForm"
      autocomplete="off"
    >
      <div class="centered"></div>
      <div class="centered column-direction">
        <span i18n="@@verifyTitle" class="auth-title"
          >Verify that it is you</span
        >
        <span
          i18n="@@firstTimeLoginVerifyDescription"
          class="first-time-login-description body-large"
        >
          To authorise this login we will require you to verify your account
          using the mobile number you registered with ending
          {{ userInfo$.getValue().challengeParam?.CODE_DELIVERY_DESTINATION }}.
          The code you will receive is valid for 15 minutes.
        </span>

        <span
          i18n="@@waitForCode"
          class="first-time-login-description body-large"
          [hidden]="authService.authServiceError$ | async"
        >
          Please wait for the code we have just sent you. It can take up to 5
          minutes for it to arrive.
        </span>
      </div>
      <div>
        <div class="first-time-login-control">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label i18n="@@verificationCodeLabel"
              >Verification code</mat-label
            >
            <input
              matInput
              type="text"
              formControlName="verificationCode"
              placeholder="Verification code"
              i18n-placeholder="@@verificationCodePlaceholder"
            />
            <mat-error i18n="@@emptyCodeError" *ngIf="emptyCode()"
              >Please enter your code</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="authService.authServiceError$ | async as error">
        <span class="auth-error-message error-code-message">
          <ng-container>{{ error }}</ng-container>
          <div>
            <a
              i18n="@@resendText"
              class="resend-text"
              (click)="onResendVerificationCode()"
            >
              Press here to resend
            </a>
          </div>
        </span>
      </div>
      <div class="resend-code-message">
        <span
          i18n="@@stillHaventReceivedCode"
          [hidden]="authService.authServiceError$ | async"
        >
          Still haven't received a code?
          <a class="resend-text" (click)="onResendVerificationCode()"
            >Press here to resend</a
          >
          or contact support if this problem persists.
        </span>
      </div>
      <div class="verify-new-password-control verify-button">
        <button
          i18n="@@verifyAndLoginButton"
          mat-raised-button
          class="auth-btn"
          color="primary"
          type="submit"
          [disabled]="invalidForm()"
        >
          Verify and Login
        </button>
      </div>
      <!-- <div class="back-to-login centered">
        <span>Go back to login?</span>
        <span class="link" (click)="onGoBackToLogin()">Click here</span>
      </div> -->
    </form>
  </div>
  <div class="auth-footer">
    <img src="../../../../assets/logos/CACI-logo 1.svg" class="caci-logo" />
  </div>
</div>
