import { Injectable } from '@angular/core';

import { Layer } from '../models/layer';
import { IconLayer } from '@deck.gl/layers';
import { MapService } from '../services/map.service';
import { pinDropLayerIdentifier } from './layer.constants';
import { Store } from '@ngrx/store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { manualPinDragSucceded } from '../../atlas-gazetteer/store/actions/gazetteer.actions';
import { rgbToHex } from '../helpers/thematic-style-helper';
import { LegendItem } from '../models/legend-item';
import { PinDropAndSelectionService } from '../services/pin-drop-and-selection-service';

@Injectable()
export class PinDropLayer extends Layer {
  constructor(
    private mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private gazetteerStore$: Store<fromGazetteerStore.State>
  ) {
    super();
  }

  async getLayer(jwtToken: string) {
    return new IconLayer({
      id: pinDropLayerIdentifier,
      name: 'Temporary Location',
      description: 'An unsaved location to model a new site. ',
      zOrder: 99,
      data: [],
      getPosition: (d: any) => d,
      pickable: true,

      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 24,
          height: 24,
          anchorY: 20,
          mask: false
        }
      },
      iconAtlas: 'assets/map-icons/pin-drop.svg',
      getSize: () => 24,
      getColor: () => [25, 125, 255], // Remember to set mask to true in your icon mapping to enable colouring. MASK IS FALSE
      getIcon: () => 'marker',

      onDragStart: (info: any, event: any) => this.dragStart(info, event),
      onDrag: (info: any, event: any) => this.drag(info, event),
      onDragEnd: (info: any, event: any) => this.dragEnd(info, event),
      autoHighlight: true,
      highlightColor: [129, 179, 255],
      mapLayerType: 'Icon',
      legendInfo: this.getLegendInfo(),
      legendTriggerScaleText: $localize`:@@alwaysVisibleLegend:Always visible`
    });
  }

  dragStart(info: any, event: any) {
    if (info && info.layer) {
      this.mapService.updateController(false);
    }
  }

  drag(info: any, event: any) {
    if (info && info.layer) {
      let props = { data: [info.coordinate] };
      this.mapService.updateLayer(info.layer.id, props);
    }
  }

  dragEnd(info: any, event: any) {
    if (info && info.layer) {
      this.pinDropAndSelectionService.reselectManualPin();
      this.mapService.updateController(true);
      this.gazetteerStore$.dispatch(
        manualPinDragSucceded({
          location: {
            latitude: info.coordinate[1],
            longitude: info.coordinate[0]
          }
        })
      );
    }
  }

  getLegendInfo(): LegendItem[] {
    return [
      {
        mapLayerType: 'Icon',
        fillColor: [],
        lineColor: [],
        lineWidth: 1,
        iconColor: rgbToHex(25, 125, 255),
        text: '',
        iconUrl: 'assets/map-icons/pin-drop.svg',
        iconHeight: 24,
        iconWidth: 24,
        iconFilter: '',
        labelBackground: []
      }
    ];
  }
}
