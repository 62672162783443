import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { getSelectedPbiReportUrl } from 'src/app/core/helpers/power-bi-reports.helper';
import { PowerBiReportViewerService } from 'src/app/core/services/report-viewer.service';

import {
  applyFilter,
  applySort,
  ListSortOptions,
  SearchAndSortValues
} from 'src/app/shared/utils/list-utils';
import { SmReportingInfo } from 'src/app/spatial-modeller/models/sm-reporting-info';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { ReportTypes } from 'src/app/spatial-modeller/types/report-types';

@Component({
  selector: 'atlas-sm-reporting-form',
  styleUrls: ['sm-reporting-form.component.less'],
  templateUrl: 'sm-reporting-form.component.html'
})
export class SmReportingFormComponent implements OnInit {
  private selectedSortOption: ListSortOptions = 'NameAsc';
  private _smReports: SmReportingInfo[] = [];
  @Input()
  set smReports(newValue: SmReportingInfo[]) {
    this._smReports = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
  }
  get smReports(): SmReportingInfo[] {
    return this._smReports;
  }

  @Input() selectedSupplyPoint: SupplyPoint | null;
  @Input() testModelJobId: string;

  smReportingForm: UntypedFormGroup;
  dataSource: SmReportingInfo[];
  selectedReport: SmReportingInfo | null = null;
  selectedPbiReportUrl: string | null = null;

  get reportTypes(): typeof ReportTypes {
    return ReportTypes;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private powerBiReportViewerService: PowerBiReportViewerService
  ) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: ''
    } as SearchAndSortValues;
    this.smReportingForm = this.createForm(initialValue);
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter]
    });
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this._smReports);
  }

  onSelectionChange(change: MatSelectionListChange) {
    this.selectedPbiReportUrl = '';
    this.selectedReport = change.options.find(
      (option) => option.selected
    )?.value;

    if (this.selectedReport?.reportType === ReportTypes.PowerBi) {
      getSelectedPbiReportUrl(
        this.powerBiReportViewerService,
        this.router,
        this.selectedReport?.workspaceId!,
        this.selectedReport?.powerBiReportId!,
        this.selectedReport?.disableExport,
        [this.testModelJobId]
      ).subscribe((pbiReportUrl) => (this.selectedPbiReportUrl = pbiReportUrl));
    }
  }
}
