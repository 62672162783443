<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div class="message-content text" i18n="@@deleteShapeConfirmation">
      Are you sure you want to delete '{{ this.data.shape.name }}'?
    </div>

    <div *ngIf="isShared" class="shared-text" i18n="@@sharedShapeDeleteWarning">
      '{{ this.data.shape.name }}' has been shared in a report(s), by deleting
      this catchment the report will no longer be accessible.
    </div>
    <p></p>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Delete"
        (clicked)="onDeleteClicked()"
        i18n-label="@@deleteButtonLabel"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
