import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { AtlasChipListComponent } from './atlas-chip-list.component';

@Component({
  selector: 'atlas-numeric-chip-list',
  templateUrl: 'atlas-chip-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AtlasNumericChipListComponent
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'chip-list-autocomplete-overlay-pane'
      }
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => AtlasNumericChipListComponent)
    }
  ],
  styleUrls: ['./atlas-chip-list.component.less']
})
export class AtlasNumericChipListComponent
  extends AtlasChipListComponent
  implements Validator
{
  private _maxChipsAllowed: number | null = null;
  @Input()
  get maxChipsAllowed(): number | null {
    return this._maxChipsAllowed;
  }
  set maxChipsAllowed(newValue: number | null) {
    this._maxChipsAllowed =
      !newValue || (newValue && newValue <= 0)
        ? null
        : (this._maxChipsAllowed = newValue);
  }

  onValidationChange: any = () => {};

  public propagateUserValueChange(newValue: string[]) {
    this.markAsTouched();

    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
      this.valueChanged.emit(newValue);
      this.onValidationChange();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Error message when there is no value added
    this.errorMessage = $localize`:@@enterPositiveIntegerError:Please enter at least one positive integer value`;

    if (!AllPositiveValues(control.value)) {
      this.errorMessage = $localize`:@@onlyPositiveError:Only positive values are accepted`;
      return {
        mustBePositive: { allPositive: false }
      };
    }
    if (
      this.maxChipsAllowed != null &&
      control.value.length > this.maxChipsAllowed
    ) {
      this.errorMessage = $localize`:@@numberExceedsLimit:The number of added values exceeds the limit of ${this.maxChipsAllowed}`;
      return {
        exceededNumberOfChips: { numberOfChips: control.value.length }
      };
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}

export function AllPositiveValues(values: string[]): boolean {
  return values.every((value) => {
    const number = Number(value);
    const isPositive = number > 0;
    return isPositive;
  });
}
