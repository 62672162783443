import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  baseSelectionChanged,
  cancelAndRetrieveLastLibraryAttempt,
  classificationSelectionChanged,
  clearProfilerLibraryStateOnLogoff,
  createProfilerLibraryDialogSaveClicked,
  createProfilerLibrarySucceeded,
  deleteProfilerLibraryAttempt,
  deleteProfilerLibrarySucceeded,
  generateProfilerLoFiReportAttempt,
  generateProfilerLoFiReportErrorOccurred,
  generateProfilerLoFiReportSucceded,
  getBaseAndClassificationSucceded,
  getProfilerLibraryCountSucceded,
  getProfilerLibrarySucceded,
  noLastEditedProfileFound,
  swapProfilerLibraryAttempt,
  swapProfilerLibrarySucceeded,
  updateProfilerLibraryDialogSaveClicked,
  updateProfilerLibrarySucceeded
} from '../actions/profiler-library.actions';
import {
  catchError,
  delay,
  filter,
  map,
  observeOn,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { asyncScheduler, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { Injectable } from '@angular/core';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { ProfilerService } from 'src/app/profiler/services/profiler.service';
import * as fromProfilerStore from 'src/app/profiler-store';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';
import { ProfilerBaseClassification } from 'src/app/profiler/model/profiler-base-classification';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';
import { showProfilerLibraryLocationsSystemLayer } from 'src/app/profiler/helpers/profiler-map-layer-helper';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import {
  fileImportAttempt,
  fileImportSuccess,
  fileUploadError
} from '../actions/profiler-import.actions';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { PollProfilerImportStatusCompleted } from '../actions/profiler-import-status.actions';
import { ImportService } from 'src/app/profiler/services/import.service';
import { profilerFeatureClicked } from 'src/app/core/store/actions/profiler-ui.actions';

@Injectable()
export class ProfilerLibraryEffects {
  constructor(
    private actions$: Actions,
    private profilerDataService: ProfilerService,
    private store$: Store<State>,
    private localStorageService: LocalStorageService,
    private appFeatureStateService: AppFeatureStateService,
    private mapService: MapService,
    private snackbarService: SnackbarService,
    private importService: ImportService
  ) {}

  getProfilerLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBaseAndClassificationSucceded),
      this.appFeatureStateService.allowWhenProfilerFeatureAllowed(),
      switchMap(() => {
        const id = this.getProfilerLibraryId();
        if (id > 0) {
          return this.profilerDataService.getProfilerLibraryById(id).pipe(
            map((library: ProfilerLibrary) => {
              this.localStorageService.set('profiler-library-id', library.id);

              return getProfilerLibrarySucceded({ library });
            })
          );
        } else {
          return this.profilerDataService.getLastedEditedProfilerLibrary().pipe(
            map((library: ProfilerLibrary | null) => {
              if (library) {
                this.localStorageService.set('profiler-library-id', library.id);
                return getProfilerLibrarySucceded({ library });
              } else {
                const emptyLibrary: ProfilerLibrary = {
                  id: 0,
                  name: '',
                  createDt: new Date(),
                  editedDt: new Date(),
                  importStatus: null
                };
                return getProfilerLibrarySucceded({ library: emptyLibrary });
              }
            })
          );
        }
      })
    )
  );

  getProfilerLibrariesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        loadAppFeaturesSucceeded,
        createProfilerLibrarySucceeded,
        deleteProfilerLibrarySucceeded,
        getProfilerLibrarySucceded
      ),
      switchMap(() =>
        this.profilerDataService.getLibrariesCount().pipe(
          map((count) =>
            getProfilerLibraryCountSucceded({
              result: count
            })
          )
        )
      )
    )
  );

  createProfilerLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfilerLibraryDialogSaveClicked),
      switchMap(({ libraryRequest }) =>
        this.profilerDataService.createLibrary(libraryRequest).pipe(
          map((library: ProfilerLibrary) => {
            this.localStorageService.set('profiler-library-id', library.id);
            return createProfilerLibrarySucceeded({
              library,
              file: libraryRequest.file
            });
          }),
          catchError((error) =>
            of(
              fromProfilerStore.ProfilerLibraryActions.profilerLibraryErrorOccurred(
                {
                  errorOn: $localize`:@@errorProfile:Error profile`,
                  error: $localize`:@@profileNotCreated:Profile ${libraryRequest.name} has not been created.`
                }
              )
            )
          )
        )
      )
    )
  );

  fileUploadAttemptEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfilerLibrarySucceeded),
      map((action) => {
        return fileImportAttempt({ file: action.file });
      })
    )
  );

  generateProfilerReportOnLibraryChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(swapProfilerLibrarySucceeded),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getSelectedClassificationId)
      ),
      filter(
        ([{ library }]) =>
          library.importStatus?.status === 'ReportAggregateCompleted'
      ),
      switchMap(([_, selectedClassificationId]) => {
        return of(
          classificationSelectionChanged({
            selectedClassificationId: selectedClassificationId
          })
        );
      })
    )
  );

  baseSelectionChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseSelectionChanged),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getSelectedClassificationId),
        this.store$.select(fromProfilerStore.getProfilerLibraryId)
      ),
      switchMap(
        ([{ selectedBaseId: selectedId }, classificationId, libraryId]) => {
          return of(
            generateProfilerLoFiReportAttempt({
              libraryId: libraryId,
              baseId: selectedId,
              classificationId: classificationId
            })
          );
        }
      )
    )
  );

  classificationSelectionChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(classificationSelectionChanged),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getSelectedBaseId),
        this.store$.select(fromProfilerStore.getProfilerLibraryId),
        this.store$.select(fromProfilerStore.getClassifications)
      ),
      switchMap(
        ([
          { selectedClassificationId: selectedId },
          baseId,
          libraryId,
          classifications
        ]) => {
          let classification = classifications.find((c) => c.id == selectedId);

          showProfilerLibraryLocationsSystemLayer(
            libraryId,
            classification,
            this.mapService
          );

          return of(
            generateProfilerLoFiReportAttempt({
              libraryId: libraryId,
              baseId: baseId,
              classificationId: selectedId
            })
          );
        }
      )
    )
  );

  showLocationsLayerAfterFileImportSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PollProfilerImportStatusCompleted),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getSelectedClassificationId)
      ),
      switchMap(([_, selectedClassificationId]) => {
        return of(
          classificationSelectionChanged({
            selectedClassificationId: selectedClassificationId
          })
        );
      })
    )
  );

  updateProfilerLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProfilerLibraryDialogSaveClicked),
      switchMap(({ id, libraryRequest }) =>
        this.profilerDataService.updateProfilerLibrary(id, libraryRequest).pipe(
          map((library: ProfilerLibrary) => {
            return updateProfilerLibrarySucceeded({
              library
            });
          }),
          catchError((error) =>
            of(
              fromProfilerStore.ProfilerLibraryActions.profilerLibraryErrorOccurred(
                {
                  errorOn: $localize`:@@errorProfile:Error profile`,
                  error: $localize`:@@profileNotUpdated:Profile ${libraryRequest.name} has not been updated.`
                }
              )
            )
          )
        )
      )
    )
  );

  swapProfilerLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(swapProfilerLibraryAttempt),
      switchMap(({ id }) => {
        return this.profilerDataService.getProfilerLibraryById(id).pipe(
          map((library: ProfilerLibrary) => {
            this.localStorageService.set('profiler-library-id', library.id);
            return swapProfilerLibrarySucceeded({ library });
          }),
          catchError((error) =>
            of(
              fromProfilerStore.ProfilerLibraryActions.profilerLibraryErrorOccurred(
                {
                  errorOn: $localize`:@@errorProfile:Error profile`,
                  error: $localize`:@@swapProfileFailed:Swap Profile has failed.`
                }
              )
            )
          )
        );
      })
    )
  );

  deleteProfilerLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProfilerLibraryAttempt),
      switchMap(({ id }) => {
        return this.profilerDataService.deleteProfilerLibrary(id).pipe(
          map(() => deleteProfilerLibrarySucceeded({ id })),
          catchError((error) =>
            of(
              fromProfilerStore.ProfilerLibraryActions.profilerLibraryErrorOccurred(
                {
                  errorOn: $localize`:@@errorProfile:Error profile`,
                  error: $localize`:@@deleteProfileFailed:Delete Profile has failed.`
                }
              )
            )
          )
        );
      })
    )
  );

  cancelAndRetrieveLastEditedLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelAndRetrieveLastLibraryAttempt),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getProfilerImportStatus)
      ),
      switchMap(([_, importStatus]) => {
        return this.importService.deleteFilesFromS3(importStatus!.fileKey).pipe(
          switchMap(() => {
            return this.profilerDataService.deleteProfilerLibrary(_.id).pipe(
              switchMap(() =>
                this.profilerDataService.getLastedEditedProfilerLibrary().pipe(
                  map((library: ProfilerLibrary | null) => {
                    if (library) {
                      this.localStorageService.set(
                        'profiler-library-id',
                        library.id
                      );
                      return getProfilerLibrarySucceded({ library });
                    } else {
                      this.localStorageService.remove('profiler-library-id');
                      return noLastEditedProfileFound();
                    }
                  }),
                  catchError((error) =>
                    of(
                      fromProfilerStore.ProfilerLibraryActions.profilerLibraryErrorOccurred(
                        {
                          errorOn: $localize`:@@errorProfile:Error profile`,
                          error: $localize`:@@deleteProfileFailed:Delete Profile has failed.`
                        }
                      )
                    )
                  )
                )
              )
            );
          })
        );
      })
    )
  );

  getLofiReportOnLibraryLoa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfilerLibrarySucceded),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getBases),
        this.store$.select(fromProfilerStore.getClassifications)
      ),
      filter(
        ([_, bases, classifications]) =>
          bases.length > 0 && classifications.length > 0
      ),
      map(([{ library }, bases, classifications]) => {
        return generateProfilerLoFiReportAttempt({
          libraryId: library.id,
          classificationId: classifications[0].id,
          baseId: bases[0].id
        });
      })
    )
  );

  getBaseAndClassification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenProfilerFeatureAllowed(),
      switchMap(() =>
        this.profilerDataService.getBaseAndClassification().pipe(
          map((baseAndClassification: ProfilerBaseClassification) =>
            getBaseAndClassificationSucceded({
              baseAndClassification: baseAndClassification
            })
          )
        )
      )
    )
  );

  generateLofiReportAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateProfilerLoFiReportAttempt),
      switchMap((_) => {
        return this.profilerDataService
          .generateLofiReportForLibrary(
            _.libraryId,
            _.baseId,
            _.classificationId
          )
          .pipe(
            map((report: ProfilerLofiQueryResult[]) => {
              return generateProfilerLoFiReportSucceded({
                report: report
              });
            }),
            catchError((error) =>
              of(
                generateProfilerLoFiReportErrorOccurred({
                  errorOn: $localize`:@@lowFidelityReport:Low fidelity report:`,
                  error
                })
              )
            )
          );
      })
    )
  );

  clearProfilerLibraryStateOnLogoff$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearProfilerLibraryStateOnLogoff),
        map(() => {
          this.mapService.resetMapService();
          this.snackbarService.closeSnackBar();
        })
      ),
    { dispatch: false }
  );

  private getProfilerLibraryId() {
    var id = this.localStorageService.get('profiler-library-id');
    return id ? id : 0;
  }
}
