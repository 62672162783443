<div class="main-container">
  <div class="side-column">
    <button
      mat-flat-button
      type="button"
      class="secondary-button back-button"
      (click)="onBack()"
      i18n="@@backButtonChevron"
    >
      <mat-icon class="chevron-back">chevron_left</mat-icon>Back
    </button>
  </div>
  <div class="workspace-container">
    <div>
      <div class="title-padding">
        <h1 i18n="@@helpResources">Help & Resources</h1>
      </div>
      <div class="sub-title-padding" i18n="@@getInTouch">
        Get in touch and let us know how we can help
      </div>
      <atlas-help-content></atlas-help-content>
    </div>
  </div>
  <div class="side-column"></div>
</div>
