import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';

import { AtlasDxGridStateService } from 'src/app/shared/dev-express/services/atlas-dx-grid-state.service';
import { AtlasDxColumnFormatService } from 'src/app/shared/dev-express/services/atlas-dx-column-format.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import {
  styleDefaultTrafficLight,
  styleMediumGeocodeLevel,
  styleStrongGeocodeLevel,
  styleWeakGeocodeLevel
} from '../../helpers/geocoding-template.helper';
import { formatPropertyKeysMap } from 'src/app/shared/utils/language-mapping-utils';

@Component({
  selector: 'atlas-delta-supply-points-list',
  templateUrl: './delta-supply-points-list.component.html'
})
export class DeltaSupplyPointsListComponent {
  private initialUse: boolean;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  @Output()
  deltaLocationClickSelected = new EventEmitter<string[]>();

  @Input()
  selectedSupplyIds: string[] = [];

  _dataSource: { [k: string]: any }[] | null;
  @Input()
  set dataSource(value: Array<{ [k: string]: any }> | null) {
    const updatedKeys = formatPropertyKeysMap(value);
    if (!updatedKeys) {
      return;
    }

    this._dataSource = updatedKeys;
  }
  get dataSource(): any {
    return this._dataSource;
  }

  @Input()
  template: TemplateRef<any>;

  constructor(
    public atlasFormat: AtlasDxColumnFormatService,
    public atlasGridState: AtlasDxGridStateService
  ) {}

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.push({
      location: 'before',
      template: 'parentTemplate'
    });
  }

  loadState = () => {
    var state = this.atlasGridState.loadState(
      'delta-supply-points-grid-storage'
    );

    this.initialUse = state.columns == undefined;

    if (this.initialUse) {
      this.grid.visible = false;
    }

    if (state.columns) {
      this.applyMatchLevelSetupChanges(state.columns);
    }

    this.grid.columns = state.columns;
    this.grid.visible = true;

    return state;
  };

  saveState = (state: any) => {
    if (this.initialUse) {
      this.initialSave(state);
    } else {
      this.atlasGridState.saveState('delta-supply-points-grid-storage', state);
    }
  };

  onSelectionChanged(event: SelectionChangedEvent) {
    this.deltaLocationClickSelected.emit(
      event.selectedRowsData.map((r) => r?.supplyId)
    );
  }

  private initialSave(state: any) {
    state = this.configureColumnsforInitialUse(state);
    this.atlasGridState.saveState('delta-supply-points-grid-storage', state);
    this.grid.columns = state.columns;
    this.grid.visible = true;
  }

  // very first use we dont know all the columns but we do know which 5 we want visible we need to configre this
  private configureColumnsforInitialUse(state: any) {
    var defaultColumns = [
      {
        visibleIndex: 0,
        dataField: 'matchLevel',
        name: 'matchLevel',
        visible: true
      },
      {
        visibleIndex: 1,
        dataField: 'supplyKey',
        name: 'supplyKey',
        caption: 'Supply Key',
        dataType: 'string',
        visible: true
      },
      {
        visibleIndex: 2,
        dataField: $localize`:@@nameDataField:name`,
        name: $localize`:@@nameDataField:name`,
        caption: $localize`:@@name:Name`,
        dataType: 'string',
        visible: true
      },

      {
        visibleIndex: 3,
        dataField: 'fascia',
        name: 'fascia',
        caption: 'Fascia',
        dataType: 'string',
        visible: true
      },
      {
        visibleIndex: 4,
        dataField: $localize`:@@statusDataField:status`,
        name: $localize`:@@statusDataField:status`,
        caption: $localize`:@@status:Status`,
        dataType: 'string',
        visible: true
      }
    ];

    this.applyMatchLevelSetupChanges(defaultColumns);

    let vIndex = 5;
    state.columns?.forEach((element: any) => {
      var match = defaultColumns.find((c) => c.dataField == element.dataField);

      if (match == undefined) {
        let column = element;
        column.visibleIndex = vIndex;
        column.visible = false;
        defaultColumns.push(column);
        vIndex = vIndex + 1;
      }
    });

    state.columns = defaultColumns;
    this.initialUse = false;
    return state;
  }

  private applyMatchLevelSetupChanges(columns: any) {
    const matchLevelColumn = columns.find(
      (column: any) => column.dataField === 'matchLevel'
    );

    // Column properties aren't passed through DevExpress dxo-storing component
    // therefore are initialised here
    matchLevelColumn.caption = '';
    matchLevelColumn.allowFiltering = false;
    matchLevelColumn.width = '40px';
    matchLevelColumn.cellTemplate = (
      cellElement: HTMLElement,
      cellInfo: any
    ) => {
      styleDefaultTrafficLight(cellInfo.text, cellElement);
      styleWeakGeocodeLevel(cellInfo.text, cellElement);
      styleMediumGeocodeLevel(cellInfo.text, cellElement);
      styleStrongGeocodeLevel(cellInfo.text, cellElement);
    };
  }
}
